import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Applicant } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles } from './index';
import { SubHeading } from '../investors/components';
import { checkAddressField } from '../../utils/utilityFunctions';
import { styles } from '../../Styles/styles';

export default function ContactDetails({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Contact Details
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ color: 'common.black', letterSpacing: 0.5 + 'px' }}>
                    {title}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pt={2}
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Phone Number (Office)'}
                        description={applicant.phoneNumberoffice}
                      />
                      <KeyValue
                        title={'Phone Number (Residential)'}
                        description={applicant.phoneNumberResidence}
                      />
                      <KeyValue title={'E-Mail ID'} description={applicant.email} />
                      <KeyValue
                        title={'Mobile Number'}
                        description={
                          applicant.countryCode
                            ? applicant.countryCode + '-' + applicant.mobile
                            : applicant.mobile
                        }
                      />
                      {applicant.kraMobileNumber && (
                        <KeyValue
                          title={'KRA Mobile Number'}
                          description={applicant.kraMobileNumber}
                        />
                      )}
                      {applicant.kraEmailId && (
                        <KeyValue title={'KRA Email Id'} description={applicant.kraEmailId} />
                      )}
                      {!!applicant?.addresses?.length &&
                        applicant?.addresses.map((address) => (
                          <React.Fragment key={address.id}>
                            <KeyValue
                              title={`${address.address_type} Address`}
                              description={`${checkAddressField(
                                address.address1
                              )}${checkAddressField(address.address2)}${checkAddressField(
                                address.address3
                              )}
                              ${checkAddressField(address.city)}${checkAddressField(
                                address.state
                              )}${checkAddressField(address.country)}${address.pincode}`}
                              sx={{
                                textTransform: 'capitalize',
                              }}
                            />
                          </React.Fragment>
                        ))}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
