import React, { ReactNode } from 'react';
import { Button, Container, Typography } from '@mui/material';

// Define the types for the props and state
interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // This method updates state if an error is thrown
  // eslint-disable-next-line
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  // This method logs the error to the console (or send to a logging service)
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // You can log the error to an error reporting service here
    console.error('Error caught in error boundary:', error, errorInfo);
  }

  handleReload = (): void => {
    // Force a full page reload to reset the application state
    window.location.reload();
  };

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <Container style={{ textAlign: 'center', padding: '2em' }}>
          <Typography variant="h4" gutterBottom>
            Oops! Something went wrong.
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '1em' }}>
            We encountered an unexpected issue. Please refresh the page to continue.
          </Typography>
          <Button variant="contained" color="primary" onClick={this.handleReload}>
            Refresh Page
          </Button>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
