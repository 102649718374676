import { Grid, Typography } from '@mui/material';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import {
  Bank,
  Document,
  individuals_Poa_nonIndividuals_Documents,
} from '../../redux-store/types/api-types';
import { useHistory } from 'react-router';
import { documentDetailsSchema } from '../../utils/schema';
import { showError } from '../../redux-store/actions/auth';
import { LoadingButton } from '@mui/lab';
import { Location } from 'history';
import { FileUpload, documentDetails, document_object } from '../investors/documentDetails';
import { SubHeading } from '../investors/components';
import { getKYCDocuments, updateKycApplication } from '../../redux-store/actions/kyc';
import DigilockerXmlFile from './preview/DigilockerXmlFile';
import { KycApplicant } from '../../redux-store/types/kyc';
import { applicationComparison } from '../../utils/utilityFunctions';

export type documentProps = {
  kyc_documents: {
    documentType: string;
    documentName: string;
    documentsList: documentDetails[];
    multipleFiles: string;
    required: string;
    options?: string[];
  }[];
};

export interface Values {
  kyc_applicants: documentProps[];
  [key: string]: documentProps[] | string | undefined | string[] | Bank[] | boolean;
}

export const getDocumentTitle: { [key: string]: string } = {
  investor_signature: 'Investor Signature',
  investor_photograph: 'Investor Photograph',
};

export default function KycDocumentDetails({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  location,
}: {
  location: Location<{ id?: string; applicant1ReferenceId?: string }>;
}): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.kycApplication);
  const dispatch = useDispatch();
  const history = useHistory();
  const { applicant1ReferenceId = '' } = location.state || {};
  const [documentUpload, setDocumentUpload] = useState(false);
  const handleUpload = () => {
    setDocumentUpload(true);
  };
  const handleUploaded = () => {
    setDocumentUpload(false);
  };
  const [documentsData, setDocumentsData] = useState<Document>();

  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(getKYCDocuments())) as unknown as Document;
        setDocumentsData(response as Document);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const {
    kyc_applicants: exisitingApplicants = [],
    id: applicationId = '',
    currentStep,
  } = application || {};
  const docData = 'kycDocs';
  const initialValues: Values = {
    kyc_applicants: exisitingApplicants.map((applicant, applicant_index) => {
      return {
        kyc_documents:
          typeof documentsData !== 'undefined'
            ? ((documentsData as Document)[docData] || []).map(
                (doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                  const { documentType, documentName, multipleFiles, required, options } = doc;
                  const { kyc_documents: existingDocuments = [] } = applicant || {};
                  const docsOfCurrentDocType = existingDocuments
                    .filter((doc) => doc.documentType === documentType)
                    .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                  return {
                    documentType: documentType,
                    documentName: documentName,
                    documentsList: docsOfCurrentDocType.length
                      ? docsOfCurrentDocType.map((doc, ind) => {
                          const {
                            id,
                            documentName = '',
                            documentType = '',
                            documentId = '',
                            isActive = true,
                            file = undefined,
                          } = doc;
                          return {
                            id,
                            kycApplicantId: applicant?.id,
                            documentType,
                            documentName,
                            documentId,
                            isActive,
                            file,
                            options,
                            required,
                            isVisible: true,
                            uniqueKey: (applicant_index.toString() +
                              '-' +
                              index.toString() +
                              '-' +
                              ind.toString()) as string,
                          };
                        })
                      : [
                          {
                            documentType,
                            documentName,
                            required,
                            options,
                            isVisible: true,
                            uniqueKey: (applicant_index.toString() +
                              '-' +
                              index.toString() +
                              '-' +
                              '0') as string,
                            ...document_object,
                          },
                        ],
                    required,
                    multipleFiles,
                    options,
                  };
                }
              )
            : [],
      };
    }),
  };

  const updatedApplicants = (
    exisitingApplicants: Partial<KycApplicant>[],
    applicants: documentProps[]
  ): Partial<KycApplicant>[] => {
    return exisitingApplicants.map((applicant, index) => {
      const { kyc_documents = [] } = applicants[index] || {};
      const finalDocs = kyc_documents.map((doc) => {
        return doc.documentsList.filter((d) => d.documentId);
      });
      return {
        ...applicant,
        kyc_documents: finalDocs.flat().map((doc) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { uniqueKey, required, ...rest } = doc;
          return rest;
        }),
      };
    }) as Partial<KycApplicant>[];
  };

  const handleSubmit = async (values: Values) => {
    try {
      const { kyc_applicants = [] } = values;
      kyc_applicants.map((applicant) => {
        applicant.kyc_documents?.forEach((document) => {
          document.documentsList?.forEach((doc) => {
            if (!doc.documentId) {
              throw `Please Select ${getDocumentTitle[doc.documentType]}`;
            }
          });
        });
      });
      const updatedApplicantWithKYCDocs = updatedApplicants(exisitingApplicants, kyc_applicants);

      const checkIfUpdated = applicationComparison(
        {
          ...application,
          kyc_applicants: application?.kyc_applicants
            ?.map((applicant) => {
              return {
                ...applicant,
                documents: applicant.kyc_documents
                  // eslint-disable-next-line
                  ?.map((document: any) => {
                    const {
                      // eslint-disable-next-line
                      filename,
                      // eslint-disable-next-line
                      updatedAt,
                      // eslint-disable-next-line
                      createdAt,
                      // eslint-disable-next-line
                      updatedBy,
                      // eslint-disable-next-line
                      createdBy,
                      // eslint-disable-next-line
                      options,
                      ...rest
                    } = document;
                    return rest;
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        },
        {
          ...application,
          kyc_applicants: updatedApplicantWithKYCDocs
            ?.map((applicant) => {
              return {
                ...applicant,
                documents: applicant.kyc_documents
                  // eslint-disable-next-line
                  ?.map((document: any) => {
                    // eslint-disable-next-line
                    const { options, ...rest } = document;
                    return rest;
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort(
              (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
            ) as unknown as KycApplicant[],
          currentStep: !currentStep ? 2 : currentStep,
        }
      );

      if (applicationId && !checkIfUpdated) {
        setLoading(true);
        await dispatch(
          updateKycApplication(applicationId, {
            kyc_documents: updatedApplicantWithKYCDocs[0]?.kyc_documents,
            applicantId: exisitingApplicants[0]?.id,
            currentStep: 3,
          })
        );
        applicant1ReferenceId
          ? history.push(`/investor-kyc/${applicant1ReferenceId}/kyc-application-details`, {
              referenceId: applicant1ReferenceId,
            })
          : history.push(`/kyc-application-preview/${applicationId}`);
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, documentDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={10}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          {values.kyc_applicants.map((applicant, ind) => {
            const { kyc_documents } = applicant;
            return (
              <>
                <SubHeading>Applicant Document Details</SubHeading>
                {kyc_documents?.map((document, doc_index) => {
                  const { documentName, documentsList = [], required } = document;
                  return (
                    <React.Fragment key={doc_index}>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          sx={{
                            color: 'primary.main',
                            fontWeight: 500,
                            mt: '59px',
                          }}>
                          {documentName} {required === 'true' ? '*' : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {documentsList.map((doc, index) => (
                          <React.Fragment key={doc.uniqueKey}>
                            <FileUpload
                              onFileChange={(value, documentId, errorOnUpload, removeFile) => {
                                const { file, ...rest } =
                                  values.kyc_applicants[ind].kyc_documents[doc_index].documentsList[
                                    index
                                  ];
                                setFieldValue(
                                  `kyc_applicants.${ind}.kyc_documents.${doc_index}.documentsList.${index}`,
                                  {
                                    ...rest,
                                    documentId,
                                    ...(!removeFile && { file }),
                                    errorOnUpload: errorOnUpload || document_object.errorOnUpload,
                                  }
                                );
                              }}
                              name={`kyc_applicants.${ind}.kyc_documents.${doc_index}.documentsList.${index}.documentId`}
                              documentData={doc}
                              disabled={false}
                              onFileUpload={handleUpload}
                              onFileUploaded={handleUploaded}
                            />
                          </React.Fragment>
                        ))}
                      </Grid>
                    </React.Fragment>
                  );
                })}
                <DigilockerXmlFile />
              </>
            );
          })}

          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <LoadingButton
              loadingPosition="start"
              type="submit"
              variant="contained"
              sx={{
                px: 6,
                color: 'common.white',
                lineHeight: 1.5,
                fontSize: 14,
                m: 1,
                ml: { xs: 0 },
                mt: 5,
              }}
              loading={loading}
              disabled={documentUpload}>
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
