/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

//should inform Backend Team if any modifications done in this file

import * as yup from 'yup';
import {
  Bank,
  ControllingPersons,
  Groups,
  NomineeType,
  nonIndividualQuestionsFatca,
  ubo,
  Applicant,
  Broker,
} from '../redux-store/types/api-types';
import {
  accountNumberRegex,
  ifscRegex,
  stringRegex,
  addressRegex,
  phoneRegExp,
  nonIndividualPanRegex,
  emailRegex,
  individualPanRegex,
  numberRegex,
  amountRegex,
  alphaNumeric,
  indianPin,
  pinCodeRegexOtherThanIndia,
  cdslRegex,
  dpIdRegex,
  dpIdNumberRegex,
} from './regex';
import {
  isMinor,
  currencyConversion,
  maxAge,
  getCountryCodes,
  checkForCorrespondenceAddress,
  checkUBOTypeIsTrust,
  getdpIdField,
  getclIdField,
  isCDSL,
  checkValidationBasedOnDate,
  getCountryBasedOnStatus,
  isOnboardingTypeMinor,
  futureAge,
  isFatherMother,
  shouldValidateUponSaveLater,
  otherNameOfBroker,
  isAccreditedInvestorApplicable,
  isFieldApplicable,
  isFieldVariable,
  isFirstApplicant,
} from './utilityFunctions';
import { InternalOptions } from 'yup/lib/types';
import { Values } from '../components/investors/contactDetails';
import { Distributor, SubDistributor } from '../redux-store/actions';
import { Values as ContributorValues } from '../components/investors/contributorDetails';
import { Values as DocumentValues } from '../components/investors/documentDetails';
import { Values as NonIndividualDocValues } from '../components/NonIndividualInvestor/DocumentDetails';
import { getSubQuestionsIndex } from '../components/NonIndividualInvestor/fatca';
import {
  addressTypesMasters,
  adminSupportMaster,
  applicantStatusMasters,
  APPLICATION_TYPE,
  BankAccountTypeMaster,
  CpUboCodesMaster,
  DLCLMasters,
  FEE_TYPE_MASTER,
  genderMasters,
  grossAnnualMasters,
  investorTypeMasters,
  isNaturalParent,
  NomineeRelations,
  NRIBankAccountTypeMaster,
  occupationDetailsMasters,
  PEPsMasters,
  Relations,
  UboTypeMaster,
  YES_NO_MASTER,
  subdistributorTypes,
  SCHEME_CATEGORY,
} from './constant';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { Values as NonIndividualContactValues } from '../components/NonIndividualInvestor/ContactDetails';
import { Values as individualFatcaValues } from '../components/investors/fatca';
import { togglerType } from '../redux-store/types/mdms';

export const contributorDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .nullable()
        .test('name', (value, context) => {
          const {
            options: { context: optionsContext },
            path,
            createError,
          } = context;
          if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
            return createError({
              message: 'Name is required',
              path,
            });
          }
          return true;
        }),
      gender: yup
        .string()
        .nullable()
        .oneOf(genderMasters, 'Invalid value for Gender')
        .test('gender', (value, context) => {
          const {
            options: { context: optionsContext },
            path,
            createError,
          } = context;
          if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
            return createError({
              message: 'Please select gender',
              path,
            });
          }
          return true;
        }),
      motherName: yup
        .string()
        .nullable()
        .matches(stringRegex, 'Special characters and numbers not allowed'),
      fatherOrSpouseName: yup
        .string()
        .nullable()
        .test('fatherOrSpouseName', (value, context) => {
          const {
            options: { context: optionsContext },
            path,
            createError,
          } = context;
          if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
            return createError({
              message: 'Father/Spouse Name is required',
              path,
            });
          }
          return true;
        }),
      dateOfBirth: yup
        .string()
        .nullable()
        .test(
          'dateOfBirth',
          'Age should be greater than 18',
          (dateOfBirth) => !isMinor(dateOfBirth || '')
        )
        .test('dateOfBirth', (value, context) => {
          const {
            options: { context: optionsContext },
            path,
            createError,
          } = context;
          if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
            return createError({
              message: 'Date of Birth is required',
              path,
            });
          }
          return true;
        }),
      status: yup
        .string()
        .nullable()
        .oneOf(Object.keys(applicantStatusMasters), 'Invalid value for Status')
        .test('status', (value, context) => {
          const {
            options: { context: optionsContext },
            path,
            createError,
          } = context;
          if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
            return createError({
              message: 'Status is required',
              path,
            });
          }
          return true;
        }),
      nationality: yup
        .string()
        .nullable()
        .test('nationality', (value, context) => {
          const fieldValue = value === null ? '' : value;
          // eslint-disable-next-line
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<ContributorValues>;
          const { nationalityDropdown = [] } = optionsContext || {};
          if (!fieldValue && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
            return createError({
              message: 'Nationality is required',
              path,
            });
          }
          if (fieldValue && !nationalityDropdown.includes(fieldValue)) {
            return createError({
              message: 'Invalid value for Nationality',
              path,
            });
          }
          // if (
          //   applicantStatusMasters[parent.status] === applicantStatusMasters.NRI &&
          //   value?.toUpperCase() !== 'INDIAN'
          // ) {
          //   return createError({
          //     message: 'Invalid value for Nationality',
          //     path,
          //   });
          // }
          return true;
        }),
      jointApplicantRelation: yup
        .string()
        .nullable()
        .test('jointApplicationRelation', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path } = context;
          const { index } = options as InternalOptions<ContributorValues> & {
            index: number;
          };

          if (index !== 0) {
            if (!fieldValue && shouldValidateUponSaveLater(options.context?.saveType || '')) {
              return createError({
                message: 'Relationship with first applicant is required',
                path,
              });
            }
            if (fieldValue && !Relations.includes(fieldValue)) {
              return createError({
                message: 'Invalid value for Relationship with first applicant',
                path,
              });
            }
          }
          return true;
        }),
      relationShipWithFirstApplicant: yup
        .string()
        .nullable()
        .when('jointApplicantRelation', {
          is: (jointApplicantRelation: string | null) => jointApplicantRelation === 'Others',
          then: yup
            .string()
            .nullable()
            .test('relationShipWithFirstApplicant', (value, context) => {
              const {
                options: { context: optionsContext },
                path,
                createError,
              } = context;
              if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
                return createError({
                  message: 'Please Specify Relationship',
                  path,
                });
              }
              return true;
            }),
        }),
      minorPan: yup
        .string()
        .nullable()
        .uppercase()
        .length(10, 'PAN must be exactly 10 characters')
        .test('pan', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent } = context;
          if (!fieldValue) {
            return true;
          }
          if (!individualPanRegex.test(fieldValue)) {
            return createError({
              message: 'Only individual PANs are allowed',
              path,
            });
          }
          if (fieldValue === parent.panNo) {
            return createError({
              message: 'Minor PAN should not be same as Guardian PAN',
              path,
            });
          }
          return true;
        }),
      minorDOB: yup
        .string()
        .nullable()
        .test('minorDOB', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent, options } = context;
          if (isOnboardingTypeMinor(parent.onboardingType)) {
            if (!fieldValue && shouldValidateUponSaveLater(options.context?.saveType)) {
              return createError({
                message: 'Date of Birth is required',
                path,
              });
            }
            if (futureAge(fieldValue || '')) {
              return createError({
                message: 'Future date cannot be entered',
                path,
              });
            }
            if (fieldValue && !isMinor(fieldValue)) {
              return createError({
                message: 'Age should be less than 18',
                path,
              });
            }
          }
          return true;
        }),
      minorName: yup
        .string()
        .nullable()
        .test('minorName', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent, options } = context;
          if (
            !fieldValue &&
            isOnboardingTypeMinor(parent.onboardingType) &&
            shouldValidateUponSaveLater(options.context?.saveType)
          ) {
            return createError({
              message: 'Minor Name is required',
              path,
            });
          }
          return true;
        }),
      //   .string()
      //   .nullable()
      //   .test('minorFirstName', (value, context) => {
      //     const fieldValue = value === undefined ? '' : value;
      //     const { path, createError, parent } = context;
      //     if (!fieldValue && isOnboardingTypeMinor(parent.onboardingType)) {
      //       return createError({
      //         message: 'Minor First Name is required',
      //         path,
      //       });
      //     }
      //     return true;
      //   }),
      // minorLastName: yup
      //   .string()
      //   .nullable()
      //   .test('minorLastName', (value, context) => {
      //     const fieldValue = value === undefined ? '' : value;
      //     const { path, createError, parent } = context;
      //     if (!fieldValue && isOnboardingTypeMinor(parent.onboardingType)) {
      //       return createError({
      //         message: 'Minor Last Name is required',
      //         path,
      //       });
      //     }
      //     return true;
      //   }),
      guardianRelationship: yup
        .string()
        .nullable()
        .test('guardianRelationship', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent, options } = context;
          if (
            !fieldValue &&
            isOnboardingTypeMinor(parent.onboardingType) &&
            shouldValidateUponSaveLater(options.context?.saveType)
          ) {
            return createError({
              message: 'Relationship with guardian is required',
              path,
            });
          }
          return true;
        }),
      naturalParent: yup
        .string()
        .nullable()
        .test('naturalParent', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent, options } = context;
          if (
            isOnboardingTypeMinor(parent.onboardingType) &&
            isFatherMother(parent.guardianRelationship || '')
          ) {
            if (!fieldValue && shouldValidateUponSaveLater(options.context?.saveType)) {
              return createError({
                message: 'Natural Parents field is required',
                path,
              });
            } else if (fieldValue && !Object.keys(isNaturalParent).includes(fieldValue)) {
              return createError({
                message: 'Invalid value for Natural Parents',
                path,
              });
            }
          }
          return true;
        }),
      guardianRelationshipTypeOther: yup
        .string()
        .nullable()
        .test('guardianRelationshipTypeOther', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent, options } = context;
          if (
            isOnboardingTypeMinor(parent.onboardingType) &&
            parent.guardianRelationship === 'OTHERS'
          ) {
            if (!fieldValue && shouldValidateUponSaveLater(options.context?.saveType)) {
              return createError({
                message: 'Please specify Relationship with guardian is required',
                path,
              });
            } else if (fieldValue && !stringRegex.test(fieldValue)) {
              return createError({
                message: 'Special characters and numbers not allowed',
                path,
              });
            }
          }
          return true;
        }),
      minorFatherName: yup
        .string()
        .nullable()
        .test('minorFatherName', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent, options } = context;
          if (
            !fieldValue &&
            isOnboardingTypeMinor(parent.onboardingType) &&
            shouldValidateUponSaveLater(options.context?.saveType)
          ) {
            return createError({
              message: 'Minor Father Name is required',
              path,
            });
          }
          return true;
        }),
      minorGender: yup
        .string()
        .nullable()
        .test('minorGender', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent, options } = context;
          if (
            !fieldValue &&
            isOnboardingTypeMinor(parent.onboardingType) &&
            shouldValidateUponSaveLater(options.context?.saveType)
          ) {
            return createError({
              message: 'Minor Gender is required',
              path,
            });
          }
          return true;
        }),

      minorEmail: yup.string().nullable().matches(emailRegex, 'Invalid Email ID'),
      minorMobile: yup
        .string()
        .nullable()
        .test('minorMobile', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === parent.minorCountryNameAndCode) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (
            value &&
            parent.minorCountryNameAndCode === 'India: +91' &&
            // eslint-disable-next-line
            !phoneRegExp.test(value as any)
          ) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          // eslint-disable-next-line
          if (value && !isValidPhoneNumber(value as any, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          return true;
        }),
    })
  ),
});

const validateOverseasAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status } = currentApplicant.address;
  return checkForCorrespondenceAddress(nationality, status) ? true : fieldValue !== '';
};

const validatePermanentAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  applicationType: string
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  const { permanentAddressSameAsCorresponding = false } =
    checkForCorrespondenceAddress(nationality, status) ||
    APPLICATION_TYPE.NON_INDIVIDUAL === applicationType
      ? currentApplicant.address.correspondence || {}
      : currentApplicant.address.overseas || {};
  return permanentAddressSameAsCorresponding ? true : fieldValue !== '';
};

const validatePincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  addressType: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  if (addressType === 'correspondence' && !checkForCorrespondenceAddress(nationality, status)) {
    return true;
  }
  if (addressType === 'overseas' && checkForCorrespondenceAddress(nationality, status)) {
    return true;
  }
  if (
    !fieldValue &&
    ['india', 'indian'].includes(parent.country?.toLowerCase() || '') &&
    shouldValidateUponSaveLater(optionsContext?.saveType || '')
  ) {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (fieldValue && parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode should be 6 digit code',
      path,
    });
  }
  if (
    fieldValue &&
    parent.country?.toLowerCase() !== 'india' &&
    !pinCodeRegexOtherThanIndia.test(fieldValue)
  ) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }

  return true;
};

const validateCorrespondenceAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  return checkForCorrespondenceAddress(nationality, status) ? fieldValue !== '' : true;
};

export const contactDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      phoneNumberoffice: yup.string().nullable(),
      phoneNumberResidence: yup.string().nullable(),
      email: yup
        .string()
        .nullable()
        .matches(emailRegex, 'Invalid Email ID')
        .test('email', (value, context) => {
          const {
            options: { context: optionsContext },
            path,
            createError,
          } = context;
          if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
            return createError({
              message: 'Email is required',
              path,
            });
          }
          return true;
        }),
      mobile: yup
        .string()
        .nullable()
        .test('mobile', (value, context) => {
          const { createError, path, parent, options } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === parent.countryNameAndCode) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
            return createError({
              message: 'Mobile is required',
              path,
            });
          }
          if (value && parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          if (value && !isValidPhoneNumber(value, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      countryNameAndCode: yup
        .string()
        .nullable()
        .test('countryNameAndCode', (value, context) => {
          const { createError, path, parent, options } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === value) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
            return createError({
              message: 'Country Code is required',
              path,
            });
          }
          if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      address: yup.object().shape({
        correspondence: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) => {
              const {
                options: { context: optionsContext },
              } = context;
              if (shouldValidateUponSaveLater(optionsContext?.saveType)) {
                return validateCorrespondenceAddressFields(value, context);
              }
              return true;
            }),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) =>
              // {
              //   const { options } = context;
              //   const { context: optionsContext, index } = options as InternalOptions<Values> & {
              //     index: number;
              //   };
              //   const { applicants = [] } = optionsContext || {};
              //   const currentApplicant = applicants[index] || {};
              //   const { fetchedFromKRA = null } = currentApplicant.address.correspondence || {};
              //   if (!fetchedFromKRA) {
              //     return validateCorrespondenceAddressFields(value, context);
              //   }
              //   return true;
              // }
              {
                const {
                  options: { context: optionsContext },
                } = context;
                if (shouldValidateUponSaveLater(optionsContext?.saveType)) {
                  return validateCorrespondenceAddressFields(value, context);
                }
                return true;
              }
            ),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) =>
              validatePincodeField(value, context, 'correspondence')
            ),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) => {
              const {
                options: { context: optionsContext },
              } = context;
              if (shouldValidateUponSaveLater(optionsContext?.saveType)) {
                return validateCorrespondenceAddressFields(value, context);
              }
              return true;
            }),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) => {
              const {
                options: { context: optionsContext },
              } = context;
              if (shouldValidateUponSaveLater(optionsContext?.saveType)) {
                return validateCorrespondenceAddressFields(value, context);
              }
              return true;
            }),
          // country: yup
          // .string()
          // .nullable()
          // .test('country', 'Country is required', (value, context) =>
          // validateCorrespondenceAddressFields(value, context)
          // ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { countryDropdown = [], applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;

              if (
                !value &&
                checkForCorrespondenceAddress(nationality, status) &&
                shouldValidateUponSaveLater(optionsContext?.saveType || '')
              ) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (
                value &&
                checkForCorrespondenceAddress(nationality, status) &&
                !countryDropdown.includes(value)
              ) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
        }),
        overseas: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) => {
              const { options } = context;
              if (shouldValidateUponSaveLater(options.context?.saveType)) {
                return validateOverseasAddressFields(value, context);
              }
              return true;
            }),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              if (shouldValidateUponSaveLater(options.context?.saveType)) {
                return validateOverseasAddressFields(value, context);
              }
              return true;
            }),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => validatePincodeField(value, context, 'overseas')),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) => {
              const { options } = context;
              if (shouldValidateUponSaveLater(options.context?.saveType)) {
                return validateOverseasAddressFields(value, context);
              }
              return true;
            }),
          // state: yup
          //   .string()
          //   .nullable()
          //   .test('state', 'State is required', (value, context) =>
          //     validateOverseasAddressFields(value, context)
          //   ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [], countryDropdown = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;
              if (!value && checkForCorrespondenceAddress(nationality, status)) {
                return true;
              }
              if (!value && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (value && value.toUpperCase() === 'INDIA') {
                return createError({
                  message: "Country can't be choosen as INDIA",
                  path,
                });
              }
              if (value && !countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
          // country: yup
          //   .string()
          //   .nullable()
          //   .test('country', 'Country is required', (value, context) =>
          //     validateOverseasAddressFields(value, context)
          //   ),
        }),
        permanent: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) => {
              const { options } = context;
              if (shouldValidateUponSaveLater(options?.context?.saveType)) {
                return validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL);
              }
              return true;
            }),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            // .test('address2', 'Address2 is required', (value, context) =>
            //   validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            // ),
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.permanent || {};
              if (!fetchedFromKRA && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                return validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL);
              }
              return true;
            }),
          pincode: yup
            .string()
            .trim()
            .nullable()
            .test('pincode', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;
              const { permanentAddressSameAsCorresponding = false } = checkForCorrespondenceAddress(
                nationality,
                status
              )
                ? currentApplicant.address.correspondence || {}
                : currentApplicant.address.overseas || {};
              if (!permanentAddressSameAsCorresponding) {
                return validatePincodeField(value, context, 'permanent');
              }
              return true;
            }),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) => {
              const { options } = context;
              if (shouldValidateUponSaveLater(options.context?.saveType)) {
                return validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL);
              }
              return true;
            }),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) => {
              const { options } = context;
              if (shouldValidateUponSaveLater(options.context?.saveType)) {
                return validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL);
              }
              return true;
            }),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { countryDropdown = [] } = optionsContext || {};
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status } = currentApplicant.address;
              const { permanentAddressSameAsCorresponding = false } = checkForCorrespondenceAddress(
                nationality,
                status
              )
                ? currentApplicant.address.correspondence || {}
                : currentApplicant.address.overseas || {};
              if (!permanentAddressSameAsCorresponding) {
                if (!value && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                // if (
                //   applicantStatusMasters[status || ''] === applicantStatusMasters.NRI &&
                //   value.toUpperCase() === 'INDIA'
                // ) {
                //   return createError({
                //     message:
                //       "If the Status is Individual (NRI) then Country can't be choosen as INDIA",
                //     path,
                //   });
                // }
                if (value && !countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
              }
              return true;
            }),
          // country: yup
          //   .string()
          //   .nullable()
          //   .test('country', 'Country is required', (value, context) =>
          //     validatePermanentAddressFields(value, context)
          //   ),
        }),
      }),
    })
  ),
});

export const KYCDetailsSchema = (brokers: Broker[], createdAt: string, toggler: togglerType) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        occupationDetails: yup
          .string()
          .oneOf(occupationDetailsMasters, 'Invalid value for occupation details')
          .test('occupationDetails', (value, context) => {
            const {
              options: { context: optionsContext },
              path,
              createError,
            } = context;
            if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
              return createError({
                message: 'Occupation details is required',
                path,
              });
            }
            return true;
          }),
        politicallyExposedPersonStatus: yup
          .string()
          .oneOf(PEPsMasters, 'Invalid value for PEP Status')
          .test('politicallyExposedPersonStatus', (value, context) => {
            const {
              options: { context: optionsContext },
              path,
              createError,
            } = context;
            if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
              return createError({
                message: 'PEP status is required',
                path,
              });
            }
            return true;
          }),
        ckycNo: yup.number().test('ckycNo', (value, context) => {
          const {
            options: { context: optionsContext },
            createError,
            path,
          } = context;
          const { applicants } = optionsContext || {};
          if (!value) {
            return true;
          }
          if (value?.toString().length !== 14) {
            return createError({
              message: 'Invalid CKYC No',
              path,
            });
          }
          if (
            applicants.filter(
              (applicant: Applicant) => applicant.ckycNo?.toString() === value.toString()
            ).length !== 1
          ) {
            return createError({
              message:
                'There is already same CKYC Number for an applicant associated with this application',
              path,
            });
          }
          return true;
        }),
        dlclId: yup
          .string()
          .nullable()
          .when('applicant_type', {
            is: (applicant_type: string) => isFirstApplicant(applicant_type),
            then: yup
              .string()
              .nullable()
              .oneOf(
                Object.keys(DLCLMasters),
                'Invalid value for Demat Account Details (for allotment of units)'
              )
              .test(
                'dlclId',
                'Demat Account Details (for allotment of units) is required',
                (value, context) => {
                  const { options } = context;
                  return shouldValidateUponSaveLater(options?.context?.saveType) ? !!value : true;
                }
              ),
          }),
        dpId: yup
          .string()
          .nullable()
          .when(['applicant_type', 'dlclId'], {
            is: (applicant_type: string, dlclId: string) =>
              isFirstApplicant(applicant_type) && getdpIdField(dlclId || ''),
            then: yup
              .string()
              .nullable()
              .test('dpId', (value, context) => {
                const {
                  path,
                  createError,
                  parent,
                  options: { context: optionsContext },
                } = context;
                if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
                  return createError({
                    message: `${isCDSL(parent.dlclId) ? 'DPCL ID' : 'DP ID'} is required`,
                    path,
                  });
                }
                if (
                  checkValidationBasedOnDate(
                    createdAt,
                    toggler?.dateFeatureToggles?.dematFieldValidationDate || ''
                  )
                ) {
                  if (value && !isCDSL(parent.dlclId) && !dpIdRegex.test(value)) {
                    return createError({
                      message: `DP ID should consist of 8 digits and must start with 'IN'`,
                      path,
                    });
                  }

                  if (value && isCDSL(parent.dlclId)) {
                    if (
                      !dpIdNumberRegex.test(value) &&
                      checkValidationBasedOnDate(
                        createdAt,
                        toggler?.dateFeatureToggles?.dpIdFieldValidationDate || ''
                      )
                    ) {
                      return createError({
                        message: 'DPCL ID should consist of 16 numeric digits',
                        path,
                      });
                    }
                    if (
                      !checkValidationBasedOnDate(
                        createdAt,
                        toggler?.dateFeatureToggles?.dpIdFieldValidationDate || ''
                      ) &&
                      !(cdslRegex.test(value) || dpIdNumberRegex.test(value))
                    ) {
                      return createError({
                        message: 'DPCL ID should consist of 8 or 16 numeric digits',
                        path,
                      });
                    }
                    return true;
                  }
                  return true;
                }
                return true;
              }),
          }),
        clId: yup
          .string()
          .nullable()
          .when(['applicant_type', 'dlclId'], {
            is: (applicant_type: string, dlclId: string) =>
              isFirstApplicant(applicant_type) && getclIdField(dlclId || ''),
            then: yup
              .string()
              .nullable()
              .test('clId', (value, context) => {
                const {
                  path,
                  createError,
                  options: { context: optionsContext },
                } = context;
                if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
                  return createError({
                    message: 'CL ID is required',
                    path,
                  });
                }
                if (
                  checkValidationBasedOnDate(
                    createdAt,
                    toggler?.dateFeatureToggles?.dematFieldValidationDate || ''
                  )
                ) {
                  if (value && !cdslRegex.test(value)) {
                    return createError({
                      message: 'CL ID should consist of 8 numeric digits',
                      path,
                    });
                  }
                }
                return true;
              }),
          }),
        nameOfBroker: yup
          .string()
          .nullable()
          .when('dlclId', {
            is: (dlclId: string) => getdpIdField(dlclId || ''),
            then: yup
              .string()
              .nullable()
              .test('nameOfBroker', (value, context) => {
                const { path, createError } = context;
                const brokerList = brokers.map((broker) => broker.key);
                if (!value) {
                  return true;
                }
                if (value && !brokerList.includes(value)) {
                  return createError({
                    message: 'Invalid value for Name of Broker/Depository Participant (DP)',
                    path,
                  });
                }
                return true;
              }),
          }),
        otherNameOfBroker: yup
          .string()
          .nullable()
          .when(['dlclId', 'nameOfBroker'], {
            is: (dlclId: string, nameOfBroker: string) =>
              getdpIdField(dlclId || '') && otherNameOfBroker(nameOfBroker),
            then: yup.string().nullable().required('Other Name of Broker is required'),
          }),
      })
    ),
  });

const createSchemaForNonIndianResident = (fieldName: string, validationMessage: string) => {
  return {
    is: (value: string) => value.toString() === 'true',
    then: yup.string().test(fieldName, (value, context) => {
      const { path, createError, options } = context;
      if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
        return createError({
          message: validationMessage,
          path,
        });
      }
      return true;
    }),
  };
};

export const FATCAValidationSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      typeOfAddressProvidedAtKRA: yup
        .string()
        .oneOf(addressTypesMasters, 'Invalid value for Type of Address Provided At KRA')
        .test('typeOfAddressProvidedAtKRA', (value, context) => {
          const {
            options: { context: optionsContext },
            path,
            createError,
          } = context;
          if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
            return createError({
              message: 'Please select type of address provided',
              path,
            });
          }
          return true;
        }),
      placeOfBirth: yup
        .string()
        .matches(stringRegex, 'Special characters and numbers not allowed')
        .test('placeOfBirth', (value, context) => {
          const {
            options: { context: optionsContext },
            path,
            createError,
          } = context;
          if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
            return createError({
              message: 'Please enter place of birth',
              path,
            });
          }
          return true;
        }),
      // countryOfBirth: yup
      //   .string()
      //   .required('Please enter country of birth')
      //   .matches(stringRegex, 'Please enter country of birth'),
      countryOfBirth: yup
        .string()
        .nullable()
        .test('countryOfBirth', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path } = context;
          const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
          const { countryDropdown = [] } = optionsContext || {};
          if (!fieldValue && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
            return createError({
              message: 'country of birth is required',
              path,
            });
          }
          if (fieldValue && !countryDropdown.map((list) => list.name).includes(fieldValue)) {
            return createError({
              message: 'Invalid value for country of birth',
              path,
            });
          }
          return true;
        }),
      countryOfNationality: yup
        .string()
        .nullable()
        .test('countryOfNationality', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
          const { countryDropdown = [] } = optionsContext || {};
          if (!fieldValue && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
            return createError({
              message: 'country of nationality is required',
              path,
            });
          }
          if (
            fieldValue &&
            !getCountryBasedOnStatus(countryDropdown, parent.statusSubType)
              .map((list) => list.name)
              .includes(fieldValue)
          ) {
            return createError({
              message: 'Invalid value for country of nationality',
              path,
            });
          }
          return true;
        }),
      taxCountryName: yup
        .string()
        .when(
          'taxResidentOfAnyCountryOtherThanIndia',
          createSchemaForNonIndianResident('taxCountryName', 'Please enter tax country name')
        ),
      taxID: yup
        .string()
        .max(20, 'Invalid Tax ID')
        .when(
          'taxResidentOfAnyCountryOtherThanIndia',
          createSchemaForNonIndianResident('taxID', 'Please enter Tax ID')
        ),
      idType: yup
        .string()
        .when(
          'taxResidentOfAnyCountryOtherThanIndia',
          createSchemaForNonIndianResident('idType', 'Please enter ID Type')
        ),
      // nameOfEntity: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter name of entity')
      //   ),
      // dateOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter date of incorporation')
      //   ),
      // cityOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter city of incorporation')
      //   ),
      // countryOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter country of incorporation')
      //   ),
      // entityExcemptionCode: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter entity exemption code')
      //   ),
    })
  ),
});

export const adminSupportValidation = yup.object().shape({
  supportType: yup.string().required('Support Type is required'),
  application_number: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        [
          adminSupportMaster.change_expired_status,
          adminSupportMaster.change_status_to_draft,
          adminSupportMaster.application_complete_for_topup,
        ].includes(adminSupportMaster[supportType]),
      then: yup.string().required('Application Number / Topup Number is required'),
    }),
  status: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        adminSupportMaster[supportType] === adminSupportMaster.change_expired_status,
      then: yup.string().required('Status is required'),
    }),
  phone: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        ![
          adminSupportMaster.change_expired_status,
          adminSupportMaster.change_status_to_draft,
          adminSupportMaster.process_signed_applications,
          adminSupportMaster.delete_topup_application,
          adminSupportMaster.application_complete_for_topup,
        ].includes(adminSupportMaster[supportType]),
      then: yup.string().test('phone', (value, context) => {
        const { createError, path, parent } = context;
        const codesList = getCountryCodes()
          .map((codes) => {
            if (codes.label === parent.countryCode) {
              return codes.countryCode;
            }
          })
          .filter((ele) => ele)
          .toString();
        if (!value) {
          return createError({
            message: 'Mobile number is required',
            path,
          });
        }
        if (parent.countryCode === 'India: +91' && !phoneRegExp.test(value)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
        if (!isValidPhoneNumber(value, codesList as CountryCode)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
        return true;
      }),
    }),
  // .required('Mobile Number is required')
  // .min(8, 'Invalid mobile number')
  // .max(16, 'Invalid mobile number')
  // .matches(phoneRegExp, 'Invalid mobile number'),
  // countryCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }
  //     return true;
  //   }),
});

export const nomineeDetailsSchema = (applicants?: Partial<Applicant>[]) =>
  yup.object().shape({
    nominees: yup.array().of(
      yup.object().shape({
        nomineeName: yup
          .string()
          .nullable()
          .when(['isActive'], {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .test('nomineeName', (value, context) => {
                const { path, createError, options } = context;
                if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                  return createError({
                    message: 'Nominee Name is required',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineeRelationship: yup.string().when(['Relationship', 'isActive'], {
          is: (Relationship: string | null, isActive: boolean) =>
            isActive && Relationship === 'OTHERS',
          then: yup
            .string()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .test('nomineeRelationship', (value, context) => {
              const { path, createError, options } = context;
              if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                return createError({
                  message: 'Please Specify Relationship',
                  path,
                });
              }
              return true;
            }),
        }),
        Relationship: yup
          .string()
          .nullable()
          .when(['isActive'], {
            is: (isActive: boolean) => {
              return isActive;
            },
            then: yup
              .string()
              .nullable()
              .oneOf(NomineeRelations, 'Invalid value for Nominee Relationship')
              .test('Relationship', (value, context) => {
                const { path, createError, options } = context;
                if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                  return createError({
                    message: 'Please select relationship',
                    path,
                  });
                }
                return true;
              }),
          }),

        dateOfBirth: yup
          .string()
          .nullable()
          .when(['isActive'], {
            is: (isActive: boolean) => {
              return isActive;
            },
            then: yup
              .string()
              .nullable()
              .test('dateOfBirth', (value, context) => {
                const { path, createError, options } = context;
                if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                  return createError({
                    message: 'Date of Birth is required',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineePercentage: yup.number().when(['isActive'], {
          is: (isActive: boolean) => isActive,
          then: yup
            .number()
            .typeError('Nominee % should be a number')
            .test(
              'nomineePercentage',
              'Total Nominee % should be equal to 100%',
              (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                } = context;
                if (
                  !value &&
                  value !== 0 &&
                  shouldValidateUponSaveLater(optionsContext?.saveType)
                ) {
                  return createError({
                    message: 'Nominee % is required',
                    path,
                  });
                }
                if (value === 0) {
                  return createError({
                    message: 'Nominee % should be greater then 0',
                    path,
                  });
                }
                const { nominees } = optionsContext || {};
                const total = nominees
                  .filter((_nominee: NomineeType) => _nominee.isActive)
                  .map((nominee: NomineeType) => Number(nominee.nomineePercentage))
                  .reduce((a: number, b: number) => a + b, 0);
                return nominees.length && total === 100;
              }
            ),
        }),
        guardianName: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .test('guardianName', (value, context) => {
                const { path, createError, options } = context;
                if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                  return createError({
                    message: 'Guardian Name is required',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineePan: yup
          .string()
          .nullable()
          .matches(individualPanRegex, 'Only individual PANs are allowed')
          .test('nomineePan', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              // eslint-disable-next-line
              parent,
            } = context;
            const { nominees = [] } = optionsContext || {};
            const applicantsPan = applicants?.map((applicant) => applicant.panNo) || [];
            // if (!value && !isMinor(parent.dateOfBirth || '')) {
            //   return createError({
            //     message: 'Nominee PAN is required',
            //     path,
            //   });
            // }
            if (value && applicantsPan.length && applicantsPan?.includes(value)) {
              return createError({
                message: 'Applicant PAN not allowed to enter as nominee PAN',
                path,
              });
            }
            if (
              value &&
              nominees.filter((nominee: NomineeType) => nominee.nomineePan === value).length !== 1
            ) {
              return createError({
                message: 'There is already same pan for a nominee associated with this application',
                path,
              });
            }
            // if (value && parent.nomineeGuardianPan && value === parent.nomineeGuardianPan) {
            //   return createError({
            //     message: 'Nominee Pan should not be same as guardian PAN',
            //     path,
            //   });
            // }
            return true;
          }),
        // nomineeGuardianPan: yup
        //   .string()
        //   .nullable()
        //   .when('dateOfBirth', {
        //     is: (dateOfBirth: string | null) => isMinor(dateOfBirth || ''),
        //     then: yup
        //       .string()
        //       .nullable()
        //       .matches(individualPanRegex, 'Only individual PANs are allowed')
        //       .test('nomineeGuardianPan', (value, context) => {
        //         const {
        //           options: { context: optionsContext },
        //           createError,
        //           path,
        //           parent,
        //         } = context;
        //         // if (!value && isMinor(parent.dateOfBirth || '')) {
        //         //   return createError({
        //         //     message: 'Guardian Pan is required',
        //         //     path,
        //         //   });
        //         // }
        //         if (value && parent.nomineePan && value === parent.nomineePan) {
        //           return createError({
        //             message: 'Guardian Pan should not be same as nominee PAN',
        //             path,
        //           });
        //         }
        //         return true;
        //       }),
        //   }),
      })
    ),
  });

export const commonBankSchema = (
  applicants: Partial<Applicant>[] | undefined,
  isTopupBank = false // New parameter to handle topupBanks
) =>
  yup.object().shape({
    ifscCode: yup
      .string()
      .nullable()
      .matches(ifscRegex, 'Invalid IFSC Code')
      .test('ifscCode', (value, context) => {
        const {
          options: { context: optionsContext },
          path,
          createError,
        } = context;
        if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
          return createError({
            message: 'IFSC Code is required',
            path,
          });
        }
        return true;
      }),
    bankAccountNumber: yup
      .string()
      .nullable()
      .matches(accountNumberRegex, 'Please enter valid Account Number')
      .test('bankAccountNumber', (value, context) => {
        const {
          options: { context: optionsContext },
          path,
          createError,
        } = context;
        if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
          return createError({
            message: 'Bank Account Number is required',
            path,
          });
        }
        return true;
      })
      .test('bankAccountNumber', 'Account number already used', (value, context) => {
        const {
          options: { context: optionsContext },
        } = context;
        const relevantBanks = isTopupBank ? optionsContext?.topupBanks : optionsContext?.banks;
        return value
          ? relevantBanks.filter((bank: Bank) => bank.bankAccountNumber === value).length === 1
          : true;
      }),
    bankAccountType: yup
      .string()
      .nullable()
      .test('bankAccountType', 'Bank Account Type is required', (value, context) => {
        const {
          options: { context: optionsContext },
          createError,
          path,
        } = context;
        const fieldValue = value === null ? '' : value;
        const { applicationType, status } = optionsContext || {};
        const relevantBanks = isTopupBank ? optionsContext?.topupBanks : optionsContext?.banks;

        if (
          applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
          !['huf'].includes(applicants ? applicants[0]?.investorType || '' : '') &&
          !fieldValue
        ) {
          return true;
        }
        if (shouldValidateUponSaveLater(optionsContext?.saveType)) {
          if (
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
            fieldValue &&
            ![BankAccountTypeMaster.Current, BankAccountTypeMaster.Savings].includes(fieldValue)
          ) {
            return false;
          } else if (
            fieldValue &&
            applicantStatusMasters[status] !== applicantStatusMasters.NRI &&
            !Object.values(BankAccountTypeMaster).includes(fieldValue)
          ) {
            return false;
          }
          if (
            fieldValue &&
            applicantStatusMasters[status] === applicantStatusMasters.NRI &&
            !Object.values(NRIBankAccountTypeMaster).includes(fieldValue)
          ) {
            return false;
          }
        }
        if (
          fieldValue &&
          applicantStatusMasters[status] === applicantStatusMasters.NRI &&
          relevantBanks.filter(
            (bank: Bank) =>
              Object.values(NRIBankAccountTypeMaster).includes(bank.bankAccountType) &&
              Object.values(NRIBankAccountTypeMaster).includes(fieldValue) &&
              bank.bankAccountType != fieldValue
          ).length === 1
        ) {
          return createError({
            message: 'Both Bank Account Type should be same',
            path,
          });
        }

        return shouldValidateUponSaveLater(optionsContext?.saveType) ? !!fieldValue : true;
      }),
    bankName: yup
      .string()
      .nullable()
      .test('bankName', (value, context) => {
        const {
          options: { context: optionsContext },
          path,
          createError,
        } = context;
        if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
          return createError({
            message: 'Bank Name is required',
            path,
          });
        }
        return true;
      }),
    bankBranch: yup
      .string()
      .nullable()
      .test('bankBranch', (value, context) => {
        const {
          options: { context: optionsContext },
          path,
          createError,
        } = context;
        if (!value && shouldValidateUponSaveLater(optionsContext?.saveType)) {
          return createError({
            message: 'Bank Branch is required',
            path,
          });
        }
        return true;
      }),
    defaultBankAccount: yup
      .bool()
      .test('defaultBankAccount', 'Please check the default bank account', (value, context) => {
        const {
          options: { context: optionsContext },
        } = context;
        const relevantBanks = isTopupBank ? optionsContext?.topupBanks : optionsContext?.banks;
        const isDefaultBankSelected = relevantBanks.some((bank: Bank) => bank.defaultBankAccount);
        return shouldValidateUponSaveLater(optionsContext?.saveType) ? isDefaultBankSelected : true;
      }),
  });

export const bankDetailsSchema = (applicants: Partial<Applicant>[] | undefined) =>
  yup.object().shape({
    banks: yup.array().of(commonBankSchema(applicants, false)),
    topupBanks: yup.array().of(commonBankSchema(applicants, true)),
  });

export const investmentPaymentDetailsSchema = (
  minCommitmentAmount: number,
  maxCommitmentAmount: number,
  minContributionPercentage: number,
  currency: string | null,
  saveType: string,
  maxExitCharge: number | null,
  maxHurdleRate: number | null,
  maxManagementFee: number | null,
  maxPerformanceFee: number | null,
  maxPlacementFee: number | null,
  maxOperatingExpense: number | null,
  maxSetupFee: number | null,
  minExitCharge: number | null,
  minHurdleRate: number | null,
  minManagementFee: number | null,
  minPerformanceFee: number | null,
  minPlacementFee: number | null,
  minOperatingExpense: number | null,
  minSetupFee: number | null
) =>
  yup.object().shape({
    schemeId: yup.string().test('schemeId', 'Fund Scheme is required', (value, context) => {
      const { options } = context;
      return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
    }),
    planId: yup.string().when(['schemeId'], {
      is: (schemeId: string) => schemeId,
      then: yup.string().test('planId', 'Class Plan is required', (value, context) => {
        const { options } = context;
        return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
      }),
    }),
    // topupAmount: yup
    // .number()
    // .nullable()
    // .when(['schemeId', 'planId'], {
    // is: (schemeId: string, planId: string) =>
    // schemeId && planId && shouldValidateUponSaveLater(saveType) && topUpApplication,
    // then: yup
    // .number()
    // .nullable()
    // .min(1, 'Top Up Commitment Amount should be greater than 0')
    // .required('Top Up Amount is required'),
    // }),
    commitmentAmount: yup
      .number()
      .nullable()
      .when(['schemeId', 'planId'], {
        is: (schemeId: string, planId: string) =>
          schemeId && planId && shouldValidateUponSaveLater(saveType),
        then: yup
          .number()
          .nullable()
          .min(
            minCommitmentAmount,
            `Commitment Amount should be greater than or equal to ${currencyConversion(
              minCommitmentAmount,
              currency
            )}`
          )
          .max(
            maxCommitmentAmount,
            `Commitment Amount should be between ${
              currencyConversion(minCommitmentAmount, currency) || '1 Cr'
            } and ${currencyConversion(maxCommitmentAmount, currency) || '2 Cr'}`
          )
          .test('commitmentAmount', 'Commitment Amount is required', (value, context) => {
            const { options } = context;
            return shouldValidateUponSaveLater(options.context?.saveType) &&
              !value &&
              !(value === 0)
              ? !!value
              : true;
          }),
      }),
    contributionChequeAmount: yup
      .number()
      .nullable()
      .when(['schemeId', 'planId'], {
        is: (schemeId: string, planId: string) =>
          schemeId && planId && shouldValidateUponSaveLater(saveType),
        then: yup
          .number()
          .nullable()
          .test(
            'contributionChequeAmount',
            'Contribution Cheque Amount is required',
            (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType) &&
                !value &&
                !(value === 0)
                ? !!value
                : true;
            }
          )
          .max(
            yup.ref('commitmentAmount'),
            'Contribution Amount should not be greater than commitment amount'
          )
          .test(
            'contributionChequeAmount',
            `${
              Number(minContributionPercentage) === 100
                ? `Contribution Amount should be equal to commitment amount`
                : `Contribution Amount should be greater than or equal to ${minContributionPercentage}% of commitment amount`
            }`,
            (value, context) => {
              const { parent } = context;
              const { commitmentAmount } = parent;
              return (
                (100 * Number(value)) / Number(commitmentAmount) >=
                Number(minContributionPercentage)
              );
            }
          ),
      }),

    setupFeeTDS: yup
      .number()
      .nullable()
      .min(0, `TDS should be greater than 0`)
      .max(yup.ref('totalSetupFee'), 'TDS should not be greater than Total Setup Fee'),
    hurdleRate: yup
      .number()
      .nullable()
      .test('hurdleRate', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.hurdleRateApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Hurdle Rate is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minHurdleRate) || value > Number(maxHurdleRate))
          ) {
            return createError({
              message: `Hurdle Rate should be in between or equal to ${Number(
                minHurdleRate
              )} and ${Number(maxHurdleRate)}`,
              path,
            });
          }
        }
        return true;
      }),
    performanceFee: yup
      .number()
      .nullable()
      .test('performanceFee', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.performanceFeeApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Performance Fee is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minPerformanceFee) || value > Number(maxPerformanceFee))
          ) {
            return createError({
              message: `Performance Fee should be in between or equal to ${Number(
                minPerformanceFee
              )} and ${Number(maxPerformanceFee)}`,
              path,
            });
          }
        }

        return true;
      }),
    exitCharge: yup
      .number()
      .nullable()
      .test('exitCharge', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.exitChargeApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Exit Charge is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minExitCharge) || value > Number(maxExitCharge))
          ) {
            return createError({
              message: `Exit Charge should be in between or equal to ${Number(
                minExitCharge
              )} and ${Number(maxExitCharge)}`,
              path,
            });
          }
        }
        return true;
      }),
    managementFeesRate: yup
      .number()
      .nullable()
      .test('managementFeesRate', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.managementFeeApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Management Fee is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minManagementFee) || value > Number(maxManagementFee))
          ) {
            return createError({
              message: `Management Fee should be in between or equal to ${Number(
                minManagementFee
              )} and ${Number(maxManagementFee)}`,
              path,
            });
          }
        }
        return true;
      }),
    operatingExpenses: yup
      .number()
      .nullable()
      .test('operatingExpenses', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.operatingExpenseApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Operating Expense is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minOperatingExpense) || value > Number(maxOperatingExpense))
          ) {
            return createError({
              message: `Operating Expense should be in between or equal to ${Number(
                minOperatingExpense
              )} and ${Number(maxOperatingExpense)}`,
              path,
            });
          }
        }
        return true;
      }),
    placementFee: yup
      .number()
      .nullable()
      .test('placementFee', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.placementFeeApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Placement Fee is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minPlacementFee) || value > Number(maxPlacementFee))
          ) {
            return createError({
              message: `Placement Fee should be in between or equal to ${Number(
                minPlacementFee
              )} and ${Number(maxPlacementFee)}`,
              path,
            });
          }
        }
        return true;
      }),
    setupFeePercentage: yup
      .number()
      .nullable()
      .test('setupFeePercentage', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.setupFeeApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Setup Fee Percentage is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minSetupFee) || value > Number(maxSetupFee))
          ) {
            return createError({
              message: `Setup Fee Percentage should be in between or equal to ${Number(
                minSetupFee
              )} and ${Number(maxSetupFee)}`,
              path,
            });
          }
        }
        return true;
      }),
  });

export const topUpInvestmentPaymentDetailsSchema = (
  minCommitmentAmount: number,
  maxCommitmentAmount: number,
  minContributionPercentage: number,
  currency: string | null,
  saveType: string,
  maxExitCharge: number | null,
  maxHurdleRate: number | null,
  maxManagementFee: number | null,
  maxPerformanceFee: number | null,
  maxPlacementFee: number | null,
  maxOperatingExpense: number | null,
  maxSetupFee: number | null,
  minExitCharge: number | null,
  minHurdleRate: number | null,
  minManagementFee: number | null,
  minPerformanceFee: number | null,
  topUpApplication: boolean | null,
  minPlacementFee: number | null,
  minOperatingExpense: number | null,
  minSetupFee: number | null
) =>
  yup.object().shape({
    schemeId: yup.string().test('schemeId', 'Fund Scheme is required', (value, context) => {
      const { options } = context;
      return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
    }),
    planId: yup.string().when(['schemeId'], {
      is: (schemeId: string) => schemeId,
      then: yup.string().test('planId', 'Class Plan is required', (value, context) => {
        const { options } = context;
        return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
      }),
    }),
    topupAmount: yup
      .number()
      .nullable()
      .when(['schemeId', 'planId'], {
        is: (schemeId: string, planId: string) =>
          schemeId && planId && shouldValidateUponSaveLater(saveType),
        then: yup
          .number()
          .nullable()
          .min(
            minCommitmentAmount,
            `Top Up Commitment Amount should be between ${
              currencyConversion(minCommitmentAmount, currency) || '1 Cr'
            } and ${currencyConversion(maxCommitmentAmount, currency) || '2 Cr'}`
          )
          .max(
            maxCommitmentAmount,
            `Top Up Commitment Amount should be between ${
              currencyConversion(minCommitmentAmount, currency) || '1 Cr'
            } and ${currencyConversion(maxCommitmentAmount, currency) || '2 Cr'}`
          )
          .test('topupAmount', 'Top Up Commitment Amount is required', (value, context) => {
            const { options } = context;
            return shouldValidateUponSaveLater(options.context?.saveType) &&
              !value &&
              !(value === 0)
              ? !!value
              : true;
          }),
      }),
    commitmentAmount: yup
      .number()
      .nullable()
      .when(['schemeId', 'planId'], {
        is: (schemeId: string, planId: string) =>
          schemeId && planId && shouldValidateUponSaveLater(saveType),
        then: yup
          .number()
          .nullable()
          .min(1, 'Commitment Amount should be greater than 0')
          .required('Commitment Amount is required'),
      }),
    topupContributionAmount: yup
      .number()
      .nullable()
      .when(['schemeId', 'planId'], {
        is: (schemeId: string, planId: string) =>
          schemeId && planId && shouldValidateUponSaveLater(saveType),
        then: yup
          .number()
          .nullable()
          .test(
            'topupContributionAmount',
            'Contribution Cheque Amount is required',
            (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType) &&
                !value &&
                !(value === 0)
                ? !!value
                : true;
            }
          )
          .max(
            yup.ref('topupAmount'),
            'Contribution Amount should not be greater than commitment amount'
          )
          .test(
            'topupContributionAmount',
            `${
              Number(minContributionPercentage) === 100
                ? `Contribution Amount should be equal to commitment amount`
                : `Contribution Amount should be greater than or equal to ${minContributionPercentage}% of commitment amount`
            }`,
            (value, context) => {
              const { parent } = context;
              const { topupAmount } = parent;
              return (
                (100 * Number(value)) / Number(topupAmount) >= Number(minContributionPercentage)
              );
            }
          ),
      }),

    setupFeeTDS: yup
      .number()
      .nullable()
      .min(0, `TDS should be greater than 0`)
      .max(yup.ref('totalSetupFee'), 'TDS should not be greater than Total Setup Fee'),
    hurdleRate: yup
      .number()
      .nullable()
      .test('hurdleRate', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.hurdleRateApplicable) &&
          !topUpApplication
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Hurdle Rate is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minHurdleRate) || value > Number(maxHurdleRate))
          ) {
            return createError({
              message: `Hurdle Rate should be in between or equal to ${Number(
                minHurdleRate
              )} and ${Number(maxHurdleRate)}`,
              path,
            });
          }
        }
        return true;
      }),
    performanceFee: yup
      .number()
      .nullable()
      .test('performanceFee', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.performanceFeeApplicable) &&
          !topUpApplication
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Performance Fee is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minPerformanceFee) || value > Number(maxPerformanceFee))
          ) {
            return createError({
              message: `Performance Fee should be in between or equal to ${Number(
                minPerformanceFee
              )} and ${Number(maxPerformanceFee)}`,
              path,
            });
          }
        }

        return true;
      }),
    exitCharge: yup
      .number()
      .nullable()
      .test('exitCharge', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.exitChargeApplicable) &&
          !topUpApplication
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Exit Charge is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minExitCharge) || value > Number(maxExitCharge))
          ) {
            return createError({
              message: `Exit Charge should be in between or equal to ${Number(
                minExitCharge
              )} and ${Number(maxExitCharge)}`,
              path,
            });
          }
        }
        return true;
      }),
    managementFeesRate: yup
      .number()
      .nullable()
      .test('managementFeesRate', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.managementFeeApplicable) &&
          !topUpApplication
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Management Fee is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minManagementFee) || value > Number(maxManagementFee))
          ) {
            return createError({
              message: `Management Fee should be in between or equal to ${Number(
                minManagementFee
              )} and ${Number(maxManagementFee)}`,
              path,
            });
          }
        }
        return true;
      }),
    operatingExpenses: yup
      .number()
      .nullable()
      .test('operatingExpenses', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.operatingExpenseApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Operating Expense is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minOperatingExpense) || value > Number(maxOperatingExpense))
          ) {
            return createError({
              message: `Operating Expense should be in between or equal to ${Number(
                minOperatingExpense
              )} and ${Number(maxOperatingExpense)}`,
              path,
            });
          }
        }
        return true;
      }),
    placementFee: yup
      .number()
      .nullable()
      .test('placementFee', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.placementFeeApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Placement Fee is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minPlacementFee) || value > Number(maxPlacementFee))
          ) {
            return createError({
              message: `Placement Fee should be in between or equal to ${Number(
                minPlacementFee
              )} and ${Number(maxPlacementFee)}`,
              path,
            });
          }
        }
        return true;
      }),
    setupFeePercentage: yup
      .number()
      .nullable()
      .test('setupFeePercentage', (value, context) => {
        const { path, createError, parent } = context;
        if (
          shouldValidateUponSaveLater(saveType) &&
          isFieldApplicable(parent?.setupFeeApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Setup Fee Percentage is required',
              path,
            });
          }
          if (
            (value || value === 0) &&
            (value < Number(minSetupFee) || value > Number(maxSetupFee))
          ) {
            return createError({
              message: `Setup Fee Percentage should be in between or equal to ${Number(
                minSetupFee
              )} and ${Number(maxSetupFee)}`,
              path,
            });
          }
        }
        return true;
      }),
  });

export const documentDetailsSchema = yup.object().shape({
  applicants: yup.array().when('isTopup', {
    is: (value: boolean) => value === false,
    then: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { applicants = [] } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, options: optionsDropdown } =
                        applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (
                        optionsDropdown &&
                        shouldValidateUponSaveLater(optionsContext?.saveType || '')
                      ) {
                        if (!fieldValue && required === 'true') {
                          return createError({
                            message: `Please select valid value`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { applicants = [] } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName } =
                        applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (!value && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                        if (!value && required === 'true') {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  }),
  topUpApplicants: yup.array().when('isTopup', {
    is: (value: boolean) => value === true,
    then: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { topUpApplicants = [] } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, options: optionsDropdown } =
                        topUpApplicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (
                        optionsDropdown &&
                        shouldValidateUponSaveLater(optionsContext?.saveType || '')
                      ) {
                        if (!fieldValue && required === 'true') {
                          return createError({
                            message: `Please select valid value`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { topUpApplicants = [] } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName } =
                        topUpApplicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (!value && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                        if (!value && required === 'true') {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  }),
  nominees: yup.array().when('isTopup', {
    is: (value: boolean) => value === false,
    then: yup.array().of(
      yup.object().shape({
        nomineedocuments: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { nominees = [] } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const {
                        required,
                        documentName,
                        options: optionsDropdown,
                      } = nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[
                        indexes[2]
                      ];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (
                        optionsDropdown &&
                        shouldValidateUponSaveLater(optionsContext?.saveType || '')
                      ) {
                        if (!fieldValue && required === 'true') {
                          return createError({
                            message: `Please Select ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { nominees = [] } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName } =
                        nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }
                      if (
                        !value &&
                        required === 'true' &&
                        shouldValidateUponSaveLater(optionsContext?.saveType || '')
                      ) {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  }),
  // applicants: yup.array().of(
  //   yup.object().shape({
  //     documents: yup.object().shape({
  //       // identityProof: yup.object().shape({
  //       //   documentName: yup.string().required('Please select identity proof'),
  //       //   documentId: yup.string().required('Please upload Identity Proof'),
  //       // }),
  //       // addressProof: yup.object().shape({
  //       //   documentName: yup.string().required('Please select Address Proof'),
  //       //   documentId: yup.string().required('Please upload Address Proof'),
  //       // }),
  //       bankAccountProof: yup.object().shape({
  //         documentName: yup
  //           .string()
  //           .test('documentName', 'Please select Bank Account Proof', (value, context) =>
  //             DocumentRequiredForPennyDrop(value, context)
  //           ),

  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload Bank Account Proof', (value, context) =>
  //             DocumentRequiredForPennyDrop(value, context)
  //           ),
  //       }),
  //       poaNotarized: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload POA Notarized Document', (value, context) =>
  //             POADocumentValidation(value, context, 'poaNotarized')
  //           ),
  //       }),
  //       investorsConsent: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', "Please upload Investor's Consent Document", (value, context) =>
  //             POADocumentValidation(value, context)
  //           ),
  //       }),
  //       fatca: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload FATCA Document', (value, context) =>
  //             POADocumentValidation(value, context)
  //           ),
  //       }),
  //     }),
  //   })
  // ),
});

export const distributorsValidationSchema = (
  selectedDistributor: SubDistributor[],
  distributors: Distributor[],
  isSupportRM: boolean
) =>
  yup.object().shape({
    //role: useSelector((store: RootStateType) => store.auth),
    distributorId: yup
      .string()
      .nullable()
      .when('loginType', {
        is: (loginType: string) => loginType === 'distributor' && !!distributors.length,
        then: yup.string().nullable().required('Please select a distributor name'),
      }),
    subdistributorId: yup
      .string()
      .nullable()
      .when(['loginType', 'distributorId'], {
        is: () => 'distributor' && !!selectedDistributor.length,
        then: yup.string().nullable().required("Please select a distributor's rm name - code"),
      }),
    rm_id: yup
      .string()
      .nullable()
      .test('rm_id', (value, context) => {
        const { createError, path, parent } = context;
        if (isSupportRM && !value && parent.loginType === 'rm') {
          return createError({
            message: "AMC's RM is Required",
            path,
          });
        }
        return true;
      }),

    //selectedDistributor.length
  });

export const userManagementRmSchema = yup.object().shape({
  name: yup.string().nullable().required('RM Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number')
    .required('Mobile Number is required'),
  roleType: yup.string().nullable().required('Role Type is required'),

  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
});

export const DistributorSchema = yup.object().shape({
  name: yup.string().nullable().required('Distributor Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().required('Email is required'),
    }),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number')
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().nullable().required('Mobile Number is required'),
    }),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value && parent.type=== 'individual') {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value&&parent.type=== 'individual') {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  arnCode: yup.string().nullable(),
  // .matches(arnCodeRegex, 'Invalid ARN Code Ex:- ARN-12345')
  // .required('ARN Code is required'),
  rmId: yup
    .string()
    .nullable()
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().nullable().required('RM Name is required'),
    }),
  schemeAndPlan: yup.array().of(
    yup.object().shape({
      planIds: yup
        .array()
        .nullable()
        .when('schemeIds', {
          is: (schemeIds: number | string) => schemeIds !== null,
          then: yup
            .array()
            .nullable()
            .min(1, 'Please choose applicable Plans')
            .required('Please choose applicable Plans'),
        }),
    })
  ),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const TrusteeAndFundManagerSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile number')
    .max(16, 'Invalid Mobile number')
    .matches(phoneRegExp, 'Invalid Mobile number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  dateOfBirth: yup
    .string()
    .nullable()
    .test(
      'dateOfBirth',
      'Age should be greater than 18',
      (dateOfBirth) => !isMinor(dateOfBirth || '')
    ),
  leegalityName: yup.string().nullable().required('Leegality Name is required'),
  // .required('Date of Birth is required'),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const aifApproverSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});
export const amcApproverSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
});

export const addFundManagerSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid mobile number')
    .max(16, 'Invalid mobile number')
    .matches(phoneRegExp, 'Invalid mobile number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  leegalityName: yup.string().nullable().required('Leegality Name is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const AuthorisedSignatoryAndPOASchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile number')
    .max(16, 'Invalid Mobile number')
    .matches(phoneRegExp, 'Invalid Mobile number')
    .required('Mobile Number is required'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // distributorId: yup.string().required('Please Enter Distridutor Id'),
  // amcId: yup.string().required('Please Enter AmcId'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const DistributorRmSchema = yup.object().shape({
  name: yup.string().nullable().required("Distributor's RM/CSE name is required"),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number')
    .required('Mobile Number is required'),
  subdistributorType: yup
    .string()
    .nullable()
    .oneOf(Object.keys(subdistributorTypes), 'Invalid value for Subdistributor Type')
    .required('Please Select Subdistributor Type'),
  // supportSubdistributorId: yup
  //   .string()
  //   .nullable()
  //   .when('subdistributorType', {
  //     is: (subdistributorType: string) =>
  //       subdistributorTypes[subdistributorType] === subdistributorTypes.direct,
  //     then: yup.string().nullable().required('Support Subdistibutor Name(s) is required'),
  //   }),
  directSubdistributorIds: yup
    .array()
    .nullable()
    .when('subdistributorType', {
      is: (subdistributorType: string) =>
        subdistributorTypes[subdistributorType] === subdistributorTypes.support,
      then: yup
        .array()
        .nullable()
        .min(1, 'Distributor RM Name required')
        .required('Please Select Distributor RM Name'),
    }),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  //distributors_RM_Code: yup.string().required('Please Enter Distributor RM code'),
  rmId: yup.string().nullable().required("AMC's RM name is required"),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

// name,
// email,
// countryCode,
// phone,
// panNumber,
// distributors_RM_Code,
// rmId,
// distributorId,
// pincode,
// buildingNo,
// streetName,
// city,
// state,
// country,

// pan: yup
// .string()
// .nullable()
// .uppercase()
// .length(10, 'PAN must be exactly 10 characters')
// .matches(individualPanRegex, 'Only individual PANs are allowed')
// .test('pan', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesPanNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'PAN is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
//     return createError({
//       message:
//         'There is already same pan number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),

// mobile: yup
// .string()
// .nullable()
// .min(8, 'Invalid Mobile Number')
// .max(16, 'Invalid Mobile Number')
// .matches(phoneRegExp, 'Invalid Mobile Number')
// .test('mobile', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesMobileNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Mobile Number is required',
//       path,
//     });
//   }
//   if (
//     authorisedSignatoriesMobileNumbers &&
//     authorisedSignatoriesMobileNumbers.includes(value)
//   ) {
//     return createError({
//       message:
//         'There is already same mobile number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
// email: yup
// .string()
// .nullable()
// .matches(emailRegex, 'Invalid Email ID')
// .test('email', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesEmailID = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.email))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Email ID is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesEmailID && authorisedSignatoriesEmailID.includes(value)) {
//     return createError({
//       message:
//         'There is already same Email ID for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
export const amcAdminSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
});

export const NonIndividualContributorValidationSchema = (
  brokers: Broker[],
  createdAt: string,
  toggler: togglerType
) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .nullable()
          .test('name', 'Name Of Entity is required', (value, context) => {
            const { options } = context;
            return shouldValidateUponSaveLater(options?.context?.saveType) ? !!value : true;
          }),
        cityOfIncorporation: yup
          .string()
          .nullable()
          .test(
            'cityOfIncorporation',
            'Place of Registration/Incorporation is required',
            (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options?.context?.saveType) ? !!value : true;
            }
          ),
        dateOfBirth: yup
          .string()
          .nullable()
          .test(
            'dateOfBirth',
            'Date of Registration/Incorporation is required',
            (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options?.context?.saveType) ? !!value : true;
            }
          ),
        dlclId: yup
          .string()
          .nullable()
          .oneOf(
            Object.keys(DLCLMasters),
            'Invalid value for Demat Account Details (for allotment of units)'
          )
          .test(
            'dlclId',
            'Demat Account Details (for allotment of units) is required',
            (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options?.context?.saveType) ? !!value : true;
            }
          ),
        dpId: yup
          .string()
          .nullable()
          .when('dlclId', {
            is: (dlclId: string) => getdpIdField(dlclId || ''),
            then: yup
              .string()
              .nullable()
              .test('dpId', (value, context) => {
                const { path, createError, parent, options } = context;
                if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                  return createError({
                    message: `${isCDSL(parent.dlclId) ? 'DPCL ID' : 'DP ID'} is required`,
                    path,
                  });
                }
                if (
                  checkValidationBasedOnDate(
                    createdAt,
                    toggler?.dateFeatureToggles?.dematFieldValidationDate
                  )
                ) {
                  if (value && !isCDSL(parent.dlclId) && !dpIdRegex.test(value)) {
                    return createError({
                      message: `DP ID should consist of 8 digits and must start with 'IN'`,
                      path,
                    });
                  }

                  if (value && isCDSL(parent.dlclId)) {
                    if (
                      !dpIdNumberRegex.test(value) &&
                      checkValidationBasedOnDate(
                        createdAt,
                        toggler?.dateFeatureToggles?.dpIdFieldValidationDate
                      )
                    ) {
                      return createError({
                        message: 'DPCL ID should consist of 16 numeric digits',
                        path,
                      });
                    }
                    if (
                      !checkValidationBasedOnDate(
                        createdAt,
                        toggler?.dateFeatureToggles?.dpIdFieldValidationDate
                      ) &&
                      !(cdslRegex.test(value) || dpIdNumberRegex.test(value))
                    ) {
                      return createError({
                        message: 'DPCL ID should consist of 8 or 16 numeric digits',
                        path,
                      });
                    }
                    return true;
                  }
                  return true;
                }
                return true;
              }),
          }),
        clId: yup
          .string()
          .nullable()
          .when('dlclId', {
            is: (dlclId: string) => getclIdField(dlclId || ''),
            then: yup
              .string()
              .nullable()
              .test('clId', (value, context) => {
                const { path, createError, options } = context;
                if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                  return createError({
                    message: 'CL ID is required',
                    path,
                  });
                }
                if (
                  checkValidationBasedOnDate(
                    createdAt,
                    toggler?.dateFeatureToggles?.dematFieldValidationDate
                  )
                ) {
                  if (value && !cdslRegex.test(value)) {
                    return createError({
                      message: 'CL ID should consist of 8 numeric digits',
                      path,
                    });
                  }
                }
                return true;
              }),
          }),
        nameOfBroker: yup
          .string()
          .nullable()
          .when('dlclId', {
            is: (dlclId: string) => getdpIdField(dlclId || ''),
            then: yup
              .string()
              .nullable()
              .test('nameOfBroker', (value, context) => {
                const { path, createError } = context;
                const brokerList = brokers.map((broker) => broker.key);
                if (!value) {
                  return true;
                }
                if (value && !brokerList.includes(value)) {
                  return createError({
                    message: 'Invalid value for Name of Broker/Depository Participant (DP)',
                    path,
                  });
                }
                return true;
              }),
          }),
        otherNameOfBroker: yup
          .string()
          .nullable()
          .when(['dlclId', 'nameOfBroker'], {
            is: (dlclId: string, nameOfBroker: string) =>
              getdpIdField(dlclId || '') && otherNameOfBroker(nameOfBroker),
            then: yup.string().nullable().required('Other Name of Broker is required'),
          }),
        ckycNo: yup
          .string()
          .nullable()
          .test('ckycNo', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const {
              options: { context: optionsContext },
              createError,
              path,
            } = context;
            const { applicants } = optionsContext || {};
            if (!fieldValue) {
              return true;
            } else if (fieldValue.length !== 14) {
              return createError({
                message: 'Invalid CKYC No./CKYC Acknowledge No./KIN No',
                path,
              });
            } else if (!numberRegex.test(fieldValue)) {
              return createError({
                message: 'CKYC No./CKYC Acknowledge No./KIN No allows only digits',
                path,
              });
            } else if (
              applicants.filter((applicant: Applicant) => applicant.ckycNo === fieldValue).length >
              1
            ) {
              return createError({
                message:
                  'There is already same CKYC Number for an applicant associated with this application',
                path,
              });
            }
            return true;
          }),
        panNo: yup
          .string()
          .nullable()
          .uppercase()
          .length(10, 'PAN must be exactly 10 characters')
          .matches(nonIndividualPanRegex, 'Invalid PAN')
          .test('panNo', 'PAN is required', (value, context) => {
            const { options } = context;
            return shouldValidateUponSaveLater(options?.context?.saveType) ? !!value : true;
          }),
        investorType: yup
          .string()
          .nullable()
          .oneOf(Object.keys(investorTypeMasters), 'Invalid value for Investor Type')
          .test('investorType', 'Please Select Investor Type', (value, context) => {
            const { options } = context;
            return shouldValidateUponSaveLater(options?.context?.saveType) ? !!value : true;
          }),
        netWorth: yup
          .string()
          .nullable()
          .test('netWorth', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, options } = context;
            if (!fieldValue && shouldValidateUponSaveLater(options?.context?.saveType)) {
              return createError({
                message: 'Net Worth is required',
                path,
              });
            }
            if (fieldValue && !amountRegex.test(fieldValue)) {
              return createError({
                message: 'Invalid amount',
                path,
              });
            }
            return true;
          }),
        grossAnnualIncome: yup
          .string()
          .nullable()
          .oneOf(grossAnnualMasters, 'Invalid value for Gross Annual Income')
          .test('grossAnnualIncome', 'Gross Annual Income is required', (value, context) => {
            const { options } = context;
            return shouldValidateUponSaveLater(options?.context?.saveType) ? !!value : true;
          }),
      })
    ),
  });

const validateNonIndividualPincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  addressType: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext } = options as InternalOptions<NonIndividualContactValues> & {
    index: number;
  };
  if (
    !fieldValue &&
    (addressType === 'permanent' || addressType === 'correspondence') &&
    shouldValidateUponSaveLater(optionsContext?.saveType || '') &&
    ['india', 'indian'].includes(parent?.country?.toLowerCase())
  ) {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (fieldValue && parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode code should be 6 digit code',
      path,
    });
  }
  if (
    fieldValue &&
    parent.country?.toLowerCase() !== 'india' &&
    !pinCodeRegexOtherThanIndia.test(fieldValue)
  ) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }
  return true;
};

export const nonIndividualContactDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      contactperson: yup.object().shape({
        address1: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
          ),
        // .required('Address line 1 is required'),
        address2: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
          ),
        // .required('Address line 2 is required'),

        landLineNumber: yup
          .string()
          .nullable()
          .test('landLineNumber', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent, options } = context;
            if (
              !fieldValue &&
              parent.stdCode &&
              shouldValidateUponSaveLater(options.context?.saveType)
            ) {
              return createError({
                message: 'Please enter Landline number',
                path,
              });
            } else if (parent.landLineNumber && !numberRegex.test(parent.landLineNumber)) {
              return createError({
                message: 'Please enter valid Landline Number',
                path,
              });
            }
            return true;
          }),
        stdCode: yup
          .string()
          .nullable()
          .test('stdCode', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent, options } = context;
            if (
              !fieldValue &&
              parent.landLineNumber &&
              shouldValidateUponSaveLater(options.context?.saveType)
            ) {
              return createError({
                message: 'Please enter STD Code',
                path,
              });
            } else if (parent.stdCode && !numberRegex.test(parent.stdCode)) {
              return createError({
                message: 'Please enter valid STD Code',
                path,
              });
            }
            return true;
          }),

        city: yup.string().nullable(),
        // .required('City is required'),
        state: yup.string().nullable(),
        // .required('State is required'),
        country: yup
          .string()
          .nullable()
          .test('country', (value, context) => {
            const { options, createError, path } = context;
            const { context: optionsContext } =
              options as InternalOptions<NonIndividualContactValues>;
            const { countryDropdown = [] } = optionsContext || {};
            // if (!value) {
            //   return createError({
            //     message: 'Country is required',
            //     path,
            //   });
            // }
            if (value && !countryDropdown.includes(value)) {
              return createError({
                message: 'Invalid value for Country',
                path,
              });
            }
            return true;
          }),
        pincode: yup
          .string()
          .nullable()
          .test('pincode', (value, context) =>
            validateNonIndividualPincodeField(value, context, 'contactPerson')
          ),
        name: yup
          .string()
          .nullable()
          .test('name', 'Name is required', (value, context) => {
            const { options } = context;
            return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
          }),
        email: yup
          .string()
          .nullable()
          .matches(emailRegex, 'Invalid Email ID')
          .test('email', 'Email is required', (value, context) => {
            const { options } = context;
            return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
          }),
        mobile: yup
          .string()
          .nullable()
          .test('mobile', (value, context) => {
            const { createError, path, parent, options } = context;
            const codesList = getCountryCodes()
              .map((codes) => {
                if (codes.label === parent.countryNameAndCode) {
                  return codes.countryCode;
                }
              })
              .filter((ele) => ele)
              .toString();
            if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
              return createError({
                message: 'Mobile is required',
                path,
              });
            }
            if (value && parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }
            if (value && !isValidPhoneNumber(value, codesList as CountryCode)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }

            return true;
          }),
        countryNameAndCode: yup
          .string()
          .nullable()
          .test('countryNameAndCode', (value, context) => {
            const { createError, path, parent, options } = context;
            const codesList = getCountryCodes()
              .map((codes) => {
                if (codes.label === value) {
                  return codes.countryCode;
                }
              })
              .filter((ele) => ele)
              .toString();
            if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
              return createError({
                message: 'Country Code is required',
                path,
              });
            }
            if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }

            return true;
          }),
      }),
      address: yup.object().shape({
        correspondence: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
            }),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              // const { context: optionsContext, index } = options as InternalOptions<Values> & {
              //   index: number;
              // };
              // const { applicants = [] } = optionsContext || {};
              // const currentApplicant = applicants[index] || {};
              // const { fetchedFromKRA = null } = currentApplicant.address.correspondence || {};
              if (
                // !fetchedFromKRA &&
                !value &&
                shouldValidateUponSaveLater(options.context?.saveType)
              ) {
                return false;
              }
              return true;
            }),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) =>
              validateNonIndividualPincodeField(value, context, 'correspondence')
            ),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
            }),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType) ? !!value : true;
            }),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext } =
                options as InternalOptions<NonIndividualContactValues>;
              const { countryDropdown = [] } = optionsContext || {};
              if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (value && !countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
        }),
        permanent: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType)
                ? validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
                : true;
            }),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            // .test('address2', 'Address2 is required', (value, context) =>
            //   validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            // ),
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.permanent || {};
              if (!fetchedFromKRA && shouldValidateUponSaveLater(options.context?.saveType)) {
                return validatePermanentAddressFields(
                  value,
                  context,
                  APPLICATION_TYPE.NON_INDIVIDUAL
                );
              }
              return true;
            }),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                return validateNonIndividualPincodeField(value, context, 'permanent');
              }
              return true;
            }),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType)
                ? validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
                : true;
            }),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) => {
              const { options } = context;
              return shouldValidateUponSaveLater(options.context?.saveType)
                ? validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
                : true;
            }),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } =
                options as InternalOptions<NonIndividualContactValues> & {
                  index: number;
                };
              const { applicants = [], countryDropdown = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                if (!value && shouldValidateUponSaveLater(options.context?.saveType)) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                if (value && !countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
              }
              return true;
            }),
          // .test('country', 'Country is required', (value, context) =>
          //   validatePermanentAddressFields(value, context)
          // ),
        }),
      }),
    })
  ),
});

export const nonIndividualDocumentDetailsSchema = yup.object().shape({
  applicants: yup.array().when('isTopup', {
    is: (value: boolean) => value === false,
    then: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, NonIndividualDocValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<NonIndividualDocValues> & { index: number };
                      const { applicants = [] } = optionsContext || ({} as NonIndividualDocValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, options: optionsDropdown } =
                        applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (
                        optionsDropdown &&
                        shouldValidateUponSaveLater(optionsContext?.saveType || '')
                      ) {
                        if (!fieldValue && required === 'true') {
                          return createError({
                            message: `Please select valid value`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, NonIndividualDocValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<NonIndividualDocValues> & { index: number };
                      const { applicants = [] } = optionsContext || ({} as NonIndividualDocValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName } =
                        applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (!value && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                        if (required === 'true') {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  }),
  topUpApplicants: yup.array().when('isTopup', {
    is: (value: boolean) => value === true,
    then: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, NonIndividualDocValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<NonIndividualDocValues> & { index: number };
                      const { topUpApplicants = [] } =
                        optionsContext || ({} as NonIndividualDocValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, options: optionsDropdown } =
                        topUpApplicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (
                        optionsDropdown &&
                        shouldValidateUponSaveLater(optionsContext?.saveType || '')
                      ) {
                        if (!fieldValue && required === 'true') {
                          return createError({
                            message: `Please select valid value`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, NonIndividualDocValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<NonIndividualDocValues> & { index: number };
                      const { topUpApplicants = [] } =
                        optionsContext || ({} as NonIndividualDocValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName } =
                        topUpApplicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (!value && shouldValidateUponSaveLater(optionsContext?.saveType || '')) {
                        if (required === 'true') {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  }),
});

export const nonIndividualAuthorisedSignatories = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  pan: yup
    .string()
    .nullable()
    .uppercase()
    .length(10, 'PAN must be exactly 10 characters')
    .matches(individualPanRegex, 'Only individual PANs are allowed')
    .test('pan', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesPanNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
        .flat();
      if (!value) {
        return createError({
          message: 'PAN is required',
          path,
        });
      }
      if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
        return createError({
          message:
            'There is already same pan for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  mobile: yup
    .string()
    .nullable()
    .test('mobile', (value, context) => {
      const { path, createError, options, parent } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesMobileNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
        .flat();
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (
        authorisedSignatoriesMobileNumbers &&
        authorisedSignatoriesMobileNumbers.includes(value)
      ) {
        return createError({
          message:
            'There is already same mobile number for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),

  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid Email ID')
    .test('email', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesEmailID = optionsContext
        ?.map((groups) =>
          groups.groupsignatories.map((signatory) => signatory.email?.toLowerCase())
        )
        .flat();
      if (!value) {
        return createError({
          message: 'Email ID is required',
          path,
        });
      }
      if (
        authorisedSignatoriesEmailID &&
        authorisedSignatoriesEmailID.includes(value?.toLowerCase())
      ) {
        return createError({
          message:
            'There is already same Email ID for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  designation: yup.string().nullable().required('Designation is required'),
});

const fieldValidation = (
  value: string | undefined | null | number,
  context: yup.TestContext<Record<string, ubo>>,
  mes: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { path, createError, parent } = context;
  if (!fieldValue && !parent.isFetchData) {
    return createError({
      message: `${mes} is required`,
      path,
    });
  }
  return true;
};

export const nonIndividualUbo = yup.object().shape({
  panNumber: yup
    .string()
    .nullable()
    .uppercase()
    .test('panNumber', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ubo[]>;

      const uboPanNumbers = optionsContext?.map((ubo) => ubo.panNumber);
      if (!value) {
        return createError({
          message: 'Taxpayer ID Number/PAN/Equivalent ID Number is required',
          path,
        });
      }
      if (uboPanNumbers && uboPanNumbers.includes(value)) {
        return createError({
          message:
            'There is already same Taxpayer ID Number/PAN/Equivalent ID Number for an Ultimate Beneficiary Ownership (UBO) associated with this application',
          path,
        });
      }

      return true;
    }),

  dob: yup
    .string()
    .nullable()
    .test('dob', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, parent } = context;
      if (!fieldValue) {
        return createError({
          message: 'Date of birth is required',
          path,
        });
      }
      if (
        parent.panNumber &&
        individualPanRegex.test(parent.panNumber) &&
        isMinor(fieldValue || '')
      ) {
        return createError({
          message: 'Age should be greater than 18',
          path,
        });
      }
      if (
        parent.panNumber &&
        individualPanRegex.test(parent.panNumber) &&
        maxAge(fieldValue || '')
      ) {
        return createError({
          message: 'Age should be less than 125',
          path,
        });
      }
      return true;
    }),
  name: yup
    .string()
    .nullable()
    .test('name', (value, context) => {
      return fieldValidation(value, context, 'Name');
    }),
  identificationType: yup
    .string()
    .nullable()
    .test('identificationType', (value, context) => {
      return fieldValidation(value, context, 'Identification Type');
    }),
  typeOfUBO: yup
    .string()
    .nullable()
    .oneOf(Object.keys(UboTypeMaster), 'Invalid value for UBO Type'),
  percentageOfBeneficialInterest: yup
    .number()
    .nullable()
    .test('percentageOfBeneficialInterest', (value, context) => {
      const fieldValue = value;
      const { path, createError, parent } = context;
      if (fieldValue === null || (fieldValue === undefined && !parent.isFetchData)) {
        return createError({
          message: `Percentage Of Beneficial Interest is required`,
          path,
        });
      }
      if (fieldValue === 0 && !parent.isFetchData && !checkUBOTypeIsTrust(parent.typeOfUBO)) {
        return createError({
          message: `Percentage Of Beneficial Interest should be greater 0`,
          path,
        });
      }
      if (fieldValue) {
        if (fieldValue < 0 && !parent.isFetchData) {
          return createError({
            message: `Percentage Of Beneficial Interest should be greater than${
              checkUBOTypeIsTrust(parent.typeOfUBO) ? ' or equal to' : ''
            } 0`,
            path,
          });
        }
        if (fieldValue > 100 && !parent.isFetchData) {
          return createError({
            message: `Percentage Of Beneficial Interest must not exceed 100%`,
            path,
          });
        }
      }
      return true;
    }),
  countryOfTaxResidency: yup
    .string()
    .nullable()
    .test('countryOfTaxResidency', (value, context) => {
      return fieldValidation(value, context, 'Country Of Tax Residency');
    }),
  cpUboCode: yup
    .string()
    .nullable()
    .oneOf(Object.keys(CpUboCodesMaster), 'Invalid value for CP/UBO Code')
    .test('cpUboCode', (value, context) => {
      return fieldValidation(value, context, 'Cp/UboCode');
    }),
  placeAndCountryOfBirth: yup
    .string()
    .nullable()
    .test('placeAndCountryOfBirth', (value, context) => {
      return fieldValidation(value, context, 'Place And Country Of Birth');
    }),
  occupation: yup
    .string()
    .nullable()
    .oneOf(occupationDetailsMasters, 'Invalid value for Occupation')
    .test('occupation', (value, context) => {
      return fieldValidation(value, context, 'Occupation');
    }),
  gender: yup
    .string()
    .nullable()
    .test('gender', (value, context) => {
      return fieldValidation(value, context, 'Gender');
    }),
  nationality: yup
    .string()
    .nullable()
    .test('nationality', (value, context) => {
      return fieldValidation(value, context, 'Nationality');
    }),
  fatherName: yup
    .string()
    .nullable()
    .test('fatherName', (value, context) => {
      return fieldValidation(value, context, 'FatherName');
    }),
  ckycNumber: yup
    .string()
    .nullable()
    .length(14, 'Invalid CKYC Number')
    .test('ckycNumber', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, options, parent } = context;
      const { context: optionsContext } = options as InternalOptions<ubo[]>;
      if (!fieldValue && !parent.isFetchData) {
        return true;
      } else if (!numberRegex.test(fieldValue as string) && !parent.isFetchData) {
        return createError({
          message: 'CKYC Number allows only digits',
          path,
        });
      }
      const uboCkycNumbers = optionsContext?.map((ubo) => Number(ubo.ckycNumber).toString());
      if (uboCkycNumbers && uboCkycNumbers.includes(fieldValue as string) && !parent.isFetchData) {
        return createError({
          message:
            'There is already same CKYC Number for an Ultimate Beneficiary Ownership (UBO) associated with this application',
          path,
        });
      }
      return true;
    }),
  address2: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
    )
    .test('address2', (value, context) => {
      return fieldValidation(value, context, 'Address Line 1');
    }),
  address3: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
    )
    .test('address3', (value, context) => {
      return fieldValidation(value, context, 'Address Line 2');
    }),
  city: yup
    .string()
    .nullable()
    .test('city', (value, context) => {
      return fieldValidation(value, context, 'City');
    }),
  state: yup
    .string()
    .nullable()
    .test('state', (value, context) => {
      return fieldValidation(value, context, 'State');
    }),
  country: yup
    .string()
    .nullable()
    .test('country', (value, context) => {
      return fieldValidation(value, context, 'Country');
    }),
  pincode: yup
    .string()
    .nullable()
    .test('pincode', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, parent } = context;
      if (
        !fieldValue &&
        ['india', 'indian'].includes(parent.country?.toLowerCase() || '') &&
        !parent.isFetchData
      ) {
        return createError({
          message: 'Pincode is required',
          path,
        });
      } else if (
        ['india', 'indian'].includes(parent.country?.toLowerCase() || '') &&
        !indianPin.test(fieldValue as string) &&
        !parent.isFetchData
      ) {
        return createError({
          message: 'Pincode length should be 6 digits',
          path,
        });
      } else if (
        !['india', 'indian'].includes(parent.country?.toLowerCase() || '') &&
        !pinCodeRegexOtherThanIndia.test(fieldValue as string) &&
        !parent.isFetchData
      ) {
        return createError({
          message: 'Invalid Pincode',
          path,
        });
      }
      return true;
    }),
});

export const nonIndividualControllingPersons = yup.object().shape({
  name: yup.string().nullable().required('Name of controlling person is required'),
  correspondenceAddress: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid address format [special characters are not allowed except -/',&()#:.]"
    )
    .required('Correspondence address is required'),
  countryOfResidense: yup
    .string()
    .nullable()
    .required('Country of residence for tax purposes is required'),
  issuingCountry: yup.string().nullable().required('Issuing country is required'),
  controllingPersonType: yup.string().nullable().required('Controlling Person Type is required'),
  indentificationType: yup.string().nullable().required('Indentification Type is required'),
  indentificationNumber: yup
    .string()
    .nullable()
    .test('indentificationNumber', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
      const indentificationNumberArr = optionsContext?.map(
        (person) => person.indentificationNumber
      );
      if (!value) {
        return createError({
          message: 'Indentification Number is required',
          path,
        });
      }
      if (value && !alphaNumeric.test(value)) {
        return createError({
          message: 'Special Characters are Not allowed',
          path,
        });
      }
      if (indentificationNumberArr && indentificationNumberArr.includes(value)) {
        return createError({
          message:
            'There is already same Indentification Number for a Controlling Persons associated with this application',
          path,
        });
      }

      return true;
    }),
  occupationType: yup.string().nullable().required('Occupation Type is required'),
  occupation: yup
    .string()
    .nullable()
    .oneOf(occupationDetailsMasters, 'Invalid value for Occupation')
    .required('Occupation is required'),
  dateOfBirth: yup
    .string()
    .nullable()
    .test('dateOfBirth', (value, context) => {
      const { path, createError } = context;
      if (!value) {
        return createError({
          message: 'Date of Birth is required',
          path,
        });
      }
      if (isMinor(value)) {
        return createError({
          message: 'Age should be greater than 18',
          path,
        });
      }
      if (maxAge(value)) {
        return createError({
          message: 'Age should be less than 125',
          path,
        });
      }
      return true;
    }),
  countryOfBirth: yup.string().nullable().required('Country Of Birth is required'),
  nationality: yup.string().nullable().required('Nationality is required'),
  tin: yup
    .string()
    .nullable()
    .test('tin', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
      const controllingPersons = optionsContext?.map((person) => person.tin);
      if (!value) {
        return createError({
          message: 'TIN is required',
          path,
        });
      }
      if (value && !alphaNumeric.test(value)) {
        return createError({
          message: 'Special Characters are Not allowed',
          path,
        });
      }
      if (controllingPersons && controllingPersons.includes(value)) {
        return createError({
          message:
            'There is already same Tin for a Controlling Persons associated with this application',
          path,
        });
      }

      return true;
    }),
});

type errorObj = { fieldPath: string; fieldName: string };

const subQuestionsValidation = (
  fatca: nonIndividualQuestionsFatca,
  path: string
): errorObj | errorObj[] | undefined => {
  if (
    !fatca.answer &&
    fatca.isVisible &&
    fatca.backend_key &&
    fatca.isMandatory === 'true' &&
    fatca.question_type !== 'Controlling_person_details'
  ) {
    const fieldPath =
      path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
    return {
      fieldPath: fieldPath,
      fieldName:
        fatca.question_type === 'single_choice_radio'
          ? 'Field is required'
          : fatca.question_text
          ? fatca.question_text + ' is required'
          : (fatca.placeholder || 'Field') + ' is required',
    };
  }
  if (
    fatca.answer &&
    fatca.isVisible &&
    fatca.backend_key &&
    fatca.validation === 'alphaNumeric' &&
    !alphaNumeric.test(fatca.answer)
  ) {
    const fieldPath =
      path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
    return {
      fieldPath: fieldPath,
      fieldName: 'Special Characters are Not allowed',
    };
  }
  if (fatca.question_type === 'group' && fatca.isVisible && fatca.sub_questions?.length) {
    return fatca.sub_questions
      ?.map((qun) => subQuestionsValidation(qun, path) as errorObj)
      .filter((ele: errorObj) => ele)
      .flat();
  }
};

export const nonIndividualFatcaSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      fatcadetail: yup.array().of(
        yup.object().shape({
          answer: yup
            .string()
            .nullable()
            .test('answer', (value, context) => {
              const { path, createError, parent, options } = context;
              if (shouldValidateUponSaveLater(options.context?.saveType)) {
                if (
                  !value &&
                  parent.isVisible &&
                  parent.isMandatory === 'true' &&
                  parent.backend_key &&
                  parent.question_type !== 'single_choice_radio'
                ) {
                  return createError({
                    message:
                      parent.question_type === 'open_text_field'
                        ? (parent.placeholder || 'Field') + ' is required'
                        : parent.question_text
                        ? parent.question_text + ' is required'
                        : (parent.placeholder || 'Field') + ' is required',
                    path,
                  });
                }
                if (
                  value &&
                  parent.isVisible &&
                  parent.backend_key &&
                  parent.validation === 'alphaNumeric' &&
                  !alphaNumeric.test(value)
                ) {
                  return createError({
                    message: 'Special Characters are Not allowed',
                    path,
                  });
                }
                if (parent.question_type === 'group' && parent.isVisible && parent.sub_questions) {
                  const val = parent.sub_questions
                    ?.map((qun: nonIndividualQuestionsFatca) => {
                      const r = subQuestionsValidation(qun, path?.split('.')[0]) as errorObj;
                      return r;
                    })
                    .filter((ele: errorObj) => ele)
                    .flat();
                  if (val.length) {
                    const error = val.map((ele: errorObj) => {
                      return new yup.ValidationError(ele.fieldName, '', `${ele.fieldPath}.answer`);
                    });
                    if (error.length) {
                      return new yup.ValidationError(error);
                    }
                  }
                  return true;
                }
              }
              return true;
            }),
        })
      ),
    })
  ),
});

const topUpPlanValidation = yup.object().shape({
  topUpMinCommitmentAmount: yup
    .number()
    .nullable()
    .when('isTopupApplicable', {
      is: (isTopupApplicable: boolean) => isTopupApplicable,
      then: yup
        .number()
        .nullable()
        .moreThan(0, `Top Up Minimum Commitment Amount should be greater than 0`)
        .required('Top Up Minimum Commitment Amount is required'),
    }),

  topUpMaxCommitmentAmount: yup
    .number()
    .nullable()
    .when('isTopupApplicable', {
      is: (isTopupApplicable: boolean) => isTopupApplicable,
      then: yup
        .number()
        .nullable()
        .min(
          yup.ref('topUpMinCommitmentAmount'),
          'Maximum Top Up Commitment Amount should be greater than or equal to Minimum Top Up Commitment Amount'
        )
        .required('Maximum Top Up Commitment Amount is required'),
    }),
  topUpSetupFeeApplicable: yup
    .string()
    .nullable()
    .when('isTopupApplicable', {
      is: (isTopupApplicable: boolean) => isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Setup Fee Applicable is required')
        .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Top Up Setup Fee Applicable'),
    }),
  topUpSetupFeeType: yup
    .string()
    .nullable()
    .when(['topUpSetupFeeApplicable', 'isTopupApplicable'], {
      is: (topUpSetupFeeApplicable: string, isTopupApplicable: boolean) =>
        isFieldApplicable(topUpSetupFeeApplicable) && isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Setup Fee Type is required')
        .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Top Up Setup Fee'),
    }),
  topUpSetupFee: yup
    .number()
    .nullable()
    .test('topUpSetupFee', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (!value && value !== 0 && isFieldApplicable(parent?.topUpSetupFeeApplicable)) {
          return createError({
            message: 'Top Up Setup Fee is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.topUpSetupFeeType, parent.topUpSetupFeeApplicable) &&
          (value < Number(parent?.topUpMinSetupFee || 0) ||
            value > Number(parent?.topUpMaxSetupFee || 100))
        ) {
          return createError({
            message: `Top Up Setup Fee should be in between or equal to ${
              parent?.topUpMinSetupFee || 0
            } and ${parent?.topUpMaxSetupFee || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.topUpSetupFeeType, parent.topUpSetupFeeApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Top Up Setup Fee should be in between or equal to 0 and 100',
            path,
          });
        }
      }
      return true;
    }),
  topUpMinSetupFee: yup
    .number()
    .nullable()
    .test('minSetupFee', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (isFieldVariable(parent?.topUpSetupFeeType, parent.topUpSetupFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Top Up Setup Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Top Up Setup Fee should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.topUpMaxSetupFee || parent?.topUpMaxSetupFee === 0
              ? Number(parent?.topUpMaxSetupFee)
              : 100)
          ) {
            return createError({
              message:
                'Minimum Top Up Setup Fee should be less than or equal to Maximum Top Up Setup Fee',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpMaxSetupFee: yup
    .number()
    .nullable()
    .test('topUpMaxSetupFee', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (isFieldVariable(parent?.topUpSetupFeeType, parent.topUpSetupFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Top Up Setup Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Top Up Setup Fee should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.topUpMinSetupFee || parent?.topUpMinSetupFee === 0
              ? Number(parent?.topUpMinSetupFee)
              : 0)
          ) {
            return createError({
              message:
                'Maximum Top Up Setup Fee should be greater than or equal to Minimum Top Up Setup Fee',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpAccreditedInvestorApplicable: yup
    .string()
    .nullable()
    .when('isTopupApplicable', {
      is: (isTopupApplicable: boolean) => isTopupApplicable,
      then: yup.string().nullable().required('Top Up Accredited Investor Applicable is required'),
    }),
  topUpAccreditedMinCommitmentAmount: yup
    .number()
    .nullable()
    .when(['topUpAccreditedInvestorApplicable', 'isTopupApplicable'], {
      is: (topUpAccreditedInvestorApplicable: string, isTopupApplicable: boolean) =>
        isAccreditedInvestorApplicable(topUpAccreditedInvestorApplicable) && isTopupApplicable,
      then: yup
        .number()
        .nullable()
        .moreThan(0, `Top Up Accredited minimum commitment amount should be greater than 0`)
        .required('Top Up Accredited Minimum Commitment Amount is required'),
    }),
  topUpManagementFeeApplicable: yup
    .string()
    .nullable()
    .when('isTopupApplicable', {
      is: (isTopupApplicable: boolean) => isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Management Fee Applicable is required')
        .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Top Up Management Fee Applicable'),
    }),
  topUpManagementFeeType: yup
    .string()
    .nullable()
    .when(['topUpManagementFeeApplicable', 'isTopupApplicable'], {
      is: (topUpManagementFeeApplicable: string, isTopupApplicable: boolean) =>
        isFieldApplicable(topUpManagementFeeApplicable) && isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Management Fee Type is required')
        .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Top Up Management Fee Type'),
    }),
  defaultTopUpManagementFee: yup
    .number()
    .nullable()
    .test('defaultTopUpManagementFee', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (!value && value !== 0 && isFieldApplicable(parent?.topUpManagementFeeApplicable)) {
          return createError({
            message: 'Top Up Management Fee is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.topUpManagementFeeType, parent.topUpManagementFeeApplicable) &&
          (value < Number(parent?.topUpMinManagementFee || 0) ||
            value > Number(parent?.topUpMaxManagementFee || 100))
        ) {
          return createError({
            message: `Top Up Management Fee should be in between or equal to ${
              parent?.topUpMinManagementFee || 0
            } and ${parent?.topUpMaxManagementFee || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.topUpManagementFeeType, parent.topUpManagementFeeApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Top Up Management Fee should be in between or equal to 0 and 100',
            path,
          });
        }
      }
      return true;
    }),
  topUpMinManagementFee: yup
    .number()
    .nullable()
    .test('minManagementFee', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (isFieldVariable(parent?.topUpManagementFeeType, parent.topUpManagementFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Top Up Management Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Top Up Management Fee should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.topUpMaxManagementFee || parent?.topUpMaxManagementFee === 0
              ? Number(parent?.topUpMaxManagementFee)
              : 100)
          ) {
            return createError({
              message:
                'Minimum Top Up Management Fee should be less than or equal to Maximum Top Up Management Fee',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpMaxManagementFee: yup
    .number()
    .nullable()
    .test('topUpMaxManagementFee', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (isFieldVariable(parent?.topUpManagementFeeType, parent.topUpManagementFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Top Up Management Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Top Up Management Fee should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.topUpMinManagementFee || parent?.topUpMinManagementFee === 0
              ? Number(parent?.topUpMinManagementFee)
              : 0)
          ) {
            return createError({
              message:
                'Maximum Top Up Management Fee should be greater than or equal to Minimum Top Up Management Fee',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpHurdleRateApplicable: yup
    .string()
    .nullable()
    .when('isTopupApplicable', {
      is: (isTopupApplicable: boolean) => isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Hurdle Rate Applicable is required')
        .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Top Up Hurdle Rate Applicable'),
    }),
  topUpHurdleRateType: yup
    .string()
    .nullable()
    .when(['topUpHurdleRateApplicable', 'isTopupApplicable'], {
      is: (topUpHurdleRateApplicable: string, isTopupApplicable: boolean) =>
        isFieldApplicable(topUpHurdleRateApplicable) && isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Hurdle Rate Type is required')
        .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Top Up Hurdle Rate Type'),
    }),
  defaultTopUpHurdleRate: yup
    .number()
    .nullable()
    .test('defaultTopUpHurdleRate', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (!value && value !== 0 && isFieldApplicable(parent?.topUpHurdleRateApplicable)) {
          return createError({
            message: 'Top Up Hurdle Rate is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.topUpHurdleRateType, parent.topUphurdleRateApplicable) &&
          (value < Number(parent?.topUpMinHurdleRate || 0) ||
            value > Number(parent?.topUpMaxHurdleRate || 100))
        ) {
          return createError({
            message: `Top Up Hurdle Rate should be in between or equal to ${
              parent?.topUpMinHurdleRate || 0
            } and ${parent?.topUpMaxHurdleRate || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.topUpHurdleRateType, parent.topUphurdleRateApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Top Up Hurdle Rate should be in between or equal to 0 and 100',
            path,
          });
        }
      }
      return true;
    }),
  topUpMinHurdleRate: yup
    .number()
    .nullable()
    .test('minHurdleRate', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (isFieldVariable(parent?.topUpHurdleRateType, parent.topUpHurdleRateApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Top Up Hurdle Rate is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Top Up Hurdle Rate should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.topUpMaxHurdleRate || parent?.topUpMaxHurdleRate === 0
              ? Number(parent?.topUpMaxHurdleRate)
              : 100)
          ) {
            return createError({
              message:
                'Minimum Top Up Hurdle Rate should be less than or equal to Maximum Hurdle Rate',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpMaxHurdleRate: yup
    .number()
    .nullable()
    .test('topUpMaxHurdleRate', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (isFieldVariable(parent?.topUpHurdleRateType, parent.topUpHurdleRateApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Top Up Hurdle Rate is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Top Up Hurdle Rate should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.topUpMinHurdleRate || parent?.topUpMinHurdleRate === 0
              ? Number(parent?.topUpMinHurdleRate)
              : 0)
          ) {
            return createError({
              message:
                'Maximum Top Up Hurdle Rate should be greater than or equal to Minimum Top Up Hurdle Rate',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpPerformanceFeeApplicable: yup
    .string()
    .nullable()
    .when('isTopupApplicable', {
      is: (isTopupApplicable: boolean) => isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Performance Fee Applicable is required')
        .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Top Up Performance Fee Applicable'),
    }),
  topUpPerformanceFeeType: yup
    .string()
    .nullable()
    .when(['topUpPerformanceFeeApplicable', 'isTopupApplicable'], {
      is: (topUpPerformanceFeeApplicable: string, isTopupApplicable: boolean) =>
        isFieldApplicable(topUpPerformanceFeeApplicable) && isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Performance Fee Type is required')
        .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Top Up Performance Fee Type'),
    }),
  defaultTopUpPerformanceFee: yup
    .number()
    .nullable()
    .test('defaultTopUpPerformanceFee', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (!value && value !== 0 && isFieldApplicable(parent?.topUpPerformanceFeeApplicable)) {
          return createError({
            message: 'Top Up Performance Fee is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.topUpPerformanceFeeType, parent.topUpPerformanceFeeApplicable) &&
          (value < Number(parent?.topUpMinPerformanceFee || 0) ||
            value > Number(parent?.topUpMaxPerformanceFee || 100))
        ) {
          return createError({
            message: `Top Up Performance Fee should be in between or equal to ${
              parent?.topUpMinPerformanceFee || 0
            } and ${parent?.topUpMaxPerformanceFee || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.topUpPerformanceFeeType, parent.topUpPerformanceFeeApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Top Up Performance Fee should be in between or equal to 0 and 100',
            path,
          });
        }
      }
      return true;
    }),
  topUpMinPerformanceFee: yup
    .number()
    .nullable()
    .test('topUpMinPerformanceFee', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (
          isFieldVariable(parent?.topUpPerformanceFeeType, parent.topUpPerformanceFeeApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Top Up Performance Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Top Up Performance Fee should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.topUpMaxPerformanceFee || parent?.topUpMaxPerformanceFee === 0
              ? Number(parent?.topUpMaxPerformanceFee)
              : 100)
          ) {
            return createError({
              message:
                'Minimum Top Up Performance Fee should be less than or equal to Maximum Top Up Performance Fee',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpMaxPerformanceFee: yup
    .number()
    .nullable()
    .test('topUpMaxPerformanceFee', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (
          isFieldVariable(parent?.topUpPerformanceFeeType, parent.topUpPerformanceFeeApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Top Up Performance Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Top Up Performance Fee should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.topUpMinPerformanceFee || parent?.topUpMinPerformanceFee === 0
              ? Number(parent?.topUpMinPerformanceFee)
              : 0)
          ) {
            return createError({
              message:
                'Maximum Top Up Performance Fee should be greater than or equal to Minimum Top Up Performance Fee',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpExitChargeApplicable: yup
    .string()
    .nullable()
    .when('isTopupApplicable', {
      is: (isTopupApplicable: boolean) => isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Exit Charge Applicable is required')
        .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Top Up Exit Charge Applicable'),
    }),
  topUpExitChargeType: yup
    .string()
    .nullable()
    .when(['topUpExitChargeApplicable', 'isTopupApplicable'], {
      is: (topUpExitChargeApplicable: string, isTopupApplicable: boolean) =>
        isFieldApplicable(topUpExitChargeApplicable) && isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Exit Charge Type is required')
        .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Top Up Exit Charge Type'),
    }),
  defaultTopUpExitCharge: yup
    .number()
    .nullable()
    .test('defaultTopUpExitCharge', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (!value && value !== 0 && isFieldApplicable(parent?.topUpExitChargeApplicable)) {
          return createError({
            message: 'Top Up Exit Charge is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.topUpExitChargeType, parent.topUpExitChargeApplicable) &&
          (value < Number(parent?.topUpMinExitCharge || 0) ||
            value > Number(parent?.topUpMaxExitCharge || 100))
        ) {
          return createError({
            message: `Top Up Exit Charge should be in between or equal to ${
              parent?.topUpMinExitCharge || 0
            } and ${parent?.topUpMaxExitCharge || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.topUpExitChargeType, parent.topUpExitChargeApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Top Up Exit Charge should be in between or equal to 0 and 100',
            path,
          });
        }
      }
      return true;
    }),
  topUpMinExitCharge: yup
    .number()
    .nullable()
    .test('topUpMinExitCharge', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (isFieldVariable(parent?.topUpExitChargeType, parent.topUpExitChargeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Top Up Exit Charge is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Top Up Exit Charge should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.topUpMaxExitCharge || parent?.topUpMaxExitCharge === 0
              ? Number(parent?.topUpMaxExitCharge)
              : 100)
          ) {
            return createError({
              message:
                'Minimum Top Up Exit Charge should be less than or equal to Maximum Top Up Exit Charge',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpMaxExitCharge: yup
    .number()
    .nullable()
    .test('topUpMaxExitCharge', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (isFieldVariable(parent?.topUpExitChargeType, parent.topUpExitChargeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Top Up Exit Charge is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Top Up Exit Charge should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.topUpMinExitCharge || parent?.topUpMinExitCharge === 0
              ? Number(parent?.topUpMinExitCharge)
              : 0)
          ) {
            return createError({
              message:
                'Maximum Top Up Exit Charge should be greater than or equal to Minimum Top Up Exit Charge',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpPlacementFeeApplicable: yup
    .string()
    .nullable()
    .when('isTopupApplicable', {
      is: (isTopupApplicable: boolean) => isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Placement Fee Applicable is required')
        .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Placement Fee Applicable'),
    }),
  topUpPlacementFeeType: yup
    .string()
    .nullable()
    .when(['topUpPlacementFeeApplicable', 'isTopupApplicable'], {
      is: (topUpPlacementFeeApplicable: string, isTopupApplicable: boolean) =>
        isFieldApplicable(topUpPlacementFeeApplicable) && isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Placement Fee Type is required')
        .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Placement Fee'),
    }),
  defaultTopUpPlacementFee: yup
    .number()
    .nullable()
    .test('defaultTopUpPlacementFee', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (!value && value !== 0 && isFieldApplicable(parent?.topUpPlacementFeeApplicable)) {
          return createError({
            message: 'Top Up Placement Fee is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.topUpPlacementFeeType, parent.topUpPlacementFeeApplicable) &&
          (value < Number(parent?.topUpMinPlacementFee) ||
            value > Number(parent?.topUpMaxPlacementFee || 100))
        ) {
          return createError({
            message: `Top Up Placement Fee should be in between or equal to ${
              parent?.topUpMinPlacementFee || 0
            } and ${parent?.topUpMaxPlacementFee || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.topUpPlacementFeeType, parent.topUpPlacementFeeApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Top Up Placement Fee should be in between or equal to 0 and 100',
            path,
          });
        }
      }
      return true;
    }),
  topUpMinPlacementFee: yup
    .number()
    .nullable()
    .test('topUpMinPlacementFee', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (isFieldVariable(parent?.topUpPlacementFeeType, parent.topUpPlacementFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Top Up Placement Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Top Up placement Fee should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.topUpMaxPlacementFee || parent?.topUpMaxPlacementFee === 0
              ? Number(parent?.topUpMaxPlacementFee)
              : 100)
          ) {
            return createError({
              message:
                'Minimum Top Up Placement Fee should be less than or equal to Maximum Top Up Placement Fee',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpMaxPlacementFee: yup
    .number()
    .nullable()
    .test('topUpMaxPlacementFee', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (isFieldVariable(parent?.topUpPlacementFeeType, parent.topUpPlacementFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Top Up Placement Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Top Up Placement Fee should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.topUpMinPlacementFee || parent?.topUpMinPlacementFee === 0
              ? Number(parent?.topUpMinPlacementFee)
              : 0)
          ) {
            return createError({
              message:
                'Maximum Top Up Placement Fee should be greater than or equal to Minimum Top Up Placement Fee',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpOperatingExpenseApplicable: yup
    .string()
    .nullable()
    .when('isTopupApplicable', {
      is: (isTopupApplicable: boolean) => isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Operating Expense Applicable is required')
        .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Top Up Operating Expense Applicable'),
    }),
  topUpOperatingExpenseType: yup
    .string()
    .nullable()
    .when(['topUpOperatingExpenseApplicable', 'isTopupApplicable'], {
      is: (topUpOperatingExpenseApplicable: string, isTopupApplicable: boolean) =>
        isFieldApplicable(topUpOperatingExpenseApplicable) && isTopupApplicable,
      then: yup
        .string()
        .nullable()
        .required('Top Up Operating Expense Type is required')
        .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Top Up Operating Expense'),
    }),
  defaultTopUpOperatingExpenses: yup
    .number()
    .nullable()
    .test('defaultTopUpOperatingExpenses', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (!value && value !== 0 && isFieldApplicable(parent?.topUpOperatingExpenseApplicable)) {
          return createError({
            message: 'Top Up Operating Expense is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(
            parent?.topUpOperatingExpenseType,
            parent.topUpOperatingExpenseApplicable
          ) &&
          (value < Number(parent?.topUpMinOperatingExpense || 0) ||
            value > Number(parent?.topUpMaxOperatingExpense || 100))
        ) {
          return createError({
            message: `Top Up Operating Expense should be in between or equal to ${
              parent?.topUpMinOperatingExpense || 0
            } and ${parent?.topUpMaxOperatingExpense || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(
            parent?.topUpOperatingExpenseType,
            parent.topUpOperatingExpenseApplicable
          ) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Top Up Operating Expense should be in between or equal to 0 and 100',
            path,
          });
        }
      }
      return true;
    }),
  topUpMinOperatingExpense: yup
    .number()
    .nullable()
    .test('topUpMinOperatingExpense', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (
          isFieldVariable(parent?.topUpOperatingExpenseType, parent.topUpOperatingExpenseApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Top Up Operating Expense is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Top Up Operating Expense should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.topUpMaxOperatingExpense || parent?.topUpMaxOperatingExpense === 0
              ? Number(parent?.topUpMaxOperatingExpense)
              : 100)
          ) {
            return createError({
              message:
                'Minimum Top Up Operating Expense should be less than or equal to Maximum Top Up Operating Expense',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpMaxOperatingExpense: yup
    .number()
    .nullable()
    .test(' topUpMaxOperatingExpense', (value, context) => {
      const { path, createError, parent } = context;
      if (parent?.isTopupApplicable) {
        if (
          isFieldVariable(parent?.topUpOperatingExpenseType, parent.topUpOperatingExpenseApplicable)
        ) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Top Up Operating Expense is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Top Up Operating Expense should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.topUpMinOperatingExpense || parent?.topUpMinOperatingExpense === 0
              ? Number(parent?.topUpMinOperatingExpense)
              : 0)
          ) {
            return createError({
              message:
                'Maximum Top Up Operating Expense should be greater than or equal to Minimum Top Up Operating Expense',
              path,
            });
          }
        }
      }
      return true;
    }),
  topUpMinContributionPercentage: yup
    .number()
    .nullable()
    .min(0, 'Min Top Up Contribution Percentage should be between 0 and 100')
    .max(100, 'Min Top Up Contribution Percentage should be between 0 and 100'),
});

export const addPlanValidation = yup
  .object()
  .shape({
    planCode: yup.string().nullable().required('Plan Code is required'),
    planDescription: yup.string().nullable().required('Plan Description is required'),
    schemeNature: yup.string().nullable().required('Scheme Nature is required'),
    // registrationNumber: yup.string().nullable().required('Registration Number is required'),
    fundmanagerIds: yup
      .array()
      .nullable()
      .min(1, 'Investment Manager(s) required')
      .required('Investment Manager Name is required'),
    trusteeIds: yup
      .array()
      .nullable()
      .min(1, 'Trustee(s) required')
      .required('Trustee Name is required'),
    requiredFundManagers: yup
      .number()
      .nullable()
      .test('requiredFundManagers', (value, context) => {
        const { createError, path, parent } = context;
        if (!value) {
          return createError({
            message: 'No. of Investment Manager(s) to esign is required',
            path,
          });
        }
        if (value && parent?.fundmanagerIds?.length < value) {
          return createError({
            message: 'Invalid No. of Investment Manager(s) to esign',
            path,
          });
        }
        return true;
      }),
    requiredTrustees: yup
      .number()
      .nullable()
      .test('requiredTrustees', (value, context) => {
        const { createError, path, parent } = context;
        if (!value) {
          return createError({
            message: 'No. of Trustee(s) to esign is required',
            path,
          });
        }
        if (value && parent?.trusteeIds?.length < value) {
          return createError({
            message: 'Invalid No. of Trustee(s) to esign',
            path,
          });
        }
        return true;
      }),
    schemeCategory: yup
      .string()
      .nullable()
      .required('Scheme Category is required')
      .oneOf(Object.keys(SCHEME_CATEGORY), 'Invalid value of Scheme Category'),
    minCommitmentAmount: yup
      .number()
      .nullable()
      .moreThan(0, `Minimum Commitment Amount should be greater than 0`)
      .required('Minimum Commitment Amount is required'),
    maxCommitmentAmount: yup
      .number()
      .nullable()
      .min(
        yup.ref('minCommitmentAmount'),
        'Maximum Commitment Amount should be greater than or equal to Minimum Commitment Amount'
      )
      .required('Maximum Commitment Amount is required'),
    distributorIds: yup
      .array()
      .nullable()
      .when('isApplicableForDistributor', {
        is: (isApplicableForDistributor: boolean) => isApplicableForDistributor === true,
        then: yup
          .array()
          .nullable()
          .min(1, 'Distributors required')
          .required('Distributors required'),
      }),
    accreditedInvestorApplicable: yup
      .string()
      .nullable()
      .required('Accredited Investor Applicable is required'),
    accreditedMinCommitmentAmount: yup
      .number()
      .nullable()
      .when('accreditedInvestorApplicable', {
        is: (accreditedInvestorApplicable: string) =>
          isAccreditedInvestorApplicable(accreditedInvestorApplicable),
        then: yup
          .number()
          .nullable()
          .moreThan(0, `Accredited minimum commitment amount should be greater than 0`)
          .required('Accredited Minimum Commitment Amount is required'),
      }),
    managementFeeApplicable: yup
      .string()
      .nullable()
      .required('Management Fee Applicable is required')
      .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Management Fee Applicable'),
    managementFeeType: yup
      .string()
      .nullable()
      .when('managementFeeApplicable', {
        is: (managementFeeApplicable: string) => isFieldApplicable(managementFeeApplicable),
        then: yup
          .string()
          .nullable()
          .required('Management Fee Type is required')
          .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Management Fee Type'),
      }),
    defaultManagementFee: yup
      .number()
      .nullable()
      .test('defaultManagementFee', (value, context) => {
        const { path, createError, parent } = context;
        if (!value && value !== 0 && isFieldApplicable(parent?.managementFeeApplicable)) {
          return createError({
            message: 'Management Fee is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.managementFeeType, parent.managementFeeApplicable) &&
          (value < Number(parent?.minManagementFee || 0) ||
            value > Number(parent?.maxManagementFee || 100))
        ) {
          return createError({
            message: `Management Fee should be in between or equal to ${
              parent?.minManagementFee || 0
            } and ${parent?.maxManagementFee || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.managementFeeType, parent.managementFeeApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Management Fee should be in between or equal to 0 and 100',
            path,
          });
        }
        return true;
      }),
    minManagementFee: yup
      .number()
      .nullable()
      .test('minManagementFee', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.managementFeeType, parent.managementFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Management Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Management Fee should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.maxManagementFee || parent?.maxManagementFee === 0
              ? Number(parent?.maxManagementFee)
              : 100)
          ) {
            return createError({
              message:
                'Minimum Management Fee should be less than or equal to Maximum Management Fee',
              path,
            });
          }
        }
        return true;
      }),
    maxManagementFee: yup
      .number()
      .nullable()
      .test('maxManagementFee', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.managementFeeType, parent.managementFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Management Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Management Fee should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.minManagementFee || parent?.minManagementFee === 0
              ? Number(parent?.minManagementFee)
              : 0)
          ) {
            return createError({
              message:
                'Maximum Management Fee should be greater than or equal to Minimum Management Fee',
              path,
            });
          }
        }
        return true;
      }),
    hurdleRateApplicable: yup
      .string()
      .nullable()
      .required('Hurdle Rate Applicable is required')
      .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Hurdle Rate Applicable'),
    hurdleRateType: yup
      .string()
      .nullable()
      .when('hurdleRateApplicable', {
        is: (hurdleRateApplicable: string) => isFieldApplicable(hurdleRateApplicable),
        then: yup
          .string()
          .nullable()
          .required('Hurdle Rate Type is required')
          .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Hurdle Rate Type'),
      }),
    defaultHurdleRate: yup
      .number()
      .nullable()
      .test('defaultHurdleRate', (value, context) => {
        const { path, createError, parent } = context;
        if (!value && value !== 0 && isFieldApplicable(parent?.hurdleRateApplicable)) {
          return createError({
            message: 'Hurdle Rate is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.hurdleRateType, parent.hurdleRateApplicable) &&
          (value < Number(parent?.minHurdleRate || 0) ||
            value > Number(parent?.maxHurdleRate || 100))
        ) {
          return createError({
            message: `Hurdle Rate should be in between or equal to ${
              parent?.minHurdleRate || 0
            } and ${parent?.maxHurdleRate || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.hurdleRateType, parent.hurdleRateApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Hurdle Rate should be in between or equal to 0 and 100',
            path,
          });
        }
        return true;
      }),
    minHurdleRate: yup
      .number()
      .nullable()
      .test('minHurdleRate', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.hurdleRateType, parent.hurdleRateApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Hurdle Rate is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Hurdle Rate should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.maxHurdleRate || parent?.maxHurdleRate === 0
              ? Number(parent?.maxHurdleRate)
              : 100)
          ) {
            return createError({
              message: 'Minimum Hurdle Rate should be less than or equal to Maximum Hurdle Rate',
              path,
            });
          }
        }
        return true;
      }),
    maxHurdleRate: yup
      .number()
      .nullable()
      .test('maxHurdleRate', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.hurdleRateType, parent.hurdleRateApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Hurdle Rate is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Hurdle Rate should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.minHurdleRate || parent?.minHurdleRate === 0
              ? Number(parent?.minHurdleRate)
              : 0)
          ) {
            return createError({
              message: 'Maximum Hurdle Rate should be greater than or equal to Minimum Hurdle Rate',
              path,
            });
          }
        }
        return true;
      }),
    performanceFeeApplicable: yup
      .string()
      .nullable()
      .required('Performance Fee Applicable is required')
      .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Performance Fee Applicable'),
    performanceFeeType: yup
      .string()
      .nullable()
      .when('performanceFeeApplicable', {
        is: (performanceFeeApplicable: string) => isFieldApplicable(performanceFeeApplicable),
        then: yup
          .string()
          .nullable()
          .required('Performance Fee Type is required')
          .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Performance Fee Type'),
      }),
    defaultPerformanceFee: yup
      .number()
      .nullable()
      .test('defaultPerformanceFee', (value, context) => {
        const { path, createError, parent } = context;
        if (!value && value !== 0 && isFieldApplicable(parent?.performanceFeeApplicable)) {
          return createError({
            message: 'Performance Fee is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.performanceFeeType, parent.performanceFeeApplicable) &&
          (value < Number(parent?.minPerformanceFee || 0) ||
            value > Number(parent?.maxPerformanceFee || 100))
        ) {
          return createError({
            message: `Performance Fee should be in between or equal to ${
              parent?.minPerformanceFee || 0
            } and ${parent?.maxPerformanceFee || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.performanceFeeType, parent.performanceFeeApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Performance Fee should be in between or equal to 0 and 100',
            path,
          });
        }
        return true;
      }),
    minPerformanceFee: yup
      .number()
      .nullable()
      .test('minPerformanceFee', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.performanceFeeType, parent.performanceFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Performance Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Performance Fee should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.maxPerformanceFee || parent?.maxPerformanceFee === 0
              ? Number(parent?.maxPerformanceFee)
              : 100)
          ) {
            return createError({
              message:
                'Minimum Performance Fee should be less than or equal to Maximum Performance Fee',
              path,
            });
          }
        }
        return true;
      }),
    maxPerformanceFee: yup
      .number()
      .nullable()
      .test('maxPerformanceFee', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.performanceFeeType, parent.performanceFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Performance Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Performance Fee should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.minPerformanceFee || parent?.minPerformanceFee === 0
              ? Number(parent?.minPerformanceFee)
              : 0)
          ) {
            return createError({
              message:
                'Maximum Performance Fee should be greater than or equal to Minimum Performance Fee',
              path,
            });
          }
        }
        return true;
      }),
    exitChargeApplicable: yup
      .string()
      .nullable()
      .required('Exit Charge Applicable is required')
      .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Exit Charge Applicable'),
    exitChargeType: yup
      .string()
      .nullable()
      .when('exitChargeApplicable', {
        is: (exitChargeApplicable: string) => isFieldApplicable(exitChargeApplicable),
        then: yup
          .string()
          .nullable()
          .required('Exit Charge Type is required')
          .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Exit Charge Type'),
      }),
    defaultExitCharge: yup
      .number()
      .nullable()
      .test('defaultExitCharge', (value, context) => {
        const { path, createError, parent } = context;
        if (!value && value !== 0 && isFieldApplicable(parent?.exitChargeApplicable)) {
          return createError({
            message: 'Exit Charge is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.exitChargeType, parent.exitChargeApplicable) &&
          (value < Number(parent?.minExitCharge || 0) ||
            value > Number(parent?.maxExitCharge || 100))
        ) {
          return createError({
            message: `Exit Charge should be in between or equal to ${
              parent?.minExitCharge || 0
            } and ${parent?.maxExitCharge || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.exitChargeType, parent.exitChargeApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Exit Charge should be in between or equal to 0 and 100',
            path,
          });
        }
        return true;
      }),
    minExitCharge: yup
      .number()
      .nullable()
      .test('minExitCharge', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.exitChargeType, parent.exitChargeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Exit Charge is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Exit Charge should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.maxExitCharge || parent?.maxExitCharge === 0
              ? Number(parent?.maxExitCharge)
              : 100)
          ) {
            return createError({
              message: 'Minimum Exit Charge should be less than or equal to Maximum Exit Charge',
              path,
            });
          }
        }
        return true;
      }),
    maxExitCharge: yup
      .number()
      .nullable()
      .test('maxExitCharge', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.exitChargeType, parent.exitChargeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Exit Charge is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Exit Charge should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.minExitCharge || parent?.minExitCharge === 0
              ? Number(parent?.minExitCharge)
              : 0)
          ) {
            return createError({
              message: 'Maximum Exit Charge should be greater than or equal to Minimum Exit Charge',
              path,
            });
          }
        }
        return true;
      }),
    placementFeeApplicable: yup
      .string()
      .nullable()
      .required('Placement Fee Applicable is required')
      .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Placement Fee Applicable'),
    placementFeeType: yup
      .string()
      .nullable()
      .when('placementFeeApplicable', {
        is: (placementFeeApplicable: string) => isFieldApplicable(placementFeeApplicable),
        then: yup
          .string()
          .nullable()
          .required('Placement Fee Type is required')
          .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Placement Fee'),
      }),
    defaultPlacementFee: yup
      .number()
      .nullable()
      .test('defaultPlacementFee', (value, context) => {
        const { path, createError, parent } = context;
        if (!value && value !== 0 && isFieldApplicable(parent?.placementFeeApplicable)) {
          return createError({
            message: 'Placement Fee is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.placementFeeType, parent.placementFeeApplicable) &&
          (value < Number(parent?.minPlacementFee) ||
            value > Number(parent?.maxPlacementFee || 100))
        ) {
          return createError({
            message: `Placement Fee should be in between or equal to ${
              parent?.minPlacementFee || 0
            } and ${parent?.maxPlacementFee || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.placementFeeType, parent.placementFeeApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'placement Fee should be in between or equal to 0 and 100',
            path,
          });
        }
        return true;
      }),
    minPlacementFee: yup
      .number()
      .nullable()
      .test('minPlacementFee', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.placementFeeType, parent.placementFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Placement Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum placement Fee should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.maxPlacementFee || parent?.maxPlacementFee === 0
              ? Number(parent?.maxPlacementFee)
              : 100)
          ) {
            return createError({
              message:
                'Minimum Placement Fee should be less than or equal to Maximum Placement Fee',
              path,
            });
          }
        }
        return true;
      }),
    maxPlacementFee: yup
      .number()
      .nullable()
      .test('maxPlacementFee', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.placementFeeType, parent.placementFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Placement Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Placement Fee should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.minPlacementFee || parent?.minPlacementFee === 0
              ? Number(parent?.minPlacementFee)
              : 0)
          ) {
            return createError({
              message:
                'Maximum Placement Fee should be greater than or equal to Minimum Placement Fee',
              path,
            });
          }
        }
        return true;
      }),
    operatingExpenseApplicable: yup
      .string()
      .nullable()
      .required('Operating Expense Applicable is required')
      .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Operating Expense Applicable'),
    operatingExpenseType: yup
      .string()
      .nullable()
      .when('operatingExpenseApplicable', {
        is: (operatingExpenseApplicable: string) => isFieldApplicable(operatingExpenseApplicable),
        then: yup
          .string()
          .nullable()
          .required('Operating Expense Type is required')
          .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Operating Expense'),
      }),
    defaultOperatingExpenses: yup
      .number()
      .nullable()
      .test('defaultOperatingExpenses', (value, context) => {
        const { path, createError, parent } = context;
        if (!value && value !== 0 && isFieldApplicable(parent?.operatingExpenseApplicable)) {
          return createError({
            message: 'Operating Expense is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.operatingExpenseType, parent.operatingExpenseApplicable) &&
          (value < Number(parent?.minOperatingExpense || 0) ||
            value > Number(parent?.maxOperatingExpense || 100))
        ) {
          return createError({
            message: `Operating Expense should be in between or equal to ${
              parent?.minOperatingExpense || 0
            } and ${parent?.maxOperatingExpense || 100}`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.operatingExpenseType, parent.operatingExpenseApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Operating Expense should be in between or equal to 0 and 100',
            path,
          });
        }
        return true;
      }),
    minOperatingExpense: yup
      .number()
      .nullable()
      .test('minOperatingExpense', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.operatingExpenseType, parent.operatingExpenseApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Operating Expense is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Operating Expense should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.maxOperatingExpense || parent?.maxOperatingExpense === 0
              ? Number(parent?.maxOperatingExpense)
              : 100)
          ) {
            return createError({
              message:
                'Minimum Operating Expense should be less than or equal to Maximum Operating Expense',
              path,
            });
          }
        }
        return true;
      }),
    maxOperatingExpense: yup
      .number()
      .nullable()
      .test(' maxOperatingExpense', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.operatingExpenseType, parent.operatingExpenseApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Operating Expense is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Operating Expense should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.minOperatingExpense || parent?.minOperatingExpense === 0
              ? Number(parent?.minOperatingExpense)
              : 0)
          ) {
            return createError({
              message:
                'Maximum Operating Expense should be greater than or equal to Minimum Operating Expense',
              path,
            });
          }
        }
        return true;
      }),
    setupFeeApplicable: yup
      .string()
      .nullable()
      .required('Setup Fee Applicable is required')
      .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Setup Fee Applicable'),
    setupFeeType: yup
      .string()
      .nullable()
      .when('setupFeeApplicable', {
        is: (setupFeeApplicable: string) => isFieldApplicable(setupFeeApplicable),
        then: yup
          .string()
          .nullable()
          .required('Setup Fee Type is required')
          .oneOf(Object.keys(FEE_TYPE_MASTER), 'Invalid value of Setup Fee'),
      }),
    setupFee: yup
      .number()
      .nullable()
      .test('setupFee', (value, context) => {
        const { path, createError, parent } = context;
        if (!value && value !== 0 && isFieldApplicable(parent?.setupFeeApplicable)) {
          return createError({
            message: 'Setup Fee is required',
            path,
          });
        }
        if (
          (value || value === 0) &&
          isFieldVariable(parent?.setupFeeType, parent.setupFeeApplicable) &&
          (value < Number(parent?.minSetupFee || 0) || value > Number(parent?.maxSetupFee || 100))
        ) {
          return createError({
            message: `Setup Fee should be in between or equal to ${parent?.minSetupFee || 0} and ${
              parent?.maxSetupFee || 100
            }`,
            path,
          });
        }

        if (
          value &&
          !isFieldVariable(parent?.setupFeeType, parent.setupFeeApplicable) &&
          (value < 0 || value > 100)
        ) {
          return createError({
            message: 'Setup Fee should be in between or equal to 0 and 100',
            path,
          });
        }
        return true;
      }),
    minSetupFee: yup
      .number()
      .nullable()
      .test('minSetupFee', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.setupFeeType, parent.setupFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Minimum Setup Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Minimum Setup Fee should be greater than 0',
              path,
            });
          }
          if (
            value >
            (parent?.maxSetupFee || parent?.maxSetupFee === 0 ? Number(parent?.maxSetupFee) : 100)
          ) {
            return createError({
              message: 'Minimum Setup Fee should be less than or equal to Maximum Setup Fee',
              path,
            });
          }
        }
        return true;
      }),
    maxSetupFee: yup
      .number()
      .nullable()
      .test('maxSetupFee', (value, context) => {
        const { path, createError, parent } = context;
        if (isFieldVariable(parent?.setupFeeType, parent.setupFeeApplicable)) {
          if (!value && value !== 0) {
            return createError({
              message: 'Maximum Setup Fee is required',
              path,
            });
          }
          if (value < 0) {
            return createError({
              message: 'Maximum Setup Fee should be greater than 0',
              path,
            });
          }
          if (
            value <
            (parent?.minSetupFee || parent?.minSetupFee === 0 ? Number(parent?.minSetupFee) : 0)
          ) {
            return createError({
              message: 'Maximum Setup Fee should be greater than or equal to Minimum Setuop Fee',
              path,
            });
          }
        }
        return true;
      }),
    parentShareClass: yup.string().nullable().required('Parent Share Class is required'),
    minContributionPercentage: yup
      .number()
      .nullable()
      .min(0, 'Min Contribution Percentage should be between 0 and 100')
      .max(100, 'Min Contribution Percentage should be between 0 and 100'),
  })
  .concat(topUpPlanValidation);

export const editFundValidation = yup.object().shape({
  schemeCode: yup.string().nullable().required('Scheme Code is required'),
  schemeName: yup.string().nullable().required('Scheme Name is required'),
  LEEGALITY_STAMP_SERIES: yup
    .string()
    .nullable()
    .required('Stamp Paper Series is required')
    .matches(alphaNumeric, 'Special characters are not allowed'),
  LEEGALITY_STAMP_SERIES_GROUP: yup
    .string()
    .nullable()
    .required('Stamp Paper Group is required')
    .matches(alphaNumeric, 'Special characters are not allowed'),
  LEEGALITY_STAMP_VALUE: yup.number().nullable().required('Stamp Paper Value is required'),
  LEEGALITY_USE_STAMP_SERIES: yup.boolean().nullable().required('Field is required'),
});

export const addFundValidation = editFundValidation.concat(addPlanValidation);

export const updateImAndTrusteeFundValidation = (IMId?: number, idForTrustee?: number) =>
  yup.object().shape({
    fundmanagerIds: yup
      .array()
      .nullable()
      .test('fundmanagerIds', (value, context) => {
        const { createError, path } = context;
        if (IMId && value?.length === 0) {
          return createError({
            message: 'Investment Manager(s) required',
            path,
          });
        }
        return true;
      }),
    trusteeIds: yup
      .array()
      .nullable()
      .test('trusteeIds', (value, context) => {
        const { createError, path } = context;
        if (idForTrustee && value?.length === 0) {
          return createError({
            message: 'Trustee(s) required',
            path,
          });
        }
        return true;
      }),
    requiredTrustees: yup
      .number()
      .nullable()
      .test('requiredTrustees', (value, context) => {
        const { createError, path, parent } = context;
        if (idForTrustee && !value) {
          return createError({
            message: 'No. of Trustee(s) to esign is required',
            path,
          });
        }
        if (idForTrustee && value && parent?.trusteeIds?.length < value) {
          return createError({
            message: 'Invalid No. of Trustee(s) to esign',
            path,
          });
        }
        return true;
      }),
    requiredFundManagers: yup
      .number()
      .nullable()
      .test('requiredFundManagers', (value, context) => {
        const { createError, path, parent } = context;
        if (IMId && !value) {
          return createError({
            message: 'No. of Investment Manager(s) to esign is required',
            path,
          });
        }
        if (IMId && value && parent?.fundmanagerIds?.length < value) {
          return createError({
            message: 'Invalid No. of Investment Manager(s) to esign',
            path,
          });
        }
        return true;
      }),
  });
