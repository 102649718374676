import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  CardMedia,
  Dialog,
  Tooltip,
  Theme,
} from '@mui/material';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import {
  applicationDownloadStatusCheck,
  checkIfApplicationIsNonIndividual,
  displaySendBack,
  getFirstHolderName,
  isApplicationStatusComplete,
  isGetLinkVisible,
  handleDownload,
} from '../../utils/utilityFunctions';
import { ConfirmationDialog, LoadingDialog } from '../commonComponents';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { getAllApplications, getApplicationDetails } from '../../redux-store/actions/application';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApplicationProps,
  investmentDetailsAplicationRef,
} from '../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../DataTable';
import {
  APPLICATION_LISTING_STATUS,
  ENABLE_TOP_UP,
  POA_APPLICATION_STATUS,
  REJECTED_ROLES,
  USER_ROLES,
} from '../../utils/constant';
import { formatShortDateAndTime } from '../../utils/date';
import CloseIcon from '@mui/icons-material/Close';
import {
  ApplicationDownload,
  ApplicationRefs,
  getAllTopups,
  topUpInit,
} from '../../redux-store/actions/onBoarding';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { RootStateType } from '../../redux-store/reducers';
import { SxProps } from '@mui/system';
import { useSnackbar } from 'notistack';
import {
  GetAllApplicantionsResponseBody,
  GetAllTopUpApplication,
} from '../../redux-store/types/application';
import { reportDownload } from '../Reports/report-utils';
import PostAddIcon from '@mui/icons-material/PostAdd';
import HistoryIcon from '@mui/icons-material/History';

export const getTableHeader = (showTopUp?: boolean | null): ColumnType[] => {
  return [
    {
      header: 'Application Number',
      label: 'applicationNumber',
      valueGetter: (row: ApplicationProps) => row.applicationNumber || 'N/A',
    },
    {
      header: 'Investor Name',
      valueGetter: ({ applicants = [] }: ApplicationProps) =>
        getFirstHolderName(applicants) || 'N/A',
    },
    {
      header: 'Mode of Holding',
      renderCell: (row: ApplicationProps) => {
        return (
          <Typography
            sx={{
              textTransform: 'capitalize',
            }}>
            {row.modeOfHolding || 'Single'}
          </Typography>
        );
      },
    },
    {
      header: 'Investor PAN',
      valueGetter: ({ applicants = [] }: ApplicationProps) => applicants[0]?.panNo || 'N/A',
    },
    {
      header: 'Distributor Name',
      label: 'viewDistributorRM',
      valueGetter: (row: ApplicationProps) =>
        row.distributor?.name || row.distributor?.arnCode || 'N/A',
    },
    {
      header: 'Filled By',
      label: 'filledBy',
      valueGetter: (row: ApplicationProps) => row.filledBy || 'N/A',
    },
    // {
    //   header: 'Scheme Name',
    //   label: 'schemeName',
    //   valueGetter: (row: ApplicationProps) => (typeof row.scheme === 'string' ? row.scheme : 'N/A'),
    // },
    {
      header: 'Status',
      renderCell: (row: ApplicationProps) => {
        const applicationStatus = APPLICATION_LISTING_STATUS[row.status];
        return (
          <Typography
            className={applicationStatus}
            sx={{
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: 14,
              color: '#B78813',
              '&.Draft': {
                color: 'rgba(51, 127, 201, 0.7)',
              },
              '&.Completed': {
                fontWeight: 600,
                color: 'rgba(35, 133, 63, 0.7)',
              },
              '&.Rejected': {
                color: '#BD2932 !important',
                fontWeight: 500,
              },
              '& span': {
                display: 'block',
                color: 'rgba(51, 127, 201, 0.7)',
                mt: 0.5,
              },
            }}
            component={RouterLink}
            to={{
              pathname: checkIfApplicationIsNonIndividual(row)
                ? `/non-individual-application-details/${row.id}`
                : `/application-details/${row.id}`,
              state: { topUpApplication: !!showTopUp },
            }}>
            {`${applicationStatus}  ${displaySendBack(row)}`}
            {APPLICATION_LISTING_STATUS.sent_to_applicant1 === applicationStatus && (
              <span>Leegality Document {row.leegalityDocumentId ? 'generated' : 'pending'}</span>
            )}
          </Typography>
        );
      },
    },
  ];
};

export const getTopUpTableHeader = (): ColumnType[] => {
  return [
    {
      header: 'Top Up Number',
      label: 'topupApplicationNumber',
      valueGetter: (row: ApplicationProps) => row?.topupApplicationNumber || 'N/A',
    },
    {
      header: 'Top Up Amount',
      valueGetter: (row: ApplicationProps) => row.topupAmount || 'N/A',
    },
    {
      header: 'Aggregate Amount',
      valueGetter: (row: ApplicationProps) => row.aggregateAmount || 'N/A',
    },
    {
      header: 'Status',
      renderCell: (row: ApplicationProps) => {
        const applicationStatus = APPLICATION_LISTING_STATUS[row.status];
        return (
          <Typography
            className={applicationStatus}
            sx={{
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px!important',
              color: '#B78813',
              '&.Draft': {
                color: 'rgba(51, 127, 201, 0.7)',
              },
              '&.Completed': {
                fontWeight: 500,
                color: 'rgba(35, 133, 63, 0.7)',
              },
              '&.Rejected': {
                color: '#BD2932 !important',
                fontWeight: 500,
              },
              '& span': {
                display: 'block',
                color: 'rgba(51, 127, 201, 0.7)',
                mt: 0.5,
              },
            }}
            component={RouterLink}
            to={{
              pathname: checkIfApplicationIsNonIndividual(row)
                ? `/non-individual-application-details/${row.id}`
                : `/application-details/${row.id}`,
              state: { topUpApplication: true },
            }}>
            {`${applicationStatus}  ${displaySendBack(row)}`}
            {APPLICATION_LISTING_STATUS.sent_to_applicant1 === applicationStatus && (
              <span>Leegality Document {row.leegalityDocumentId ? 'generated' : 'pending'}</span>
            )}
          </Typography>
        );
      },
    },
  ];
};

export default function DashboardAndApplicationsList({
  isLoading,
  applicationsList,
  boxStyles,
  DashboardApplicationsList,
}: {
  isLoading: boolean;
  applicationsList: ApplicationProps[];
  boxStyles?: SxProps<Theme>;
  DashboardApplicationsList?: boolean;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();

  const [rejectionDialog, setRejectionDialog] = useState(false);

  const [rejectedApplication, setRejectedApplication] = useState<ApplicationProps>();
  const [isDialogLoading, setDialogLoading] = useState(false);
  const [id, setId] = useState('');
  const [checkPoaOrNI, setCheckPoaOrNI] = useState(false);
  const [tooltip, setTooltip] = useState('Click to Copy');
  const [applicationRefsList, setApplicationRefsList] = useState<investmentDetailsAplicationRef[]>(
    []
  );
  const [open, setOpen] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState<{ id: string } | null>(null);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [applicationsListData, setApplicationsListData] = useState<ApplicationProps[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openMenuDialog, setOpenMenuDialog] = useState(false);
  const [historyTableLoader, setHistoryTableLoader] = useState(false);
  const [topUpApplication, setTopUpApplicationList] = useState<ApplicationProps[]>([]);
  const [noOfPages, setNoPages] = useState(1);
  const [currentPage, setPage] = useState(1);

  const handleClose = () => {
    setOpen(false), setId('');
  };
  const handleDialogClose = () => {
    setDialogLoading(false);
    setOpen(true);
  };

  const handleRejectionDialogClose = () => {
    setRejectionDialog(false);
  };
  useEffect(() => {
    setApplicationsListData(applicationsList);
  }, [applicationsList]);

  useEffect(() => {
    let componentIsAlive = true;
    (async function () {
      try {
        if (id) {
          const responseApplicationRefsList = (await dispatch(
            ApplicationRefs(id, false)
          )) as unknown as investmentDetailsAplicationRef[];
          if (!componentIsAlive) {
            return;
          }
          setApplicationRefsList(responseApplicationRefsList);
          handleDialogClose();
        }
      } catch (e) {
        setDialogLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [id]);

  const handleMenuDialog = () => {
    setOpenMenuDialog(false);
  };

  const handleMenuClick = async (id: string) => {
    try {
      setOpenMenuDialog(true);
      setHistoryTableLoader(true);
      const response = (await dispatch(
        getAllTopups({ applicationId: id })
      )) as unknown as GetAllTopUpApplication;
      const { topups, pages } = response;
      setTopUpApplicationList(topups);
      setNoPages(pages);
    } catch (e) {
      console.error(e);
    } finally {
      setHistoryTableLoader(false);
    }
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const topUpAdditionalCell = {
    header: '',
    renderEditDeleteCell: (row: ApplicationProps): JSX.Element => {
      const [loadingPopup, setLoadingPopup] = useState(false);

      const handleLoadingPopupClose = () => {
        setLoadingPopup(false);
      };
      return (
        <Box display="flex">
          {isGetLinkVisible(row.status) && (
            <Tooltip title="Link">
              <IconButton
                sx={{
                  p: 0,
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                }}
                onClick={() => {
                  setId(row.id as string),
                    setDialogLoading(true),
                    setCheckPoaOrNI(row.hasPOA || checkIfApplicationIsNonIndividual(row));
                }}>
                <CardMedia
                  component="img"
                  src="/images/link-icon.svg"
                  alt="Link"
                  sx={{ width: '30px' }}
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={
              applicationDownloadStatusCheck(row.status || '') || row?.signedLeegalityFile?.url
                ? 'Download'
                : 'Only Signed Documents can be Downloaded'
            }>
            <IconButton
              sx={{
                p: 0,
                pr: 0.5,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                cursor: 'pointer',
                pointerEvents: 'all',
              }}
              onClick={async () => {
                try {
                  if (applicationDownloadStatusCheck(row.status || '')) {
                    setLoadingPopup(true);
                    // eslint-disable-next-line
                    const res = (await dispatch(
                      ApplicationDownload(row.id, !!row?.topupInitiated)
                    )) as unknown as Blob;
                    reportDownload(res as unknown as Blob, `${row.applicationNumber}.pdf`);
                    setLoadingPopup(false);
                    return;
                  }
                  row?.signedLeegalityFile?.url && window.open(row?.signedLeegalityFile?.url);
                } catch (e) {
                  setLoadingPopup(false);
                  console.error((e as Error).message);
                }
              }}>
              <CardMedia
                component="img"
                src={
                  applicationDownloadStatusCheck(row.status || '') || row?.signedLeegalityFile?.url
                    ? '/images/download-icon.png'
                    : '/images/download-icon-disabled.png'
                }
                alt="Download"
                //sx={{ width: '30px' }}
              />
            </IconButton>
          </Tooltip>
          <LoadingDialog
            loadingPopup={loadingPopup}
            onLoadingPopupClose={handleLoadingPopupClose}
            loaderText={'Downloading'}
          />
        </Box>
      );
    },
  };

  const additionalCell = {
    header: '',
    renderEditDeleteCell: (row: ApplicationProps): JSX.Element => {
      const [loadingPopup, setLoadingPopup] = useState(false);

      const handleLoadingPopupClose = () => {
        setLoadingPopup(false);
      };

      return (
        <Box display="flex">
          {APPLICATION_LISTING_STATUS[row.status] === APPLICATION_LISTING_STATUS.rejected && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/comment.svg"
                alt="comment"
                sx={{ width: '20px' }}
                onClick={() => {
                  setRejectedApplication(row);
                  setRejectionDialog(true);
                }}
              />
            </IconButton>
          )}
          {isGetLinkVisible(row.status) && (
            <Tooltip title="Link">
              <IconButton
                sx={{
                  p: 0,
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                }}
                onClick={() => {
                  setId(row.id as string),
                    setDialogLoading(true),
                    setCheckPoaOrNI(row.hasPOA || checkIfApplicationIsNonIndividual(row));
                }}>
                <CardMedia
                  component="img"
                  src="/images/link-icon.svg"
                  alt="Link"
                  sx={{ width: '30px' }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isApplicationStatusComplete(row) && ENABLE_TOP_UP ? (
            <Tooltip
              title={
                (row.hasPOA && [USER_ROLES.SUBDISTRIBUTOR]?.includes(role)) || !row.hasPOA
                  ? 'Create Top Up'
                  : 'Only SRM can create top up for POA application'
              }>
              <IconButton
                disabled={row.hasPOA && ![USER_ROLES.SUBDISTRIBUTOR]?.includes(role)}
                onClick={async () => {
                  try {
                    const response = (await dispatch(
                      topUpInit(row.id)
                    )) as unknown as ApplicationProps;
                    history.push('/onboarding/enter-pan-number', {
                      application: response,
                      firstHolderName: getFirstHolderName(row.applicants),
                      index: 0,
                      fromTopUp: false,
                    });
                  } catch (e) {
                    console.error((e as Error).message);
                  }
                }}
                sx={{
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                  '&.Mui-disabled': {
                    cursor:
                      row.hasPOA && [USER_ROLES.SUBDISTRIBUTOR]?.includes(role)
                        ? 'pointer'
                        : 'not-allowed !important',
                    pointerEvents: 'all',
                  },
                }}>
                <PostAddIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={row.editable ? 'Edit' : ''}>
              <IconButton
                onClick={() => {
                  row.editable &&
                    history.push(
                      checkIfApplicationIsNonIndividual(row)
                        ? `/non-individual-application`
                        : `/application`,
                      { id: row.id, topUpApplication: row.topupInitiated }
                    );
                }}
                sx={{
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                  '&.Mui-disabled': {
                    cursor: row.editable ? 'pointer' : 'not-allowed !important',
                    pointerEvents: 'all',
                  },
                }}
                disabled={!row.editable}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {[USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role) && (
            <Tooltip title={row.deletable ? 'Delete' : 'Application can`t be Deleted'}>
              <IconButton
                sx={{
                  p: 0,
                  pr: 0.5,
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                  '&.Mui-disabled': {
                    cursor: row.deletable ? 'pointer' : 'not-allowed !important',
                    pointerEvents: 'all',
                  },
                }}
                onClick={() => row.deletable && setDeletePopUp({ id: row.id })}
                disabled={!row.deletable}>
                <DeleteOutlineIcon color={row.deletable ? 'error' : 'disabled'} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={
              applicationDownloadStatusCheck(row.status || '') || row?.signedLeegalityFile?.url
                ? 'Download'
                : 'Only Signed Documents can be Downloaded'
            }>
            <IconButton
              sx={{
                p: 0,
                pr: 0.5,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                cursor: 'pointer',
                pointerEvents: 'all',
              }}
              onClick={async () => {
                try {
                  if (applicationDownloadStatusCheck(row.status || '')) {
                    setLoadingPopup(true);
                    // eslint-disable-next-line
                    const res = (await dispatch(
                      ApplicationDownload(row.id, !!row?.topupInitiated)
                    )) as unknown as Blob;
                    reportDownload(res as unknown as Blob, `${row.applicationNumber}.pdf`);
                    setLoadingPopup(false);
                    return;
                  }
                  // row?.signedLeegalityFile?.url && window.open(row?.signedLeegalityFile?.url);
                  row?.signedLeegalityFile?.url &&
                    (await handleDownload(
                      row?.signedLeegalityFile?.url as string,
                      `${row.applicationNumber}.pdf`
                    ));
                } catch (e) {
                  setLoadingPopup(false);
                  console.error((e as Error).message);
                }
              }}>
              <CardMedia
                component="img"
                src={
                  applicationDownloadStatusCheck(row.status || '') || row?.signedLeegalityFile?.url
                    ? '/images/download-icon.png'
                    : '/images/download-icon-disabled.png'
                }
                alt="Download"
                //sx={{ width: '30px' }}
              />
            </IconButton>
          </Tooltip>
          {isApplicationStatusComplete(row) && ENABLE_TOP_UP && (
            <>
              <Tooltip title="Top Up History">
                <IconButton
                  onClick={() => handleMenuClick(row.id)}
                  sx={{
                    ':hover': {
                      bgcolor: '#F4FCFC',
                    },
                  }}>
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          <LoadingDialog
            loadingPopup={loadingPopup}
            onLoadingPopupClose={handleLoadingPopupClose}
            loaderText={'Downloading'}
          />
        </Box>
      );
    },
  };

  return (
    <>
      <DataTable
        isLoading={isLoading}
        tableHeader={[...getTableHeader(), additionalCell]}
        tableData={applicationsListData}
        rowCustomStyles={{
          '&:last-child td, &:last-child th': { border: 0 },
          '.MuiTableCell-body ': { color: '#686e72' },
          background: 'transparent',
        }}
        boxStyles={boxStyles}
      />

      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={4}
          pb={2}>
          <Typography sx={{ color: 'primary.main', fontSize: '20px', fontWeight: 500 }}>
            Get Link
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Grid>
        {applicationRefsList &&
          applicationRefsList.map((ref) => {
            return (
              <React.Fragment key={ref.id}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  px={4}
                  py={1}
                  sx={{
                    ':hover': {
                      bgcolor: '#F4FCFC',
                    },
                    ':last-of-type': {
                      mb: 5,
                    },
                  }}>
                  <Typography
                    sx={{
                      color: 'rgba(41, 49, 57, 0.7)',
                      fontSize: '18px',
                      fontWeight: 500,
                    }}>
                    Copy Link For {ref.name}
                  </Typography>
                  <Tooltip title={tooltip}>
                    <IconButton
                      onMouseOver={() => {
                        setTooltip('Copy Link');
                      }}
                      onClick={() => {
                        setTooltip('Copied'),
                          navigator.clipboard.writeText(
                            checkPoaOrNI
                              ? ref.invitationUrl
                              : ref?.signatureBy &&
                                ('fundManager' === ref.signatureBy ||
                                  USER_ROLES.TRUSTEE === ref.signatureBy)
                              ? ref.invitationUrl
                              : `${window.location.origin}/investment-details/${ref.referenceId}/details`
                          );
                      }}>
                      <CardMedia
                        component="img"
                        src="/images/link-icon.svg"
                        alt="Link"
                        sx={{ width: '35px' }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </React.Fragment>
            );
          })}
      </Dialog>
      <Dialog
        onClose={handleDialogClose}
        open={isDialogLoading}
        sx={{
          '.MuiPaper-root ': {
            p: 4,
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 20,
              fontWeight: 500,
            },
          }}>
          <Typography sx={{ color: 'primary.main', lineHeight: '35px', textAlign: 'center' }}>
            Loading...
          </Typography>
        </Box>
      </Dialog>
      <Dialog
        onClose={handleRejectionDialogClose}
        open={rejectionDialog}
        sx={{
          '.MuiPaper-root ': {
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <IconButton
          onClick={handleRejectionDialogClose}
          sx={{ alignSelf: 'flex-end', mt: 2, mr: 2 }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        {rejectedApplication && (
          <Box
            sx={{
              width: { xs: '80%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontWeight: 500,
              },
              mt: 1,
              mb: 5,
            }}>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 6.7 },
                mt: 1,
              }}>
              Application Id:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {rejectedApplication.applicationNumber}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 1.5 },
                mt: 1,
              }}>
              Application Status:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#BD2932',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {POA_APPLICATION_STATUS[rejectedApplication.status]}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 9 },
                mt: 1,
              }}>
              Rejected on:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {formatShortDateAndTime(rejectedApplication.rejectedAt)}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 9.5 },
                mt: 1,
              }}>
              Rejected by:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {REJECTED_ROLES[rejectedApplication.rejectedByRole]}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                mt: 1,
              }}>
              Reason for rejection:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {rejectedApplication.rejectedReason}
              </Typography>
            </Typography>
            <Button
              variant="contained"
              sx={{ ml: '32%', lineHeight: 1.1, width: '30%', mt: 3 }}
              onClick={handleRejectionDialogClose}>
              Done
            </Button>
          </Box>
        )}
      </Dialog>
      <ConfirmationDialog
        message={'Are you sure you want to delete Application ?'}
        open={deletePopUp !== null}
        setOpen={() => setDeletePopUp(null)}
        onSave={async () => {
          try {
            setDeletePopUp(null);
            // eslint-disable-next-line
            const _application = (await dispatch(
              getApplicationDetails(deletePopUp?.id || '', 'delete')
            )) as unknown as ApplicationProps;
            if (DashboardApplicationsList) {
              setDialogLoading(true);
              const response = (await dispatch(
                getAllApplications({
                  page: 1,
                  limit: 4,
                })
              )) as unknown as GetAllApplicantionsResponseBody;
              const { applications } = response || {};
              setApplicationsListData(applications);
              setDialogLoading(false);
            } else {
              setApplicationsListData(
                applicationsListData.filter(
                  (application) => application.id !== deletePopUp?.id || ''
                )
              );
            }
            enqueueSnackbar('Application Deleted Successfully', {
              variant: 'success',
              autoHideDuration: 3000,
            });
          } catch (e) {
            console.error((e as Error).message);
          } finally {
            setDialogLoading(false);
          }
        }}
        onCancel={() => setDeletePopUp(null)}
      />
      <Dialog
        onClose={handleMenuDialog}
        open={openMenuDialog}
        sx={{
          '.MuiPaper-root ': {
            p: 4,
            width: { xs: '70%', sm: '70%' },
            maxWidth: 'fit-content',
          },
        }}>
        <Box
          sx={{
            width: 'fit-content',
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 20,
              fontWeight: 500,
            },
            // maxWidth: 'fit-content',
          }}>
          <Typography
            sx={{ color: 'primary.main', lineHeight: '35px', textAlign: 'center', pb: 1 }}>
            Top Up History
          </Typography>
          <DataTable
            isLoading={historyTableLoader}
            // isLoading={true}
            tableHeader={[...getTopUpTableHeader(), topUpAdditionalCell]}
            tableData={topUpApplication}
            rowCustomStyles={{
              '&:last-child td, &:last-child th': { border: 0 },
              '.MuiTableCell-body ': { color: '#686e72' },
              background: 'transparent',
            }}
            boxStyles={{ ...boxStyles, paddingTop: '20px' }}
            noOfPages={noOfPages}
            currentPage={currentPage}
            paginationAction={handlePageChange}
            isTopUp={true}
          />
        </Box>
      </Dialog>
    </>
  );
}
