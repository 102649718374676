import { IconButton, Typography, CardMedia, LinearProgress, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { getApplicationDetails, updateApplication } from '../../redux-store/actions/application';
import { ApplicationProps, SendBackType } from '../../redux-store/types/api-types';
import {
  AmlDetails,
  ApplicationHeader,
  CommonLayout,
  ConfirmationDialog,
  SendbackDialog,
} from '../commonComponents';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { RiskProfileEnabled } from '../../utils/constant';
import { checkIfApplicationIsNonIndividualPOA } from '../../utils/utilityFunctions';
import ContributorDetails from './ContributorDetails';
import { RootStateType } from '../../redux-store/reducers';
import ContactDetails from './ContactDetails';
import InvestAndPaymentDetails from '../ApplicationDetails/InvestmentAndPayment';
import BankDetails from '../ApplicationDetails/BankDetails';
import AuthorizedSignatories from './AuthorisedSignatories';
import Documents from './Document';
import Ubo from './ubo';
import Fatca from './fatca';
import { useRouteMatch } from 'react-router';
import DistributorDetails from '../ApplicationDetails/rmDistributor';
import Comments from '../ApplicationDetails/Comments';
import RiskProfiles from '../ApplicationDetails/riskProfileDetails';
import { ENABLE_RTA_APPROVER } from '../../utils/constant';
import { Location } from 'history';
import { getSpecificTopups } from '../../redux-store/actions/onBoarding';

export const NonIndividualApplicationLayout = ({
  loading,
  application,
}: {
  loading: boolean;
  application: ApplicationProps;
}): JSX.Element => {
  return (
    <>
      {loading && <LinearProgress />}
      {application && (
        <>
          <ApplicationHeader application={application} />
          <AmlDetails
            applicants={application.applicants}
            applicationType={application.applicationType}
          />
          {application.comments?.length > 0 && <Comments application={application} />}
          <DistributorDetails application={application} />
          {!application?.isTopup ? (
            <>
              <ContributorDetails applicants={application.applicants} />
              <ContactDetails applicants={application.applicants} />
              {RiskProfileEnabled && <RiskProfiles application={application} />}
              <InvestAndPaymentDetails application={application} />
              <BankDetails banks={application.banks} displayTopUpBanks={application?.isTopup} />
              <Documents application={application} />
              <AuthorizedSignatories application={application} />
              <Ubo application={application} />
              <Fatca applicants={application.applicants} />
            </>
          ) : (
            <>
              <BankDetails banks={application.banks} displayTopUpBanks={application?.isTopup} />
              <InvestAndPaymentDetails application={application} />
              <AuthorizedSignatories application={application} />
              <Documents application={application} />
            </>
          )}
        </>
      )}
    </>
  );
};

//Read only view for RM, Distributor, Subdistributor
export const NonIndividualApplicationDetail = ({
  location,
}: {
  location: Location<{
    topUpApplication: boolean | null;
  }>;
}): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { topUpApplication } = location.state || {};
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState<ApplicationProps>();
  const applicationDetailRef = useRef<HTMLDivElement>();
  const { path } = useRouteMatch();
  const [openDialog, setDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);

  const [openSendBackDialog, setOpenSendBackDialog] = useState(false);
  const handleSendBack = () => setOpenSendBackDialog(true);
  const [sendBackLoading, setSendBackLoading] = useState(false);
  const handleSendBackClose = () => {
    setOpenSendBackDialog(false), setSendBackLoading(false);
  };
  const onSendBackSubmit = async (values: SendBackType) => {
    try {
      handleSendBackClose();
      setSendBackLoading(true);
      const { id } = application || {};
      if (id) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              status: checkIfApplicationIsNonIndividualPOA(application as ApplicationProps)
                ? 'sent_to_poa_approver'
                : 'sent_to_amc_approver',
              comment: values.comment,
              applicationSentBack: false,
            },
            applicationId: id,
          })
        );
        history.push(topUpApplication ? '/view-top-up-list' : '/applications');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setSendBackLoading(false);
    }
  };

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const _application = topUpApplication
          ? ((await dispatch(getSpecificTopups(Number(id)))) as unknown as ApplicationProps)
          : ((await dispatch(getApplicationDetails(id))) as unknown as ApplicationProps);
        if (!isComponentActive) return;
        setApplication(_application);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentActive) setLoading(false);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const { editable, applicationSentBack = false } = application || {};

  const handleSubmit = async () => {
    try {
      const { id } = application || {};
      setIsSubmitting(true);
      if (id) {
        await dispatch(
          updateApplication({
            body: {
              ...application,

              status: checkIfApplicationIsNonIndividualPOA(application as ApplicationProps)
                ? 'sent_to_poa_approver'
                : 'sent_to_amc_approver',
            },
            applicationId: id,
          })
        );
        history.push(topUpApplication ? '/view-top-up-list' : '/applications');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CommonLayout>
      <>
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          ref={applicationDetailRef}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              component={RouterLink}
              to={topUpApplication ? '/view-top-up-list' : '/applications'}>
              <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
              Application Details
            </Typography>
          </Box>
          {editable && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() =>
                  history.push(`/non-individual-application`, {
                    id,
                    topUpApplication,
                  })
                }
              />
            </IconButton>
          )}
        </Box>
        <IconButton
          sx={{
            position: 'fixed',
            right: 0,
            bottom: 75,
            borderRadius: '5px  0 0 5px',
            '&,:hover': {
              bgcolor: 'primary.main',
            },
          }}
          onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
          <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
        </IconButton>
        <NonIndividualApplicationLayout
          loading={loading}
          application={application as ApplicationProps}
        />
        {path === '/non-individual-application-preview/:id' && editable && (
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              sx={{
                color: 'common.white',
                minWidth: '200px',
                mt: 4,
                fontWeight: 600,
                lineHeight: 1.5,
                fontSize: '16px',
              }}
              onClick={() => setDialog(true)}
              disabled={isSubmitting || sendBackLoading}>
              {`Send to ${
                checkIfApplicationIsNonIndividualPOA(application as ApplicationProps)
                  ? 'POA Approver'
                  : ENABLE_RTA_APPROVER
                  ? 'AMC approver'
                  : 'AIF approver'
              } ${isSubmitting || sendBackLoading ? '...' : ''}`}
            </Button>
          </Box>
        )}
        <SendbackDialog
          handleSendBackClose={() => handleSendBackClose()}
          openSendBackDialog={openSendBackDialog}
          onSendBackSubmit={(values: SendBackType) => onSendBackSubmit(values)}
          role={role}
          sendBackLoading={sendBackLoading}
        />
        <ConfirmationDialog
          message={`Are you sure you want to send it to ${
            checkIfApplicationIsNonIndividualPOA(application as ApplicationProps)
              ? 'POA Approver'
              : ENABLE_RTA_APPROVER
              ? 'AMC approver'
              : 'AIF approver'
          }`}
          open={openDialog}
          setOpen={() => setDialog(false)}
          onSave={async () => {
            setDialog(false);
            applicationSentBack ? handleSendBack() : handleSubmit();
          }}
          onCancel={() => setDialog(false)}
        />
      </>
    </CommonLayout>
  );
};
