/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  Hidden,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Pagination,
  Stack,
  // eslint-disable-next-line
  Switch,
  Grid,
  Typography,
  Theme,
  Skeleton,
  Collapse,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { SxProps } from '@mui/system';
import { truncateText } from '../../utils/utilityFunctions';
import { TRUNCATE_TEXT_LENGTH } from '../../utils/constant';

/**
 * @param header - Column header as a string or a function returning a React node.
 * @param label - Optional label to extract value from the row.
 * @param value - Optional value to render in the cell (string, number, boolean, or undefined).
 * @function valueGetter - Computes the cell value.
 *                  `(row: any)` => `string | number | boolean | undefined`
 * @param isSwitch - Optional flag indicating if the cell is a switch component.
 * @function switchAction - Triggered when the switch is toggled.
 *                  `(id: string)` => `void`
 * @function renderCell - Custom rendering for a cell.
 *                  `(row: any)` => `React.ReactNode`
 * @function renderEditDeleteCell - Renders edit/delete actions in a cell.
 *                  `(row: any, index: number, groupIndex?: number)` => `React.ReactNode`
 * @param truncate_text - Optional flag to truncate text in the cell.
 */

export type ColumnType = {
  /**
   * Header of the column
   */
  header: string | (() => React.ReactNode);

  /**
   * To get the value from the row
   */
  label?: string;

  /**
   * Value to render in the table
   */
  value?: string | number | boolean | undefined;

  /**
   * Function to get the value from the row
   */
  valueGetter?: (row: any) => string | number | boolean | undefined;

  /**
   * Boolean to check if the cell is switch
   */
  isSwitch?: boolean;

  /**
   * Action for switch component
   */
  switchAction?: (id: string) => void;

  /**
   * Render custom cell
   */
  renderCell?: (row: any) => React.ReactNode;

  /**
   * Renders edit/delete actions in a cell
   */
  renderEditDeleteCell?: (row: any, index: number, groupIndex?: number) => React.ReactNode;

  /**
   * Boolean flag to truncate large text
   */
  truncate_text?: boolean;
};

export const MobileViewDataRow = ({
  row,
  index,
  groupIndex,
  tableHeader,
  isLoading = false,
}: {
  row: any;
  index: number;
  groupIndex?: number;
  tableHeader: ColumnType[];
  isLoading?: boolean;
}): JSX.Element => {
  return (
    <Grid
      key={index}
      container
      // columnSpacing={1}
      sx={{
        border: '1px solid #00D9DA',
        p: 1.25,
        // ml: 0,
        borderRadius: 1.25,
        mb: 1.25,
        rowGap: 1.25,
        padding: { xs: '10px', sm: '55px' },
        '.MuiGrid-item': {
          px: 1,
        },
        '.MuiTypography-root': {
          fontSize: { xs: 10, sm: 16 },
          fontWeight: 500,
          maxWidth: '204px',
          overflowWrap: 'anywhere',
        },
        '& .MuiTypography-h6': {
          fontFamily: 'Poppins, sans-serif',
          color: 'primary.main',
        },
        '& .MuiTypography-body1': {
          color: 'rgba(41, 49, 57, 0.7)',
        },
      }}>
      {tableHeader.map((headerList, idx) => {
        const {
          valueGetter,
          // eslint-disable-next-line
          isSwitch,
          label = '',
          renderCell,
          header,
          renderEditDeleteCell,
        } = headerList;
        return (
          <Grid item xs={6} sm={4} key={idx}>
            {typeof header !== 'string' ? (
              header()
            ) : (
              <Typography variant="h6">{headerList.header}</Typography>
            )}
            {isLoading ? (
              <Skeleton />
            ) : // isSwitch ? (
            //   <Stack
            //     sx={{
            //       '.MuiSwitch-root': { paddingLeft: '0px', width: '52px' },
            //       '.MuiSwitch-switchBase': { paddingLeft: '0px' },
            //     }}>
            //     <Switch
            //       sx={{
            //         '.MuiSwitch-switchBase': { color: 'black' },
            //       }}
            //       checked={row[label]}
            //       onChange={() => headerList.switchAction && headerList.switchAction(row.id)}
            //     />
            //   </Stack>
            // ) :
            renderCell ? (
              renderCell(row)
            ) : renderEditDeleteCell ? (
              renderEditDeleteCell(row, index, groupIndex)
            ) : (
              <Typography>{valueGetter ? valueGetter(row) : row[label]}</Typography>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

const DataRow = ({
  row,
  index,
  rowCustomStyles,
  groupIndex,
  currentPage,
  tableHeader,
  isExpanded,
  onToggleExpand,
}: {
  row: any;
  index: number;
  rowCustomStyles?: SxProps<Theme>;
  groupIndex?: number;
  currentPage?: number;
  tableHeader: ColumnType[];
  isExpanded: boolean; // flag to expand row
  onToggleExpand: () => void; // set row to be expanded
}): JSX.Element => {
  return (
    <>
      <TableRow
        key={index}
        onClick={onToggleExpand}
        sx={{
          background: currentPage ? '#FAFAFB' : '',
          ':hover': {
            bgcolor: currentPage ? '#FAFAFB' : '',
          },
          ...rowCustomStyles,
        }}>
        {tableHeader.map((header, ind) => {
          const {
            valueGetter,
            // eslint-disable-next-line
            isSwitch,
            renderCell,
            label = '',
            renderEditDeleteCell,
            truncate_text = false,
          } = header;
          return (
            <TableCell key={ind} align="center">
              {/* {isSwitch ? (
              <Stack alignItems={'center'}>
                <Switch
                  sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                  checked={row.isActive}
                  // onChange={() => header.switchAction && header.switchAction(row.id)}
                />
              </Stack>
            ) :  */}
              {valueGetter
                ? truncate_text // truncate length if truncate_text flag is true
                  ? truncateText(valueGetter(row) as string, TRUNCATE_TEXT_LENGTH)
                  : valueGetter(row)
                : renderCell
                ? renderCell(row)
                : renderEditDeleteCell
                ? renderEditDeleteCell(row, index, groupIndex)
                : row[label]}
            </TableCell>
          );
        })}
      </TableRow>

      <TableRow>
        <TableCell colSpan={tableHeader.length} sx={{ padding: 0 }}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <MobileViewDataRow index={index} row={row} tableHeader={tableHeader} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const WebViewDataRow = ({
  rowCustomStyles,
  renderAdditionalRow = false,
  tableBodyCustomStyles,
  tableHeaderCustomStyles,
  groupIndex,
  currentPage,
  tableHeader,
  tableData,
  isTopUp,
  rowExpandable,
}: {
  rowCustomStyles?: SxProps<Theme>;
  currentPage?: number;
  renderAdditionalRow?: boolean;
  tableBodyCustomStyles?: SxProps<Theme>;
  tableHeaderCustomStyles?: SxProps<Theme>;
  groupIndex?: number;
  tableHeader: ColumnType[];
  tableData: any;
  isTopUp?: boolean;
  rowExpandable?: boolean; // rowExpandable flag will expand rows of a table
}): JSX.Element => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  return (
    <TableContainer sx={{ bgcolor: 'common.white' }}>
      <Table aria-label="Application table">
        <TableHead>
          <TableRow
            sx={{
              '.MuiTableCell-head': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 12,
                color: 'primary.main',
              },
              ...tableHeaderCustomStyles,
            }}>
            {tableHeader.map((currHeader: ColumnType, index: number) => {
              const { header } = currHeader;
              return (
                <TableCell
                  key={'h' + index}
                  align="center"
                  sx={{
                    whiteSpace: 'nowrap',
                  }}>
                  {typeof header !== 'string' ? header() : currHeader['header']}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '.MuiTableRow-root': {
              cursor: currentPage ? 'pointer' : 'auto',
              ':hover': {
                bgcolor: currentPage ? '#F4FCFC' : '',
              },
            },
            ...tableBodyCustomStyles,
          }}>
          {tableData.length ? (
            tableData.map((row: any, index: number) => (
              <React.Fragment key={index}>
                <DataRow
                  groupIndex={groupIndex}
                  index={index}
                  row={row}
                  key={index}
                  rowCustomStyles={rowCustomStyles}
                  currentPage={currentPage}
                  tableHeader={tableHeader}
                  isExpanded={Boolean(rowExpandable) && expandedRow === index}
                  onToggleExpand={
                    () => rowExpandable && setExpandedRow((prev) => (prev === index ? null : index)) // set index for row to make expanded
                  }
                />
                {renderAdditionalRow && (
                  <TableRow
                    sx={{
                      '.MuiTableCell-root': {
                        padding: '4px',
                      },
                    }}>
                    <TableCell colSpan={tableHeader.length}></TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={tableHeader.length}
                sx={{
                  textAlign: 'center',
                  height: 100,
                }}>
                {isTopUp ? 'No Top Up Found' : 'No data found'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export function DataTable({
  isLoading,
  tableData,
  tableHeader,
  rowCustomStyles,
  noOfPages,
  currentPage,
  paginationAction,
  renderAdditionalRow = false,
  tableBodyCustomStyles,
  tableHeaderCustomStyles,
  groupIndex,
  boxStyles,
  isTopUp,
  rowExpandable,
}: {
  isLoading?: boolean;
  tableData: any;
  tableHeader: ColumnType[];
  rowCustomStyles?: SxProps<Theme>;
  noOfPages?: number;
  currentPage?: number;
  paginationAction?: (event: ChangeEvent<unknown>, value: number) => void;
  renderAdditionalRow?: boolean;
  tableBodyCustomStyles?: SxProps<Theme>;
  tableHeaderCustomStyles?: SxProps<Theme>;
  groupIndex?: number;
  boxStyles?: SxProps<Theme>;
  isTopUp?: boolean | null;
  rowExpandable?: boolean; // rowExpandable flag will expand rows of a table
}): JSX.Element {
  tableData = isLoading ? new Array(5).fill({}) : tableData;
  return (
    <>
      <Hidden only="xs">
        <Box
          sx={{
            maxHeight: 'calc(100vh - 400px)',
            overflow: 'auto',
            minHeight: '480px',
            minWidth: 650,
            ...boxStyles,
          }}>
          {!isLoading ? (
            <WebViewDataRow
              rowCustomStyles={rowCustomStyles}
              currentPage={currentPage}
              renderAdditionalRow={renderAdditionalRow}
              tableBodyCustomStyles={tableBodyCustomStyles}
              tableHeaderCustomStyles={tableHeaderCustomStyles}
              groupIndex={groupIndex}
              tableHeader={tableHeader}
              tableData={tableData}
              isTopUp={!!isTopUp}
              rowExpandable={rowExpandable}
            />
          ) : (
            <LinearProgress />
          )}
        </Box>
      </Hidden>
      <Hidden smUp={true}>
        {tableData?.length ? (
          tableData.map((row: any, index: number) => (
            <MobileViewDataRow
              index={index}
              row={row}
              key={index}
              tableHeader={tableHeader}
              isLoading={isLoading}
            />
          ))
        ) : (
          <Typography
            sx={{
              textAlign: 'center',
              height: 100,
            }}>
            {isTopUp ? 'No Top Up Found' : 'No data found'}
          </Typography>
        )}
      </Hidden>
      {!!tableData.length && !!currentPage && !isLoading && (
        <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
          <Pagination
            count={noOfPages}
            page={currentPage}
            color="primary"
            onChange={paginationAction}
          />
        </Stack>
      )}
    </>
  );
}
