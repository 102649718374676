import {
  AmlDetails,
  ApplicationHeader,
  CommonLayout,
  CommonLayoutForPOA,
  Footer,
  SendbackDialog,
} from '../commonComponents';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {
  Box,
  Button,
  CardMedia,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  Dialog,
} from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getApplicationDetails,
  isFormValidForSubmission,
  nonIndividualFormValidForSubmission,
  updateApplication,
  updateApplicationByRTA,
} from '../../redux-store/actions/application';
import ContactDetails from './contactDetails';
import KycDetails from './kycDetails';
import FatcaDetails from './FatcaDetails';
import NomineeDetails from './nomineeDetails';
import InvestAndPaymentDetails from './InvestmentAndPayment';
import BankDetails from './BankDetails';
import DocumentDetails from './DocumentDetails';
import {
  ApplicationProps,
  Broker,
  SendBackType,
  individuals_Poa_nonIndividuals_Documents,
} from '../../redux-store/types/api-types';
import ContributorDetails from './contributor';
import {
  USER_ROLES,
  APPLICATION_TYPE,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICATION_LISTING_STATUS,
  RiskProfileEnabled,
  ENABLE_RTA_APPROVER,
} from '../../utils/constant';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory, useRouteMatch } from 'react-router';
import * as yup from 'yup';
import { Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { MFTextField } from '../../lib/formik';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LoadingButton from '@mui/lab/LoadingButton';

import {
  AMCApproverLoggedIn,
  MakerOrAdmin,
  RTAApproverEnableCheckForIndividual,
  RTAApproverLoggedIn,
  applyRoleBasedStatus,
  checkIfApplicationIsNonIndividual,
  checkIfApplicationIsNonIndividualPOA,
  isRtaSendBackToMaker,
  sendApplication_Nri,
} from '../../utils/utilityFunctions';
import { NonIndividualApplicationLayout } from '../NonIndividualApplicationDetails';
import { showError } from '../../redux-store/actions/auth';
import { ConfirmationDialog } from '../commonComponents';
import DistributorDetails from './rmDistributor';
import Comments from './Comments';
import RiskProfiles from './riskProfileDetails';
import { styles } from '../../Styles/styles';
import {
  useFeatureToggles,
  useMdmsBrokerListData,
  useMdmsCountryData,
  useMdmsDocumentData,
  useMdmsRiskProfileData,
  useMdmsTopUpDocumentData,
} from '../../utils/useDataMdms';
import { Location } from 'history';
import { getSpecificTopups } from '../../redux-store/actions/onBoarding';

export const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: '10px',
  marginLeft: '2px',
  color: theme.palette.text.secondary,
  fontSize: '15px',
  fontWeight: 500,
  letterSpacing: '0.5px',
}));
export const ItemDetails = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontSize: '16px',
  marginLeft: '2px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  maxWidth: '100%',
  overflowWrap: 'anywhere',
}));
export const Gridstyles = styled('div')(({ theme }) => ({
  paddingLeft: '10%',
  [theme.breakpoints.only('xs')]: {
    paddingLeft: 0,
  },
}));

export function RejectedDetails({
  title,
  description,
}: {
  title: string | null;
  description: string | null;
}): JSX.Element {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ fontSize: 17, fontWeight: 500, color: 'text.secondary' }}>
          {title}:{' '}
          <Typography
            component="span"
            sx={{
              color: 'text.secondary',
              fontSize: '16px',
              letterSpacing: '0.4px',
              fontWeight: 700,
              ml: { sm: 0.5 },
            }}>
            {description}
          </Typography>
        </Typography>
      </Grid>
    </>
  );
}

export function ApplicationDetailsCommonLayout({
  loading,
  application,
}: {
  loading: boolean;
  application: ApplicationProps;
}): JSX.Element {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <>
      {loading && <LinearProgress />}
      {application && (
        <>
          <ApplicationHeader application={application} />
          {USER_ROLES.INVESTOR !== role && (
            <AmlDetails
              applicants={application.applicants}
              applicationType={application.applicationType}
            />
          )}
          {role !== USER_ROLES.INVESTOR && application.comments?.length > 0 && (
            <Comments application={application} />
          )}
          {role !== USER_ROLES.INVESTOR && <DistributorDetails application={application} />}
          {!application?.isTopup ? (
            <>
              <ContributorDetails
                applicants={application.applicants}
                modeOfHolding={application.modeOfHolding}
              />
              <ContactDetails applicants={application.applicants} />
              <KycDetails applicants={application.applicants} />
              <FatcaDetails applicants={application.applicants} />
              <NomineeDetails
                nominees={application.nominees}
                onboardingType={application.onboardingType}
              />
              <BankDetails banks={application.banks} displayTopUpBanks={application?.isTopup} />
              {RiskProfileEnabled && <RiskProfiles application={application} />}
            </>
          ) : (
            <>
              <BankDetails banks={application.banks} displayTopUpBanks={application?.isTopup} />
            </>
          )}
          <InvestAndPaymentDetails application={application} />
          <DocumentDetails application={application} />
        </>
      )}
    </>
  );
}

const RejectApproveButtons = ({
  handleOpen,
  handleDialogOpen,
  approve,
  reject,
  handleSendBack,
  sendBack,
}: {
  handleOpen: () => void;
  handleDialogOpen: () => void;
  approve: boolean;
  reject: boolean;
  handleSendBack: () => void;
  sendBack: boolean;
}): JSX.Element => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        '.MuiButton-root': {
          minWidth: { xs: '100px', sm: '150px', md: '200px' },
          maxWidth: '400px',
          mt: 4,
          fontWeight: 600,
          lineHeight: 1.1,
          mx: 1.5,
        },
      }}>
      <LoadingButton
        loadingPosition="start"
        type="submit"
        loading={reject}
        variant="outlined"
        sx={{ color: 'primary.main' }}
        onClick={handleOpen}
        disabled={approve || sendBack}>
        Reject
      </LoadingButton>
      <LoadingButton
        loadingPosition="start"
        type="submit"
        loading={approve}
        variant="contained"
        sx={{ color: 'common.white' }}
        onClick={handleDialogOpen}
        disabled={reject || sendBack}>
        Approve
      </LoadingButton>
      <LoadingButton
        loadingPosition="start"
        type="submit"
        loading={sendBack}
        variant="contained"
        sx={{ color: 'common.white' }}
        onClick={handleSendBack}
        disabled={reject || approve}>
        Send Back
      </LoadingButton>
    </Grid>
  );
};

//Read only view for POA and Non Individual Application for AMC Approver,RTA Approver,Individual Application for AMC Approver, RTA Approver and POA Approver
const PoaOrNIApplicationDetails = ({
  loading,
  application,
  isTopUp,
}: {
  loading: boolean;
  application: ApplicationProps;
  isTopUp: boolean | null;
}): JSX.Element => {
  const { status, editable, applicationType } = application || {};
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [approveClickedorSendBack, setApproveClickedorSendBack] = useState(false);
  const { countries } = useMdmsCountryData();
  const { BrokerList } = useMdmsBrokerListData();
  const { riskProfile } = useMdmsRiskProfileData();
  const { Document } = useMdmsDocumentData();
  const { TopUpDocument } = useMdmsTopUpDocumentData();
  const { toggler } = useFeatureToggles();

  const validateForm = async () => {
    const { applicants = [] } = application || {};
    const getInvestorType = applicants[0]?.investorType;
    let risk;
    if (RiskProfileEnabled) {
      risk = riskProfile.riskProfile;
    }
    if (applicationType === APPLICATION_TYPE.INDIVIDUAL) {
      await isFormValidForSubmission(
        application as ApplicationProps,
        risk,
        toggler,
        true,
        true,
        countries,
        BrokerList.broker_list as Broker[],
        Document,
        TopUpDocument,
        role
      );
    }
    if (applicationType === APPLICATION_TYPE.NON_INDIVIDUAL && getInvestorType) {
      await nonIndividualFormValidForSubmission(
        application,
        risk,
        toggler,
        Document[
          `${
            checkIfApplicationIsNonIndividualPOA(application)
              ? `${getInvestorType}_poa`
              : getInvestorType
          }`
        ] as individuals_Poa_nonIndividuals_Documents[],
        TopUpDocument[
          `${
            checkIfApplicationIsNonIndividualPOA(application)
              ? `${getInvestorType}_poa`
              : getInvestorType
          }`
        ] as individuals_Poa_nonIndividuals_Documents[],
        countries.countries,
        BrokerList.broker_list as Broker[],
        true,
        role
      );
    }
  };

  const handleOpen = () => setOpen(true);
  const handleDialogOpen = async () => {
    try {
      setApproveLoading(true);
      await validateForm();
      setApproveLoading(false);
      setOpenDialog(true);
    } catch (e) {
      setApproveLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const [openSendBackDialog, setOpenSendBackDialog] = useState(false);
  const [sendBackLoading, setSendBackLoading] = useState(false);

  const handleSendBack = () => {
    setOpenSendBackDialog(true), setApproveClickedorSendBack(true);
  };
  const handleSendBackAMCtoRTA = () => setOpenSendBackDialog(true);
  const handleSendBackClose = () => {
    setOpenSendBackDialog(false), setSendBackLoading(false), setApproveClickedorSendBack(false);
  };

  const handleClose = () => {
    setOpen(false), setApproveLoading(false);
  };
  const handleDialogClose = () => {
    setOpenDialog(false), setRejectLoading(false);
  };

  const initialValues: Partial<ApplicationProps> = { rejectedReason: '' };
  const resonValidation = yup.object().shape({
    rejectedReason: yup.string().required('Please enter the reason for rejection'),
  });
  //It will called when POA/AMC Approver Approves the application
  const onApproveByAMCApprover = async (values: SendBackType) => {
    await dispatch(
      updateApplication({
        body: {
          ...application,
          ...(values.comment ? { comment: values.comment } : {}),
          rtaSentBack:
            AMCApproverLoggedIn(role) && application?.rtaSentBack
              ? false
              : application?.rtaSentBack,
          status:
            USER_ROLES.POAAPPROVER === role &&
            APPLICATION_LISTING_STATUS[application?.status] ===
              APPLICATION_LISTING_STATUS.sent_to_poa_approver
              ? 'sent_to_amc_approver'
              : AMCApproverLoggedIn(role) &&
                APPLICATION_LISTING_STATUS[application?.status] ===
                  APPLICATION_LISTING_STATUS.sent_to_amc_approver
              ? RTAApproverEnableCheckForIndividual()
                ? 'sent_to_rta_approver'
                : application?.hasPOA
                ? 'sent_to_authorised_signatories'
                : sendApplication_Nri(application) ||
                  (AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                    !checkIfApplicationIsNonIndividual(application))
                ? 'sent_to_applicant1'
                : 'sent_to_authorised_signatories'
              : application?.status,
        },
        applicationId: application.id,
      })
    );
  };
  //It will called for reject cases
  const onSubmit = async (values: Partial<ApplicationProps>) => {
    try {
      handleClose();
      setRejectLoading(true);
      if (application) {
        if (RTAApproverLoggedIn(role)) {
          await dispatch(
            updateApplicationByRTA({
              body: {
                id: application.id,
                rejectedReason: values.rejectedReason,
                status: 'rejected',
              },
              applicationId: application.id,
              isTopUp: !!isTopUp,
            })
          );
        } else {
          await dispatch(
            updateApplication({
              body: {
                ...application,
                rejectedReason: values.rejectedReason,
                status: 'rejected',
              },
              applicationId: application.id,
            })
          );
        }
        history.push('/applications-list', { isTopUp: !!isTopUp });
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setRejectLoading(false);
    }
  };
  const ApplicableToRTAApproveOrSendBack =
    RTAApproverLoggedIn(role) && RTAApproverEnableCheckForIndividual();
  // it will sends back application to desired status like from AMC/RTA/POA approver to draft, draft to first approver(POA cases to poa otherwise to AMC approver), AMC to RTA
  const onSendBackSubmit = async (values: SendBackType) => {
    try {
      handleSendBackClose();
      approveClickedorSendBack ? setSendBackLoading(true) : setApproveLoading(true);
      if (application) {
        if (ApplicableToRTAApproveOrSendBack) {
          await dispatch(
            updateApplicationByRTA({
              body: {
                id: application.id,
                status: isRtaSendBackToMaker(values?.sendBackTo) ? 'draft' : 'sent_to_amc_approver',
                comment: values.comment,
                rtaSentBack: true,
                ...(isRtaSendBackToMaker(values?.sendBackTo) ? { applicationSentBack: true } : {}),
              },
              applicationId: application.id,
              isTopUp: !!isTopUp,
            })
          );
        } else if (!approveClickedorSendBack) {
          await onApproveByAMCApprover({ comment: values.comment });
        } else {
          await dispatch(
            updateApplication({
              body: {
                ...application,
                comment: values.comment,
                status: 'draft',
                applicationSentBack: true,
              },
              applicationId: application.id,
            })
          );
        }
        history.push('/applications-list', { isTopUp: !!isTopUp });
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setSendBackLoading(false);
      setApproveLoading(false);
    }
  };
  // It will call while application is gets approved
  const onApprove = async () => {
    try {
      handleDialogClose();
      setApproveLoading(true);
      if (ApplicableToRTAApproveOrSendBack) {
        await dispatch(
          updateApplicationByRTA({
            body: {
              id: application.id,
              status:
                !checkIfApplicationIsNonIndividual(application) && !application?.hasPOA
                  ? 'sent_to_applicant1'
                  : 'sent_to_authorised_signatories',
            },
            applicationId: application.id,
            isTopUp: !!isTopUp,
          })
        );
      } else {
        if (application?.rtaSentBack && AMCApproverLoggedIn(role)) {
          handleSendBackAMCtoRTA();
          return;
        } else {
          await onApproveByAMCApprover({ comment: '' });
        }
      }
      history.push('/applications-list', { isTopUp: !!isTopUp });
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setApproveLoading(false);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <CommonLayoutForPOA />
      <Box sx={{ py: 3, px: { xs: 2, sm: 10 }, mt: { xs: 7, sm: 10 } }} ref={applicationDetailRef}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            justifyContent: 'space-between',
            // mt: 5,
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              component={RouterLink}
              to={{
                pathname: '/applications-list',
                state: { isTopUp: isTopUp },
              }}
              disabled={approveLoading || rejectLoading || sendBackLoading}>
              <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
              Application Details
            </Typography>
          </Box>
          {editable && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() =>
                  history.push(
                    [
                      USER_ROLES.POAAPPROVER,
                      USER_ROLES.AMCAPPROVER,
                      USER_ROLES.RTAAPPROVER,
                    ].includes(role) && !checkIfApplicationIsNonIndividual(application)
                      ? `/${isTopUp ? 'topup' : 'investment-details'}/edit-application`
                      : `/non-individual-application-edit`,
                    {
                      id: application?.id,
                      topUpApplication: !!isTopUp,
                    }
                  )
                }
              />
            </IconButton>
          )}
        </Box>
        <IconButton
          sx={{
            position: 'fixed',
            right: 0,
            bottom: 75,
            borderRadius: '5px  0 0 5px',
            '&,:hover': {
              bgcolor: 'primary.main',
            },
          }}
          onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
          <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
        </IconButton>
        {applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ? (
          <NonIndividualApplicationLayout loading={loading} application={application} />
        ) : (
          <ApplicationDetailsCommonLayout loading={loading} application={application} />
        )}
        {application &&
          ((USER_ROLES.AMCAPPROVER === role &&
            APPLICATION_LISTING_STATUS[status] ===
              APPLICATION_LISTING_STATUS.sent_to_amc_approver) ||
          (USER_ROLES.RTAAPPROVER === role &&
            APPLICATION_LISTING_STATUS[status] ===
              APPLICATION_LISTING_STATUS.sent_to_rta_approver) ||
          (USER_ROLES.POAAPPROVER === role &&
            APPLICATION_LISTING_STATUS[status] ===
              APPLICATION_LISTING_STATUS.sent_to_poa_approver) ? (
            <RejectApproveButtons
              handleOpen={handleOpen}
              handleDialogOpen={handleDialogOpen}
              approve={approveLoading}
              reject={rejectLoading}
              sendBack={sendBackLoading}
              handleSendBack={handleSendBack}
            />
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                sx={{
                  color: 'common.white',
                  minWidth: '200px',
                  mt: 4,
                  fontWeight: 600,
                  lineHeight: 1.5,
                }}
                onClick={() => {
                  history.push('/applications-list', { isTopUp: !!isTopUp });
                }}>
                Done
              </Button>
            </Box>
          ))}
        <Dialog
          onClose={handleClose}
          open={open}
          sx={styles.ApplicationApproveRejectSendbackDialog}>
          <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box sx={{ mb: 2 }}>
            <CardMedia
              component="img"
              src="/images/warning-icon-red.svg"
              sx={{ width: 'unset', mx: 'auto' }}
            />
          </Box>
          <Box sx={styles.ApplicationApproveRejectSendbackDialogBox}>
            <Typography sx={{ color: 'primary.main' }}>
              Are you sure you want to{' '}
              <Typography component="span" sx={{ color: '#BD2932' }}>
                Reject
              </Typography>{' '}
              the application?
            </Typography>
            <Typography sx={{ color: '#BD2932', mt: 5 }}>
              {' '}
              Give reason for application rejection *
            </Typography>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={resonValidation}>
              {({ handleSubmit }) => (
                <Box component="form" noValidate onSubmit={handleSubmit}>
                  <MFTextField
                    name="rejectedReason"
                    multiline
                    rows={4}
                    placeholder="Type your answer here..."
                  />

                  <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                      variant="outlined"
                      sx={{
                        fontSize: 15,
                        color: 'primary.main',
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 500,
                      }}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: 15,
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 400,
                      }}
                      type="submit">
                      Continue
                    </Button>
                  </Grid>
                </Box>
              )}
            </Formik>
          </Box>
        </Dialog>
        <Dialog
          onClose={handleDialogClose}
          open={openDialog}
          sx={styles.ApplicationApproveRejectSendbackDialog}>
          <IconButton onClick={handleDialogClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box sx={styles.ApplicationApproveRejectSendbackDialogBox}>
            <Typography sx={{ color: 'primary.main' }}>
              Are you sure you want to{' '}
              <Typography component="span" sx={{ color: 'green' }}>
                Approve
              </Typography>{' '}
              the application
              {ENABLE_RTA_APPROVER && AMCApproverLoggedIn(role)
                ? ' And send it to RTA Approver?'
                : '?'}
            </Typography>
          </Box>
          <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
            <Button
              onClick={() => {
                handleDialogClose();
              }}
              variant="outlined"
              sx={{
                fontSize: 15,
                color: 'primary.main',
                minWidth: 180,
                letterSpacing: 2,
                fontWeight: 500,
              }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                fontSize: 15,
                minWidth: 180,
                letterSpacing: 2,
                fontWeight: 400,
              }}
              onClick={onApprove}>
              Continue
            </Button>
          </Grid>
        </Dialog>
        <SendbackDialog
          handleSendBackClose={() => handleSendBackClose()}
          openSendBackDialog={openSendBackDialog}
          onSendBackSubmit={(values: SendBackType) => onSendBackSubmit(values)}
          role={role}
          sendBackLoading={sendBackLoading}
          extraTexttodisplay={approveClickedorSendBack}
        />
      </Box>
      <Footer />
    </Box>
  );
};

export default function ApplicationDetails({
  location,
}: {
  location: Location<{
    topUpApplication: boolean | null;
  }>;
}): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { topUpApplication = false } = location.state || {};
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState<ApplicationProps>();
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDialog, setDialog] = useState(false);
  const { path } = useRouteMatch();

  useEffect(() => {
    (async function () {
      try {
        const _application = topUpApplication
          ? ((await dispatch(getSpecificTopups(Number(id)))) as unknown as ApplicationProps)
          : ((await dispatch(getApplicationDetails(id))) as unknown as ApplicationProps);
        setApplication(_application);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  const { editable, hasPOA, applicationSentBack = false } = application || {};
  const [openSendBackDialog, setOpenSendBackDialog] = useState(false);
  const handleSendBack = () => setOpenSendBackDialog(true);
  const [sendBackLoading, setSendBackLoading] = useState(false);
  const handleSendBackClose = () => {
    setOpenSendBackDialog(false), setSendBackLoading(false);
  };
  const onSendBackSubmit = async (values: SendBackType) => {
    try {
      handleSendBackClose();
      setSendBackLoading(true);
      const { id, status } = application || {};
      if (id) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              status: hasPOA
                ? 'sent_to_poa_approver'
                : sendApplication_Nri(application) ||
                  (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))
                ? 'sent_to_amc_approver'
                : !AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role)
                ? 'sent_to_applicant1'
                : status,
              comment: values.comment,
              applicationSentBack: false,
            },
            applicationId: id,
          })
        );
        history.push(topUpApplication ? '/view-top-up-list' : '/applications');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setSendBackLoading(false);
    }
  };
  const handleSubmit = async () => {
    try {
      const { id, status } = application || {};
      setIsSubmitting(true);
      if (id) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              status: hasPOA
                ? 'sent_to_poa_approver'
                : // : (status === 'draft' || status === 'sent_to_amc_approver') &&
                sendApplication_Nri(application) ||
                  (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))
                ? 'sent_to_amc_approver'
                : !AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role)
                ? 'sent_to_applicant1'
                : status,
            },
            applicationId: id,
          })
        );
        history.push(topUpApplication ? '/view-top-up-list' : '/applications');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return MakerOrAdmin(role) ? (
    <CommonLayout>
      <>
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          ref={applicationDetailRef}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              component={RouterLink}
              // to="/applications"
              to={{
                pathname: topUpApplication ? '/view-top-up-list' : '/applications',
                state: { isTopUp: topUpApplication },
              }}>
              <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
              Application Details
            </Typography>
          </Box>
          {editable && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() =>
                  history.push(`/application`, { id, topUpApplication: topUpApplication })
                }
              />
            </IconButton>
          )}
        </Box>
        <IconButton
          sx={{
            position: 'fixed',
            right: 0,
            bottom: 75,
            borderRadius: '5px  0 0 5px',
            '&,:hover': {
              bgcolor: 'primary.main',
            },
          }}
          onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
          <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
        </IconButton>
        <ApplicationDetailsCommonLayout
          loading={loading}
          application={application as ApplicationProps}
        />
        {path === '/application-preview/:id' && editable && (
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              sx={{
                color: 'common.white',
                minWidth: '200px',
                mt: 4,
                fontWeight: 600,
                lineHeight: 1.5,
                fontSize: '16px',
              }}
              onClick={async () => {
                setDialog(true);
              }}
              disabled={isSubmitting || sendBackLoading}>
              {`${
                application?.hasPOA
                  ? 'Send to POA Approver'
                  : sendApplication_Nri(application) ||
                    (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))
                  ? ENABLE_RTA_APPROVER
                    ? 'Send to AMC approver'
                    : 'Send to AIF approver'
                  : 'Send to Investor'
              } ${isSubmitting || sendBackLoading ? '...' : ''}`}
            </Button>
          </Box>
        )}
        <SendbackDialog
          handleSendBackClose={() => handleSendBackClose()}
          openSendBackDialog={openSendBackDialog}
          onSendBackSubmit={(values: SendBackType) => onSendBackSubmit(values)}
          role={role}
          sendBackLoading={sendBackLoading}
        />

        <ConfirmationDialog
          message={`Are you sure you want to send it to ${
            application?.hasPOA
              ? 'POA Approver'
              : sendApplication_Nri(application) ||
                (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))
              ? ENABLE_RTA_APPROVER
                ? 'AMC approver'
                : 'AIF approver'
              : 'Investor'
          }`}
          open={openDialog}
          setOpen={() => setDialog(false)}
          onSave={async () => {
            setDialog(false);
            (sendApplication_Nri(application) || applyRoleBasedStatus(role)) && applicationSentBack
              ? handleSendBack()
              : handleSubmit();
          }}
          onCancel={() => setDialog(false)}
        />
      </>
    </CommonLayout>
  ) : (
    <PoaOrNIApplicationDetails
      loading={loading}
      application={application as ApplicationProps}
      isTopUp={topUpApplication}
    />
  );
}
